/* import __COLOCATED_TEMPLATE__ from './unit-search-trigger.hbs'; */
import Component from '@glimmer/component';

import { UnitSearchTrigger } from 'qonto/react/components/receivable-invoices/form/unit-search-trigger/unit-search-trigger';

interface UnitSearchTriggerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class UnitSearchTriggerComponent extends Component<UnitSearchTriggerSignature> {
  unitSearchTrigger = UnitSearchTrigger;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::UnitSearchTrigger': typeof UnitSearchTriggerComponent;
  }
}
