/* import __COLOCATED_TEMPLATE__ from './beneficiary-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

interface RequestTransferBeneficiaryFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestTransferBeneficiaryFormComponent extends Component<RequestTransferBeneficiaryFormSignature> {
  checkbox = Checkbox;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  get clearOptions() {
    // @ts-expect-error
    let canClearBeneficiary = this.args.requestTransfer.isIBANObfuscated;

    if (canClearBeneficiary) {
      return {
        text: this.intl.t('labels.clear_beneficiary'),
      };
    }

    return {};
  }

  @action
  // @ts-expect-error
  updateBeneficiaryName(name) {
    // @ts-expect-error
    this.args.requestTransfer.set('name', name);
    // @ts-expect-error
    this.args.onBeneficiaryNameFilled?.();
  }

  @action
  toggleNotifyEmail() {
    // @ts-expect-error
    this.args.requestTransfer.notifyByEmail = !this.args.requestTransfer.notifyByEmail;
    this.segment.track('transfer_notify_beneficiary_checkbox');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::BeneficiaryForm': typeof RequestTransferBeneficiaryFormComponent;
  }
}
