/* import __COLOCATED_TEMPLATE__ from './beneficiary.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField, Checkbox } from '@repo/design-system-kit';

interface RequestBeneficiarySignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestBeneficiary extends Component<RequestBeneficiarySignature> {
  checkbox = Checkbox;

  @service declare modals: Services['modals'];
  @service declare attachmentsManager: Services['attachmentsManager'];

  @tracked isEmailDirty = false;

  amountField = AmountField;

  get displayWarning() {
    // @ts-expect-error
    let error = this.args.transfer.validations?.error;
    if (error) {
      let isEmailErrorDisplayed = error.type === 'format' && error.attribute === 'email';
      return isEmailErrorDisplayed ? this.isEmailDirty : true;
    }
    return false;
  }

  @action
  // @ts-expect-error
  onAmountChange(amountValue) {
    // @ts-expect-error
    this.args.transfer.set('amount', amountValue);
  }

  // @ts-expect-error
  @action addAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.addAttachment(false, this.args.transfer, file);
  }

  // @ts-expect-error
  @action removeAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.removeAttachment(false, this.args.transfer, file);
  }

  // @ts-expect-error
  @action cancelUploadAttachment(file) {
    // @ts-expect-error
    this.attachmentsManager.cancelUploadAttachment(this.args.transfer, file);
  }

  @action
  // @ts-expect-error
  openFilePreviewModal(selectedFile) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile,
      // @ts-expect-error
      model: this.args.transfer,
      download: null,
    });
  }

  @action
  // @ts-expect-error
  handleEmailFocusOut(event) {
    this.isEmailDirty = Boolean(event.target.value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Beneficiary': typeof RequestBeneficiary;
  }
}
