/* import __COLOCATED_TEMPLATE__ from './edit-imported-form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { restartableTask } from 'ember-concurrency';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

class Form {
  // @ts-expect-error
  @tracked errors;
  @tracked values;

  // @ts-expect-error
  constructor(schema) {
    // @ts-expect-error
    this.schema = schema;
    this.values = { ...schema.initialValues };
    // @ts-expect-error
    this.validations = { ...schema.validationsSchema };
  }

  async submit({ partial = false } = {}) {
    try {
      // @ts-expect-error
      await this.schema.onSubmit(this.values, partial);
    } catch (error) {
      // @ts-expect-error
      if (error.isAdapterError) throw error;
    }
  }

  // @ts-expect-error
  updateField(prop, value, hasInvalidDate, dateRangeError) {
    this.values = { ...this.values, [prop]: value };
    let error = dateRangeError || (typeof hasInvalidDate === 'boolean' && hasInvalidDate) || false;
    this.errors = error ? { ...this.errors, [prop]: error } : null;
  }
}

interface ReceivableInvoicesEditImportedFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesEditImportedFormComponent extends Component<ReceivableInvoicesEditImportedFormSignature> {
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];

  @tracked amountDue;

  amountField = AmountField;

  constructor(owner: unknown, args: ReceivableInvoicesEditImportedFormSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.amountDue = this.args.invoice?.amountDue;
    // @ts-expect-error
    this.form = new Form({
      // @ts-expect-error
      onSubmit: values => this.saveInvoiceTask.perform(values),
      initialValues: this.defaultValues,
      // @ts-expect-error
      validationsSchema: this.validationsSchema,
    });
    // @ts-expect-error
    this.args.onCreate?.(this.form);
  }

  get defaultValues() {
    // @ts-expect-error
    let { invoice } = this.args;
    return {
      name: invoice?.customerSnapshot?.name,
      invoiceNumber: invoice?.number,
      dueDate: invoice?.dueDate,
      issueDate: invoice?.issueDate,
      amountDue: invoice.amountDue?.value,
      subtotal: invoice?.subtotal,
      vatAmount: invoice?.vatAmount,
      paymentDate: invoice?.paymentDate,
      currency: invoice?.currency || 'EUR',
    };
  }

  get customer() {
    // @ts-expect-error
    let { invoice } = this.args;

    return { ...invoice?.customerSnapshot, currency: invoice?.currency };
  }

  /**
   * @param {string} amountValue
   */
  @action
  // @ts-expect-error
  onVatAmountChange(amountValue) {
    // @ts-expect-error
    this.updateField('vatAmount', amountValue);
    this.calculateAmountDue();
  }

  /**
   * @param {string} amountValue
   */
  @action
  // @ts-expect-error
  onSubtotalAmountChange(amountValue) {
    // @ts-expect-error
    this.updateField('subtotal', amountValue);
    this.calculateAmountDue();
  }

  calculateAmountDue() {
    this.amountDue = this.formatAmountDue;
    // @ts-expect-error
    this.updateField('amountDue', this.amountDue);
  }

  get formatAmountDue() {
    // @ts-expect-error
    let { subtotal = '0', vatAmount = '0' } = this.form.values;
    return (Number(subtotal) + Number(vatAmount)).toFixed(2);
  }

  // @ts-expect-error
  formatValueToTwoDecimals(value) {
    return value.includes('.') ? value.replace(/(\.\d{2})\d+/, '$1') : value;
  }

  @action
  // @ts-expect-error
  updateField(prop, value, hasInvalidDate) {
    let dateRangeError =
      (prop === 'dueDate' || prop === 'issueDate') &&
      // @ts-expect-error
      this.displayInvalidDateRangeError(prop, value, this.form.values);
    // @ts-expect-error
    this.form.updateField(prop, value, hasInvalidDate, dateRangeError);
  }

  // @ts-expect-error
  displayInvalidDateRangeError(prop, value, values) {
    let dueDate = new Date(prop === 'dueDate' ? value : values.dueDate);
    let issueDate = new Date(prop === 'issueDate' ? value : values.issueDate);

    return issueDate > dueDate
      ? this.intl.t('receivable-invoices.import.edit.modal.invoice-details.issue-date-error')
      : undefined;
  }

  submitFormTask = restartableTask(async () => {
    try {
      // @ts-expect-error
      await this.form.submit();
    } catch (error) {
      this.sendErrorToSentry(error);
    }
  });

  saveInvoiceTask = restartableTask(async values => {
    await this._saveInvoiceTask.perform(values);
  });

  _saveInvoiceTask = restartableTask(async values => {
    this.updateInvoiceValues(values);

    try {
      // @ts-expect-error
      await this.args.invoice.updateImportedInvoice();
      this.segment.track('invoice_imported_edit-info_save');
      // @ts-expect-error
      this.form.schema.initialValues = this.defaultValues;
      // @ts-expect-error
      this.args.cancelEdit();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.edit.modal.success-toast.save-changes')
      );
    } catch (error) {
      this.handleSaveError(error);
    }
  });

  updateInvoiceValues({
    // @ts-expect-error
    amount,
    // @ts-expect-error
    currency,
    // @ts-expect-error
    name,
    // @ts-expect-error
    invoiceNumber,
    // @ts-expect-error
    issueDate,
    // @ts-expect-error
    dueDate,
    // @ts-expect-error
    subtotal,
    // @ts-expect-error
    vatAmount,
  }) {
    // @ts-expect-error
    let invoice = this.args.invoice;
    if (amount !== undefined) invoice.totalAmount = { value: amount, currency };

    vatAmount = vatAmount || '0.00';
    subtotal = subtotal || '0.00';

    Object.assign(invoice, {
      customerSnapshot: { ...invoice.customerSnapshot, name },
      number: invoiceNumber,
      issueDate,
      dueDate,
      amountDue: this.amountDue,
      subtotal,
      vatAmount,
      currency,
    });
  }

  // @ts-expect-error
  handleSaveError(error) {
    // @ts-expect-error
    this.args.invoice.rollbackAttributes();
    this.toastFlashMessages.toastError(
      this.intl.t('receivable-invoices.edit.modal.error-toast.save-changes')
    );
    this.sendErrorToSentry(error);
  }

  // @ts-expect-error
  sendErrorToSentry(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && error.status !== 400 && error.status !== 422) {
      this.sentry.captureException(error);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvoiceModal::Sidebar::EditImportedForm': typeof ReceivableInvoicesEditImportedFormComponent;
  }
}
