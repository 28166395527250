export default {
  "header": "cYT",
  "header-type": "cYz",
  "close": "cYa",
  "close-icon": "cYH",
  "header-infos": "cYO",
  "title": "cYm body-1",
  "declined": "cYp",
  "canceled": "cYx",
  "approved": "cYw",
  "pending": "cYN",
  "date": "cYv body-2",
  "container": "cYo",
  "picto": "cYn",
  "picto-status": "cYi",
  "general": "cYA",
  "amount": "cYY",
  "counterparty": "cYh body-2",
  "initiator": "cYf",
  "initiator-avatar": "cYK",
  "avatar-image": "cYG",
  "initiator-id": "cYr",
  "name": "cYb body-2",
  "role": "cYy caption-bold",
  "infobox": "cYU",
  "account-infobox": "cYj cYU",
  "beneficiary-warning": "cYW caption-bold",
  "dates-wrapper": "cYu",
  "dates": "chS",
  "recurrence": "chc caption-bold",
  "arrow": "chq",
  "notify-checkbox": "chZ"
};
