export default {
  "modal": "cmB",
  "action-container": "cmE",
  "similar-label": "cmd",
  "info-icon": "cmI",
  "container": "cmt",
  "subtitle": "cmM caption-bold",
  "error": "cmP",
  "item-container": "cml"
};
