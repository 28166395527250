export default {
  "wrapper": "crw",
  "beneficiary": "crN",
  "name": "crv body-1",
  "beneficiary-email": "cro body-2",
  "dropdown-icon": "crn",
  "trusted": "cri",
  "account-number": "crA flex body-2",
  "account-icon": "crY",
  "m-chip": "crh",
  "dropdown": "crf body-2",
  "delete-option": "crK",
  "beneficiary-name": "crG"
};
