/* import __COLOCATED_TEMPLATE__ from './rule-trigger-field.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface RuleTriggerFieldSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RuleTriggerFieldComponent extends Component<RuleTriggerFieldSignature> {
  @service declare intl: Services['intl'];

  @tracked selectedOperator = this.triggerOptions.find(
    // @ts-expect-error
    option => this.args.rule.operator === option.value
  );

  get triggerOptions() {
    return [
      {
        value: 'on',
        label: this.intl.t('payment-reminders.form.timing.option.on'),
        clearable: false,
      },
      {
        value: 'before',
        label: this.intl.t('payment-reminders.form.timing.option.before'),
        clearable: true,
      },
      {
        value: 'after',
        label: this.intl.t('payment-reminders.form.timing.option.after'),
        clearable: true,
      },
    ];
  }

  @action
  // @ts-expect-error
  updateTrigger(option) {
    // @ts-expect-error
    let prevOption = this.args.rule.operator;

    // @ts-expect-error
    this.args.rule.operator = option.value;
    this.selectedOperator = option;
    // @ts-expect-error
    this.args.rule.offsetDays = null;

    // @ts-expect-error
    if (this.args.onUpdateTrigger) {
      // @ts-expect-error
      this.args.onUpdateTrigger(prevOption, option.value);
    }
  }

  @action
  // @ts-expect-error
  updateTriggerOffsetDays(offsetDays) {
    // @ts-expect-error
    if (this.args.operatorErrorMessage) {
      // @ts-expect-error
      this.args.rule.errors.remove('operator');
    }

    // @ts-expect-error
    this.args.rule.offsetDays = offsetDays;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RemindersConfiguration::RuleTriggerField': typeof RuleTriggerFieldComponent;
  }
}
