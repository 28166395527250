/* import __COLOCATED_TEMPLATE__ from './reset-password-form.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer, Spinner } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { alias } from 'macro-decorators';

// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ResetPasswordFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ResetPasswordFormComponent extends Component<ResetPasswordFormSignature> {
  disclaimer: typeof Disclaimer.Block = Disclaimer.Block;

  spinner = Spinner;

  @service declare errors: Services['errors'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  @tracked done = false;

  // @ts-expect-error
  @alias('args.userPassword.hasPassword') hasPassword;

  signInTask = dropTask(async userPassword => {
    // @ts-expect-error
    await this.args.signInTask.linked().perform(userPassword);
  });

  resetPasswordTask = dropTask(async userPassword => {
    try {
      // @ts-expect-error
      await this.args.resetPasswordTask.linked().perform(userPassword);
      this.done = true;
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // @ts-expect-error
      if (this.errors.shouldFlash(error)) {
        // @ts-expect-error
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ResetPasswordForm: typeof ResetPasswordFormComponent;
  }
}
