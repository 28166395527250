export default {
  "subtitle": "ciM",
  "green-text": "ciP",
  "balance-subtitle": "cil",
  "options": "ciX",
  "shadow": "ciC",
  "menu-item": "cik body-2",
  "close-account": "ciJ",
  "footer": "cis",
  "progress": "ciF",
  "progress-bar": "cig",
  "progress-bar-text": "ciD",
  "progress-active": "ciV"
};
