/* import __COLOCATED_TEMPLATE__ from './rule.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';

// @ts-expect-error
import { OPERATOR } from 'qonto/constants/reminders-configuration';
// @ts-expect-error
import { emailTemplateForOperator } from 'qonto/routes/clients/client/reminders-configuration/route';

export const CLEARABLE_OPERATORS = [OPERATOR.BEFORE, OPERATOR.AFTER];

interface RuleSignature {
  // The arguments accepted by the component
  Args: {
    isDeletable?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RuleComponent extends Component<RuleSignature> {
  textAreaField = TextAreaField;

  @service declare intl: Services['intl'];

  // @ts-expect-error
  @tracked templates = [...(this.args.defaultTemplates || [])];

  constructor(owner: unknown, args: RuleSignature['Args']) {
    super(owner, args);

    this.setupTemplate();
  }

  get title() {
    return this.intl.t('payment-reminders.reminder-item.title', {
      // @ts-expect-error
      index: (this.args.index ?? 0) + 1,
    });
  }

  get description() {
    // @ts-expect-error
    let { rule } = this.args;
    let { operator, offsetDays } = rule;

    let hasClearableOperator = CLEARABLE_OPERATORS.includes(operator);

    if (!operator || (hasClearableOperator && !offsetDays)) {
      return '';
    }

    return this.intl.t('payment-reminders.reminder-item.rule', {
      operator,
      offset: offsetDays,
    });
  }

  get isExpanded() {
    // @ts-expect-error
    return this.args.expanded ?? true;
  }

  get isForcedExpanded() {
    // @ts-expect-error
    return this.args.displayErrors && this.args.rule.validations.isInvalid;
  }

  applyTemplate() {
    // @ts-expect-error
    let { rule } = this.args;
    let { operator } = rule;

    let templateToApply = emailTemplateForOperator(this.templates, operator);

    if (!templateToApply) {
      return;
    }

    rule.setProperties(templateToApply);
  }

  // @ts-expect-error
  saveTemplate(operator) {
    let indexToSave = this.templates.findIndex(
      ({ operator: templateOperator }) => templateOperator === operator
    );

    let newTemplate = {
      // @ts-expect-error
      emailTitle: this.args.rule.emailTitle,
      // @ts-expect-error
      emailBody: this.args.rule.emailBody,
    };

    if (indexToSave >= 0) {
      this.templates[indexToSave] = {
        ...this.templates[indexToSave],
        ...newTemplate,
      };
    } else {
      this.templates.push({ ...newTemplate, operator });
    }
  }

  setupTemplate() {
    // @ts-expect-error
    let { rule } = this.args;

    if (rule.emailTitle || rule.emailBody) {
      // @ts-expect-error
      this.saveTemplate(this.args.rule.operator);

      return;
    }

    next(() => {
      this.applyTemplate();
    });
  }

  @action
  // @ts-expect-error
  onUpdateRuleTrigger(prevOption) {
    if (prevOption) {
      this.saveTemplate(prevOption);
    }

    this.applyTemplate();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RemindersConfiguration::Rule': typeof RuleComponent;
  }
}
