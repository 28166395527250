/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { STATUS } from 'qonto/constants/transfers';

interface RequestSidebarExpenseReportDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarExpenseReportDetailsComponent extends Component<RequestSidebarExpenseReportDetailsSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare modals: Services['modals'];
  @service declare abilities: Services['abilities'];
  @service declare attachmentsManager: Services['attachmentsManager'];

  get showDisclaimer() {
    // @ts-expect-error
    let { request } = this.args;
    let userId = request.get('initiator.userId');

    return (
      request.status === STATUS.PENDING &&
      this.abilities.can('cancel expense report request', userId) &&
      this.abilities.cannot('see state approval-workflow')
    );
  }

  @action
  // @ts-expect-error
  handleOpenFilePreviewModal(file) {
    this.modals.open('reimbursements/requests/sidebar/expense-report/attachment-modal', {
      // @ts-expect-error
      model: this.args.request,
      selectedFile: file,
      onDownload: this.attachmentsManager.downloadAttachment,
      // @ts-expect-error
      decline: this.args.decline,
      // @ts-expect-error
      approve: this.args.approve,
      // @ts-expect-error
      setAccount: this.args.setAccount,
      // @ts-expect-error
      close: this.args.close,
      // We need to pass the args here to keep the reactivity
      // of the errors and warnings generated by the setAccount callback
      parentArgs: this.args,
    });
  }

  get shouldDisplayApprovalWorkflowState() {
    // @ts-expect-error
    let { approvalWorkflowState, canceled } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');
    return (
      this.abilities.can('see state approval-workflow') &&
      approvalWorkflowState &&
      steps &&
      conditions &&
      !canceled
    );
  }

  get approvalWorkflowState() {
    // @ts-expect-error
    let { approvalWorkflowState } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return {
      id: approvalWorkflowState.id,
      conditions,
      steps,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Requests::Sidebar::ExpenseReport::Details': typeof RequestSidebarExpenseReportDetailsComponent;
  }
}
