/* import __COLOCATED_TEMPLATE__ from './invoice-pdf-preview.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { DEFAULT_LOCALE_PER_COUNTRY } from 'qonto/constants/receivable-invoice';

interface InvoicePdfPreviewSignature {
  // The arguments accepted by the component
  Args: {
    isQuote?: boolean;
    shouldUseV1?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class InvoicePdfPreview extends Component<InvoicePdfPreviewSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare abilities: Services['abilities'];

  get customerLocale() {
    // @ts-expect-error
    return this.args.invoice.locale && !this.args.useCustomerRecord
      ? // @ts-expect-error
        this.args.invoice.locale
      : // @ts-expect-error
        this.args.invoice.get('customer.locale');
  }

  get userLocale() {
    return this.intl.primaryLocale || 'en';
  }

  get currencyIsDifferentThanEUR() {
    // @ts-expect-error
    return this.args.invoice.currency !== 'EUR';
  }

  get isOrganizationCountryDifferentThanCustomerCountry() {
    // @ts-expect-error
    let customerCountryCode = this.args.invoice.customer?.content?.countryCode;
    if (!customerCountryCode) return false;
    return this.organizationManager.organization.legalCountry !== customerCountryCode;
  }

  get showLanguageTooltip() {
    let orgaCountry = this.organizationManager.organization.legalCountry;
    return (
      // @ts-expect-error
      (this.args.showLanguageTooltip &&
        this.customerLocale &&
        // @ts-expect-error
        this.customerLocale !== DEFAULT_LOCALE_PER_COUNTRY[orgaCountry]) ||
      // @ts-expect-error
      (this.args.showLanguageTooltip && this.currencyIsDifferentThanEUR) ||
      // @ts-expect-error
      (this.args.showLanguageTooltip && this.isOrganizationCountryDifferentThanCustomerCountry)
    );
  }

  get showGermanTemplate() {
    return this.organizationManager.organization.legalCountry === 'DE';
  }

  get paymentLinkUrl() {
    // @ts-expect-error
    if (this.abilities.can('read paymentLink') && this.args.invoice?.paymentLink?.methods?.length) {
      // TODO replace with an actual URL
      return 'https://qonto.com';
    }
  }

  get isV2Template() {
    return variation('feature--boolean-ar-advanced-customization') && !this.args.shouldUseV1;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvoicePdfPreview': typeof InvoicePdfPreview;
  }
}
