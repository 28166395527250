/* import __COLOCATED_TEMPLATE__ from './tabs.hbs'; */
import Component from '@glimmer/component';

// @ts-expect-error
import { QUOTE_STATUSES } from 'qonto/constants/quotes';

interface TabsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class Tabs extends Component<TabsSignature> {
  get isCompletedTabActive() {
    // @ts-expect-error
    let statuses = this.args.status?.split(',') || [];

    return statuses.includes(QUOTE_STATUSES.APPROVED) || statuses.includes(QUOTE_STATUSES.CANCELED);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Quotes::Tabs': typeof Tabs;
  }
}
