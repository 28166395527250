/* import __COLOCATED_TEMPLATE__ from './expense-report.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ExpenseReportSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ExpenseReport = templateOnlyComponent<ExpenseReportSignature>();

export default ExpenseReport;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Table::Cell::ExpenseReport': typeof ExpenseReport;
  }
}
