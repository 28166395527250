export default {
  "wrapper": "cpG",
  "tabs": "cpr",
  "tabs-nav": "cpb",
  "tabs-panel": "cpy",
  "tabs-panel-wrapper": "cpU",
  "list-head": "cpj",
  "list": "cpW",
  "footer": "cpu",
  "reward-amount": "cxS",
  "rewarded": "cxc",
  "registered": "cxq",
  "disclaimer": "cxZ",
  "empty-state-wrapper": "cxR",
  "empty-state": "cxQ",
  "illustration": "cxe",
  "spinner": "cxB",
  "total-earned": "cxE"
};
