/* import __COLOCATED_TEMPLATE__ from './settings-modal-with-preview.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { ToggleButton } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

// @ts-expect-error
import TEMPLATE_VALUES from 'qonto/constants/env-helper';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

const GERMAN_TAB = {
  GENERAL: 'general',
  INVOICE: 'invoice',
  QUOTE: 'quote',
};

const SWITCH_VALUES = {
  INVOICE: 'INVOICE',
  QUOTE: 'QUOTE',
};

interface ReceivableInvoicesSettingsModalWithPreviewSignature {
  // The arguments accepted by the component
  Args: {
    isLoading: boolean;
    isManualNumberingDisabled: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesSettingsModalWithPreviewComponent extends Component<ReceivableInvoicesSettingsModalWithPreviewSignature> {
  toggleButton = ToggleButton;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];
  @service declare networkManager: Services['networkManager'];
  @service declare store: Services['store'];
  @service declare zendeskLocalization: Services['zendeskLocalization'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('args.data.settings') settings;
  // @ts-expect-error
  @reads('args.data.lastInvoice') lastInvoice;
  // @ts-expect-error
  @reads('args.data.isQuote') isQuote;

  // @ts-expect-error
  @tracked formattedMaxSize = formatFileSize(this.intl, TEMPLATE_VALUES.avatarMaxSize);
  @tracked contentDisplay = GERMAN_TAB.GENERAL;
  @tracked activeSwitchItem = this.originRoute;

  avatarRollbackAttributes;

  constructor(owner: unknown, args: ReceivableInvoicesSettingsModalWithPreviewSignature['Args']) {
    super(owner, args);

    let { isDefaultAvatar, avatar } = this.organization;
    this.avatarRollbackAttributes = { isDefaultAvatar, avatar };
  }

  get isLoading() {
    return this.args.isLoading;
  }

  get isManualNumberingDisabled() {
    return this.args.isManualNumberingDisabled;
  }

  get originRoute() {
    let { currentRouteName, currentRoute } = this.router;
    if (currentRouteName === 'invoicing-settings') {
      let { queryParams } = currentRoute;
      return queryParams?.origin?.includes('quote') && this.legalCountry !== 'DE'
        ? SWITCH_VALUES.QUOTE
        : SWITCH_VALUES.INVOICE;
    } else {
      return currentRouteName?.includes('quote') && this.legalCountry !== 'DE'
        ? SWITCH_VALUES.QUOTE
        : SWITCH_VALUES.INVOICE;
    }
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    if (this.settings?.isDestroying || this.settings?.isDestroyed) return;

    this.rollbackAttributesOnClose();
  }

  get legalCountry() {
    return this.organization.legalCountry;
  }

  get logo() {
    return this.organization.isDefaultAvatar === false ? this.organization.picture : null;
  }

  @action
  activateGeneralTab() {
    this.contentDisplay = GERMAN_TAB.GENERAL;
    this.activeSwitchItem = SWITCH_VALUES.INVOICE;
  }

  @action
  activateInvoiceTab() {
    this.contentDisplay = GERMAN_TAB.INVOICE;
    this.activeSwitchItem = SWITCH_VALUES.INVOICE;
  }

  @action
  activateQuoteTab() {
    this.contentDisplay = GERMAN_TAB.QUOTE;
    this.activeSwitchItem = SWITCH_VALUES.QUOTE;
  }

  get document() {
    let document = {
      organization: {
        locale: this.organization.locale,
        legalName: this.organization.legalName,
        legalNumber: this.organization.legalNumber,
        shortLegalForm: this.organization.shortLegalForm,
        legalCountry: this.organization.legalCountry,
        address: this.organization.address,
        contactEmail: this.organization.contactEmail,
        vatNumber: this.organization.vatNumber,
        taxNumber: this.organization.taxNumber,
      },
      bic: this.organization.mainAccount?.bic,
      iban: this.organization.mainAccount?.iban,
      beneficiaryName: this.organization.legalName,
      termsAndConditions: variation('feature--boolean-ar-advanced-customization')
        ? null
        : this.lastInvoice?.termsAndConditions,
    };
    return document;
  }

  get fullListDisclaimer() {
    let invoiceDisclaimer = {
      firstPart: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.part-1'
      ),
      href: this.zendeskLocalization.getLocalizedArticle(6211274),
      textLink: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.faq-text-link'
      ),
      secondPart: this.intl.t(
        'receivable-invoices.invoice-creation.payment-details.terms.disclaimer.part-2'
      ),
    };
    return invoiceDisclaimer;
  }

  get numberingMode() {
    return this.settings?.numberingMode;
  }

  get contactEmailErrorMessage() {
    let errors = this.settings.errors.get('contactEmail');

    if (errors.length === 0) return null;

    // @ts-expect-error
    if (errors.some(error => error.message === 'invalid')) {
      return this.intl.t(
        'receivable-invoices.invoice-creation.new.tab-settings.errors.invalid-contact-email'
      );
    }
    return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
  }

  get vatNumberErrorMessage() {
    let errors = this.settings.errors.get('vatNumber');

    if (errors.length === 0) return null;

    // @ts-expect-error
    if (errors.some(error => error.message === 'invalid')) {
      return this.intl.t(
        'receivable-invoices.invoice-setting.settings-modal.general.company-details.vat-error-message'
      );
    }
  }

  /**
   * `organization.isDefaultAvatar` can be `null` when the avatar wasn't loaded or failed to load
   * because of this all checks should be strict
   */
  get hasDefaultAvatar() {
    return this.organization.isDefaultAvatar ?? true;
  }

  @action
  // @ts-expect-error
  handleEmailUpdate(email) {
    this.settings.contactEmail = email;
    this.segment.track('invoice-creation_additional-details_email_edited');
  }

  @action
  // @ts-expect-error
  handleVatNumberUpdate(vatNumber) {
    this.settings.vatNumber = vatNumber;
    this.segment.track('invoice-creation_additional-details_vat-number_edited');
  }

  @action
  // @ts-expect-error
  handleDistrictCourtUpdate(districtCourt) {
    this.settings.districtCourt = districtCourt;
    this.segment.track('invoice-creation_additional-details_district-court_edited');
  }

  @action
  // @ts-expect-error
  handleCompanyLeadershipUpdate(companyLeadership) {
    this.settings.companyLeadership = companyLeadership;
    this.segment.track('invoice-creation_additional-details_company-leadership_edited');
  }

  @action
  // @ts-expect-error
  handleTaxNumberUpdate(taxNumber) {
    this.settings.taxNumber = taxNumber;
    this.segment.track('invoice-creation_additional-details_tax-number_edited');
  }

  @action
  rollbackAttributesOnClose() {
    this.settings?.rollbackAttributes();
    this.organization.set('isDefaultAvatar', this.avatarRollbackAttributes.isDefaultAvatar);
    this.organization.set('avatar', this.avatarRollbackAttributes.avatar);
  }

  @action
  handleClose() {
    this.rollbackAttributesOnClose();

    // @ts-expect-error
    this.args.close?.();
  }

  @action
  handleDropAvatar() {
    this.organization.reload();
    this.organization.set('avatar', null);
    this.organization.set('isDefaultAvatar', true);
    this.segment.track('company_logo_delete', { origin: 'invoice_creation' });
  }

  @action
  // @ts-expect-error
  handleAvatarUpload(file) {
    this.segment.track('company_logo_upload_clicked');
    this.organization.reload();
    this.organization.set('avatar', file);
    this.organization.set('isDefaultAvatar', false);
  }

  SWITCH_VALUES = SWITCH_VALUES;
  switchItems = [
    {
      value: SWITCH_VALUES.INVOICE,
      // @ts-expect-error
      label: this.intl.t('receivable-invoices.invoice-settings.toggle.invoice'),
    },
    {
      value: SWITCH_VALUES.QUOTE,
      // @ts-expect-error
      label: this.intl.t('receivable-invoices.invoice-settings.toggle.quote'),
    },
  ];

  // @ts-expect-error
  @action togglePreview(state) {
    this.activeSwitchItem = state;

    let route = this.router?.currentRouteName?.includes('quote')
      ? SWITCH_VALUES.QUOTE
      : SWITCH_VALUES.INVOICE;

    this.segment.track('invoicing_settings_toggle_switched', { origin: route, document: state });
  }

  get showQuote() {
    return this.contentDisplay === 'quote';
  }

  get showQuotePreview() {
    return this.activeSwitchItem === 'QUOTE';
  }

  @action
  // @ts-expect-error
  scrollToSelectorContent({ elementId, offsetTop }) {
    next(() => {
      scrollIntoView(document.getElementById(elementId), { offsetTop });
    });
  }

  saveSettingsTask = dropTask(async () => {
    try {
      await this.organization.save();
      await this.organization.getAvatar();
      await this.settings.save();

      this.segment.track('invoice_issuer-email-settings_saved');
      this.segment.track('company_logo_upload_success');
      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.cards.settings-updated'));

      // @ts-expect-error
      this.args.data.onSave?.(this.settings);

      let { isDefaultAvatar, avatar } = this.organization;
      this.avatarRollbackAttributes = { isDefaultAvatar, avatar };

      // @ts-expect-error
      this.args.close?.();
    } catch (error) {
      // @ts-expect-error
      if (error.status === 422) {
        // @ts-expect-error
        let normalizedErrors = this._normalizeErrors(error.errors);

        if (isEmpty(normalizedErrors)) {
          this.sentry.captureException(
            new Error(
              'The server responded with a 422 status code, but the errors could not be parsed or do not exist.',
              { cause: error }
            )
          );
        } else {
          if (normalizedErrors['invalid_file']) {
            this.toastFlashMessages.toastError(this.intl.t('errors.file-corrupted'));
          } else {
            // @ts-expect-error
            this.networkManager.errorModelInjector(this.settings, normalizedErrors, error);
            this._scrollToErrorField();
          }
        }
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  // @ts-expect-error
  _normalizeErrors(errors) {
    return (
      errors
        // @ts-expect-error
        .filter(e => Boolean(e.source?.pointer))
        // @ts-expect-error
        .reduce((errs, { source, detail }) => {
          let attribute = source.pointer.split('/').pop();
          if (['nextnumber', 'numberingpattern'].includes(attribute)) {
            attribute = source.pointer.split('/').slice(-2).join('');
          }
          //camelize did not work here for some reason so we are temporarily going with this solution
          switch (attribute) {
            case 'contactemail':
              attribute = 'contactEmail';
              break;
            case 'vatnumber':
              attribute = 'vatNumber';
              break;
            case 'invoicenextnumber':
              attribute = 'invoiceNextNumber';
              break;
            case 'quotenextnumber':
              attribute = 'quoteNextNumber';
              break;
            case 'invoicenumberingpattern':
              attribute = 'invoiceNumberingPattern';
              break;
            case '0':
              attribute = detail.code;
              break;
          }
          return {
            [attribute]: detail,
            ...errs,
          };
        }, {})
    );
  }

  _scrollToErrorField() {
    next(() => scrollIntoView('[data-has-error]'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::SettingsModalWithPreview': typeof ReceivableInvoicesSettingsModalWithPreviewComponent;
  }
}
