/* import __COLOCATED_TEMPLATE__ from './related-deposit.hbs'; */
import Component from '@glimmer/component';

import { RelatedDocument } from 'qonto/react/receivable-invoices/components/related-document';

interface RelatedDepositSignature {
  Element: HTMLFieldSetElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class ReceivableInvoicesFormRelatedDepositComponent extends Component<RelatedDepositSignature> {
  relatedDocument = RelatedDocument;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::RelatedDeposit': typeof ReceivableInvoicesFormRelatedDepositComponent;
  }
}
