export default {
  "header": "cvg",
  "header-type": "cvD",
  "close": "cvV",
  "close-icon": "cvL",
  "header-infos": "cvT",
  "title": "cvz body-1",
  "declined": "cva",
  "canceled": "cvH",
  "approved": "cvO",
  "pending": "cvm",
  "date": "cvp body-2",
  "container": "cvx",
  "picto": "cvw",
  "picto-status": "cvN",
  "general": "cvv",
  "amount": "cvo",
  "counterparty": "cvn body-2",
  "initiator": "cvi",
  "initiator-avatar": "cvA",
  "avatar-image": "cvY",
  "initiator-id": "cvh",
  "name": "cvf body-2",
  "role": "cvK caption-bold",
  "infobox": "cvG",
  "account-infobox": "cvr cvG",
  "beneficiary-warning": "cvb caption-bold",
  "dates-wrapper": "cvy",
  "dates": "cvU",
  "recurrence": "cvj caption-bold",
  "arrow": "cvW",
  "notify-checkbox": "cvu"
};
