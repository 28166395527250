/* import __COLOCATED_TEMPLATE__ from './mark-as-paid-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MarkAsPaidModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const MarkAsPaidModal = templateOnlyComponent<MarkAsPaidModalSignature>();

export default MarkAsPaidModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::MarkAsPaidModal': typeof MarkAsPaidModal;
  }
}
