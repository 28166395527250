/* import __COLOCATED_TEMPLATE__ from './beneficiary-sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface RequestTransferBeneficiarySidebarSignature {
  // The arguments accepted by the component
  Args: {
    isBeneficiaryLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestTransferBeneficiarySidebarComponent extends Component<RequestTransferBeneficiarySidebarSignature> {
  @service declare modals: Services['modals'];

  get emptyState() {
    // @ts-expect-error
    let { beneficiarySearchQuery, beneficiaries } = this.args;
    return beneficiarySearchQuery === null && !beneficiaries?.length;
  }

  get showUpload() {
    // @ts-expect-error
    return this.args.showUpload ?? true;
  }

  @action
  // @ts-expect-error
  addOrEditBeneficiary(beneficiary) {
    // @ts-expect-error
    let { transfer, refreshBeneficiariesList } = this.args;
    this.modals.open('beneficiary-form-modal', {
      isFullScreenModal: true,
      beneficiary,
      transfer,
      refreshBeneficiariesList,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::BeneficiarySidebar': typeof RequestTransferBeneficiarySidebarComponent;
  }
}
