/* import __COLOCATED_TEMPLATE__ from './page-header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { REQUEST_ORIGINS } from 'qonto/constants/requests';

interface RequestPageHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestPageHeader extends Component<RequestPageHeaderSignature> {
  @service declare intl: Services['intl'];
  @service declare subscriptionManager: Services['subscriptionManager'];

  origin = REQUEST_ORIGINS.reimbursements;

  get hasBankAccountFeature() {
    return this.subscriptionManager.hasFeature('bank_account');
  }

  get title() {
    if (!this.hasBankAccountFeature) {
      return this.intl.t('menu.main.expense-reports');
    } else if (variation('feature--boolean-ab-test-reimbursements')) {
      return this.intl.t('menu.main.reimbursements-ab-test');
    } else {
      return this.intl.t('menu.main.reimbursements');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::PageHeader': typeof RequestPageHeader;
  }
}
