/* import __COLOCATED_TEMPLATE__ from './schedule-transfer.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { Checkbox, DatePicker, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { REQUEST_TRANSFER_SCHEDULE_OPERATION_TYPES } from 'qonto/constants/requests';
import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
// @ts-expect-error
import { getCurrentParisDateString } from 'qonto/utils/date';

interface RequestTransferScheduleSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestTransferSchedule extends Component<RequestTransferScheduleSignature> {
  checkbox = Checkbox;
  datePicker = DatePicker;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare featuresManager: Services['featuresManager'];

  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;

  operationTypes = [
    ...new Set([
      ...REQUEST_TRANSFER_SCHEDULE_OPERATION_TYPES,
      SCHEDULE_OPERATION_TYPES.WEEKLY,
      SCHEDULE_OPERATION_TYPES.MONTHLY,
      SCHEDULE_OPERATION_TYPES.QUARTERLY,
      SCHEDULE_OPERATION_TYPES.HALF_YEARLY,
      SCHEDULE_OPERATION_TYPES.YEARLY,
    ]),
  ];

  operationTypeTranslations = {
    // @ts-expect-error
    scheduled_later: this.intl.t('transfers.frequency.scheduled_later'),
    // @ts-expect-error
    fx_scheduled: this.intl.t('transfers.frequency.fx_scheduled'),
    // @ts-expect-error
    standing_monthly_gender: this.intl.t('transfers.frequency.standing_monthly_gender'),
    // @ts-expect-error
    scheduled: this.intl.t('transfers.frequency.scheduled'),
    // @ts-expect-error
    standing_weekly_short: this.intl.t('transfers.frequency.standing_weekly_short'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.WEEKLY]: this.intl.t('transfers.frequency.standing_weekly'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.MONTHLY]: this.intl.t('transfers.frequency.standing_monthly'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.QUARTERLY]: this.intl.t('transfers.frequency.standing_quarterly'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.HALF_YEARLY]: this.intl.t('transfers.frequency.standing_half_yearly'),
    // @ts-expect-error
    [SCHEDULE_OPERATION_TYPES.YEARLY]: this.intl.t('transfers.frequency.standing_yearly'),
  };

  get isScheduledLater() {
    // @ts-expect-error
    return this.args.requestTransfer.operationType !== 'scheduled';
  }

  get scheduledDate() {
    // @ts-expect-error
    return this.args.requestTransfer.scheduledDate
      ? // @ts-expect-error
        parseDate(dayjs(this.args.requestTransfer.scheduledDate).format(DATE_PICKER_FIELD_FORMAT))
      : null;
  }

  get standingEndingDate() {
    // @ts-expect-error
    return this.args.requestTransfer.standingEndingDate
      ? parseDate(
          // @ts-expect-error
          dayjs(this.args.requestTransfer.standingEndingDate).format(DATE_PICKER_FIELD_FORMAT)
        )
      : null;
  }

  get minDate() {
    // @ts-expect-error
    let { requestTransfer } = this.args;

    let tomorrow = dayjs().add(1, 'day').startOf('day');
    let isDateValid =
      dayjs(requestTransfer.scheduledDate).isBefore(tomorrow) || this.isScheduledLater;

    let relevantDate = isDateValid ? tomorrow : requestTransfer.scheduledDate;
    return parseDate(dayjs(relevantDate).format(DATE_PICKER_FIELD_FORMAT));
  }

  get endMinDate() {
    return this.scheduledDate ? this.scheduledDate.add({ days: 1 }) : this.minDate;
  }

  get disclaimer() {
    let {
      weekly,
      monthly,
      quarterly,
      halfYearly,
      standing,
      standingEndingDate,
      scheduledDate,
      occurrences,
      // @ts-expect-error
    } = this.args.requestTransfer;

    if (!standing || !scheduledDate) {
      return null;
    }

    let disclaimerOptions = {
      startDate: dayjs(scheduledDate).toDate(),
      endDate: dayjs(standingEndingDate).toDate(),
      transferOccurences: occurrences,
    };

    if (weekly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_weekly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_weekly_frequency_unlimited', disclaimerOptions);
    } else if (monthly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_monthly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_monthly_frequency_unlimited', disclaimerOptions);
    } else if (quarterly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_quarterly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_quarterly_frequency_unlimited', disclaimerOptions);
    } else if (halfYearly) {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_half_yearly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_half_yearly_frequency_unlimited', disclaimerOptions);
    } else {
      return standingEndingDate
        ? this.intl.t('disclaimer.transfer_yearly_frequency', disclaimerOptions)
        : this.intl.t('disclaimer.transfer_yearly_frequency_unlimited', disclaimerOptions);
    }
  }

  // @ts-expect-error
  @action handleOperationType(operationType) {
    // @ts-expect-error
    let { trackingData, requestTransfer } = this.args;

    requestTransfer.changeOperationType(operationType);
    requestTransfer.standingOn = null;

    this.segment.track('single_transfer_request_frequency_filled', {
      frequency_type: operationType,
      member_id: trackingData.memberId,
      user_id: trackingData.userId,
    });
  }

  // @ts-expect-error
  @action setScheduledDate(date) {
    // @ts-expect-error
    let { requestTransfer } = this.args;

    requestTransfer.scheduledDate = date?.toString();

    // @ts-expect-error
    if (dayjs(requestTransfer.standingEndingDate).isBefore(this.endMinDate)) {
      requestTransfer.standingEndingDate = this.endMinDate;
    }
  }

  // @ts-expect-error
  @action setStandingEndingDate(date) {
    // @ts-expect-error
    let { requestTransfer } = this.args;
    requestTransfer.standingEndingDate = date?.toString();
  }

  @action handleToggleScheduled() {
    // @ts-expect-error
    let { requestTransfer } = this.args;

    let isScheduledLater = !this.isScheduledLater;
    let today = getCurrentParisDateString();

    if (!requestTransfer.scheduledDate) {
      requestTransfer.scheduledDate = today;
    }

    this._toggleScheduledState(isScheduledLater);
    this.segment.track('transfer_schedule_checkbox');
  }

  // @ts-expect-error
  _toggleScheduledState(isScheduledLater) {
    // @ts-expect-error
    let { requestTransfer } = this.args;

    if (isScheduledLater) {
      requestTransfer.changeOperationType('scheduled_later');
      let minDate = this.minDate;

      // @ts-expect-error
      let minDateinDateFormat = dayjs(minDate).toDate();
      if (dayjs(requestTransfer.scheduledDate).toDate() < minDateinDateFormat) {
        requestTransfer.scheduledDate = this.minDate;
      }
    } else {
      requestTransfer.changeOperationType('scheduled');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::ScheduleTransfer': typeof RequestTransferSchedule;
  }
}
