export default {
  "header": "cwo",
  "header-type": "cwn",
  "close": "cwi",
  "close-icon": "cwA",
  "header-infos": "cwY",
  "title": "cwh body-1",
  "declined": "cwf",
  "canceled": "cwK",
  "approved": "cwG",
  "pending": "cwr",
  "date": "cwb body-2",
  "mileage-icon": "cwy",
  "distance-subtitle": "cwU body-2",
  "link-icon": "cwj",
  "container": "cwW",
  "picto": "cwu",
  "general": "cNS",
  "amount": "cNc",
  "calculation-container": "cNq",
  "counterparty": "cNZ body-2",
  "infobox": "cNR"
};
