/* import __COLOCATED_TEMPLATE__ from './no-accounts-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface NoAccountsModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class NoAccountsModalComponent extends Component<NoAccountsModalSignature> {
  @service declare flowLinkManager: Services['flowLinkManager'];

  @action
  onClose() {
    // @ts-expect-error
    this.args.data.onClose?.();
  }

  @action
  onPrimaryCta() {
    this.flowLinkManager.transitionTo({
      name: 'external-account-import',
      stepId: 'select',
      queryParams: {
        origin: 'receivable-invoices',
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::NoAccountsModal': typeof NoAccountsModalComponent;
  }
}
