/* import __COLOCATED_TEMPLATE__ from './fetch-error.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FetchErrorSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const FetchError = templateOnlyComponent<FetchErrorSignature>();

export default FetchError;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReviewDuplicatesModal::FetchError': typeof FetchError;
  }
}
