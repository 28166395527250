/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox, Tag } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { INVOICING_STATUS_COLORS } from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ReviewDuplicatesModalItemSignature {
  // The arguments accepted by the component
  Args: {
    isChecked?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class ReviewDuplicatesModalItem extends Component<ReviewDuplicatesModalItemSignature> {
  checkbox = Checkbox;

  tag = Tag;

  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];

  openAttachmentInNewWindow = dropTask(async () => {
    try {
      // @ts-expect-error
      let attachment = await this.store.findRecord('attachment', this.args.duplicate.attachment.id);
      window.open(attachment.file.fileUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get status() {
    // @ts-expect-error
    let { status, displayedStatus } = this.args.duplicate;
    let statusColors = INVOICING_STATUS_COLORS;

    return {
      text: displayedStatus,
      // @ts-expect-error
      color: statusColors[status],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::ReviewDuplicatesModal::Item': typeof ReviewDuplicatesModalItem;
  }
}
