export default {
  "invoice-sidebar": "cHW",
  "body": "cHu",
  "box": "cOS",
  "box-header": "cOc",
  "footer": "cOq",
  "footer-button": "cOZ",
  "sidebar-box": "cOR",
  "border-top": "cOQ",
  "row": "cOe",
  "status-container": "cOB",
  "due-date-container": "cOE",
  "mark-as-btn": "cOd",
  "primary-actions": "cOI cOR",
  "primary-action": "cOt body-1",
  "button-icon": "cOM"
};
