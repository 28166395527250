export default {
  "header": "chO",
  "header-type": "chm",
  "close": "chp",
  "close-icon": "chx",
  "header-infos": "chw",
  "title": "chN body-1",
  "declined": "chv",
  "canceled": "cho",
  "approved": "chn",
  "pending": "chi",
  "date": "chA body-2",
  "mileage-icon": "chY",
  "distance-subtitle": "chh body-2",
  "link-icon": "chf",
  "container": "chK",
  "picto": "chG",
  "general": "chr",
  "amount": "chb",
  "calculation-container": "chy",
  "counterparty": "chU body-2",
  "infobox": "chj"
};
