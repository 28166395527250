export default {
  "cell-content": "cAs",
  "icon": "cAF",
  "status_check-m": "cAg",
  "description": "cAD body-1",
  "from": "cAV body-2",
  "from--abort": "cAL",
  "description--abort": "cAT",
  "title": "cAz body-2"
};
