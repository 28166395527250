/* import __COLOCATED_TEMPLATE__ from './item-search-trigger.hbs'; */
import Component from '@glimmer/component';

import { ItemSearchTrigger } from 'qonto/react/components/receivable-invoices/form/item-search-trigger';

interface ItemSearchTriggerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ItemSearchTriggerComponent extends Component<ItemSearchTriggerSignature> {
  itemSearchTrigger = ItemSearchTrigger;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::ItemSearchTrigger': typeof ItemSearchTriggerComponent;
  }
}
