export default {
  "section-container": "cwT",
  "disclaimer-section": "cwz",
  "trip-title": "cwa caption-bold mb-16",
  "google-link-container": "cwH",
  "link-icon": "cwO",
  "trip-details": "cwm body-2",
  "trip-details-icon": "cwp",
  "trip-details-title": "cwx",
  "subtitle": "cww caption-bold mb-16",
  "disclaimer": "cwN",
  "visualizer": "cwv"
};
