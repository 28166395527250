/* import __COLOCATED_TEMPLATE__ from './created.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import { DATE_FORMAT_TOKENS, dateToken } from '@qonto/ui-kit/utils/date-token';
import { BadgeStatus, Disclaimer } from '@repo/design-system-kit';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

// @ts-expect-error
import styles from 'qonto/components/receivable-invoices/invoice-modal/sidebar/created.module.css';
import { receivableInvoicesURL } from 'qonto/constants/hosts';
// @ts-expect-error
import { QUOTE_ESIGNATURE_LIFECYCLE_EVENT_STATUS, QUOTE_STATUSES } from 'qonto/constants/quotes';
import {
  CREATE_DEPOSIT_INVOICE_FROM_QUOTE_DISCOVERABILITY_STORAGE_KEY,
  EINVOICING_LIFECYCLE_EVENT_DESCRIPTION,
  EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES,
  EINVOICING_LIFECYCLE_EVENT_STATUS_CODES_TO_DISPLAY,
  EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES,
  INVOICED_STATUS,
  LOCAL_STORAGE_WARNING_DISMISSED_KEY,
  SENTRY_IGNORE_HTTP_STATUSES,
  STATUS,
} from 'qonto/constants/receivable-invoice';
import { importAsset } from 'qonto/helpers/import-asset';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';
import { InvoicedAmount } from 'qonto/react/components/receivable-invoices/invoice-modal';
import { Share } from 'qonto/react/payment-links/components/payment-links/sidebar/share';
import { RelatedDocument } from 'qonto/react/receivable-invoices/components/related-document';
import { getClientDisplayName } from 'qonto/utils/client-hub';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import {
  getQuoteEsignatureLifecycleEventDescription,
  getQuoteEsignatureLifecycleEvents,
} from 'qonto/utils/receivable-invoicing';

import ReceivableInvoicesSidebarSharedComponent from './sidebar-shared';

export default class ReceivableInvoicesInvoiceModalSidebarCreatedComponent extends ReceivableInvoicesSidebarSharedComponent {
  badgeStatus = BadgeStatus;
  instructionalTooltip = InstructionalTooltip;
  InvoicedAmount = InvoicedAmount;
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  share = Share;
  relatedDocument = RelatedDocument;

  // @ts-expect-error
  @service intl;
  // @ts-expect-error
  @service modals;
  // @ts-expect-error
  @service toastFlashMessages;
  // @ts-expect-error
  @service sentry;
  // @ts-expect-error
  @service segment;
  // @ts-expect-error
  @service router;
  // @ts-expect-error
  @service organizationManager;
  // @ts-expect-error
  @service store;
  // @ts-expect-error
  @service notifierManager;
  // @ts-expect-error
  @service subscriptionManager;
  // @ts-expect-error
  @service abilities;
  // @ts-expect-error
  @service localeManager;

  @tracked isInstructionalTooltipDismissed = false;
  @tracked quote: any;
  @tracked depositInvoice: any;

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.prepareDocuments();
  }

  async prepareDocuments() {
    // @ts-expect-error
    if (this.args.invoicingDocument.quote) {
      // @ts-expect-error
      this.quote = await this.args.invoicingDocument.belongsTo('quote').load();
    }

    // @ts-expect-error
    if (this.args.invoicingDocument.depositInvoice) {
      // @ts-expect-error
      this.depositInvoice = await this.args.invoicingDocument.belongsTo('depositInvoice').load();
    }
  }

  get shouldDisplayPaymentLinks() {
    // @ts-expect-error
    let { status, paymentLink } = this.args.invoicingDocument;
    return (
      status === STATUS.UNPAID &&
      paymentLink?.methods?.length &&
      this.abilities.can('read paymentLink')
    );
  }

  get shareableLink() {
    return `${receivableInvoicesURL}/invoices/${this.invoicingDocument.id}`;
  }

  get dueOrExpiryDate() {
    if (this.isQuote) {
      return this.invoicingDocument.expiryDate;
    }
    return this.invoicingDocument.dueDate;
  }

  get shouldDisplayPerformanceDate() {
    // @ts-expect-error
    return this.args.invoicingDocument.performanceDate && !this.args.isQuote;
  }

  get shouldDisplayPerformanceDateWithRange() {
    return (
      variation('feature--boolean-ar-advanced-customization') &&
      // @ts-expect-error
      this.args.invoicingDocument.performanceStartDate &&
      !this.args.isQuote
    );
  }

  get performanceDatePeriod() {
    return this.intl.t('receivable-invoices.invoice-modal.performance-period.date-range-label', {
      startDate: dateToken({
        // @ts-expect-error
        date: this.args.invoicingDocument.performanceStartDate,
        locale: this.intl.locale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      }),
      endDate: dateToken({
        // @ts-expect-error
        date: this.args.invoicingDocument.performanceEndDate,
        locale: this.intl.locale,
        token: DATE_FORMAT_TOKENS.DATE_YEAR_S,
      }),
    });
  }

  get shouldDisplayNextReminderDate() {
    return (
      this.invoicingDocument?.status === STATUS.UNPAID && this.invoicingDocument?.nextReminderDate
    );
  }

  get isFrenchEinvoice() {
    return (
      // @ts-expect-error
      (this.args.invoicingDocument.isEinvoice ||
        this.invoicingDocument?.customerSnapshot?.eInvoicingAddress) &&
      this.invoicingDocument?.status !== STATUS.DRAFT
    );
  }

  get shouldDisplayRemindersCTA() {
    let invoiceIsUnpaid = this.invoicingDocument?.status === STATUS.UNPAID;

    return invoiceIsUnpaid && this.abilities.can('create reminders client-hubs');
  }

  get remindersCTAText() {
    let customer = this.invoicingDocument?.belongsTo('customer');

    if (!customer) {
      return this.intl.t('receivable-invoices.invoice-modal.setup-payment-reminders');
    }

    let client = this.store.peekRecord('client-hub', customer.id());

    return client?.hasReminders
      ? this.intl.t('receivable-invoices.invoice-modal.edit-payment-reminders')
      : this.intl.t('receivable-invoices.invoice-modal.setup-payment-reminders');
  }

  get isNotCurrencyMismatch() {
    return this.invoicingDocument?.invoicedStatus !== INVOICED_STATUS.CURRENCY_MISMATCH;
  }

  get createInvoiceTooltip() {
    if (this.args.isGenerateInvoiceDisabled) {
      return this.intl.t(
        'receivable-invoices.invoice-modal.actions.create-invoice.basic-plan-tooltip'
      );
    }
  }

  get showRelatedDocuments() {
    if (this.isQuote) {
      return this.invoicingDocument.hasMany('receivableInvoices').ids().length > 0;
    }

    return (
      this.invoicingDocument.receivableCreditNotes.length > 0 ||
      this.invoicingDocument.balanceInvoices.length > 0 ||
      this.invoicingDocument.depositInvoice ||
      this.invoicingDocument.quote
    );
  }

  get isEditable() {
    let editableStatus = ['pending_approval', 'draft'];
    return editableStatus.includes(this.invoicingDocument?.status);
  }

  get showEditButton() {
    return !this.showQuoteEsignatureLifecycleEvents;
  }

  get hasFooterActions() {
    let quoteStatus = ['pending_approval', 'approved'];
    let invoiceStatus = ['draft', 'unpaid'];
    let isPaidDepositWithoutActiveBalanceInvoices =
      this.invoicingDocument?.isDeposit &&
      this.invoicingDocument?.status === STATUS.PAID &&
      // @ts-expect-error
      !this.invoicingDocument.balanceInvoices?.find(({ status }) => status !== STATUS.CANCELED);

    return (
      quoteStatus.includes(this.invoicingDocument?.status) ||
      invoiceStatus.includes(this.invoicingDocument?.status) ||
      isPaidDepositWithoutActiveBalanceInvoices
    );
  }

  get sharePrimaryAction() {
    return (
      (this.invoicingDocument?.status === 'unpaid' && !this.invoicingDocument?.isDeposit) ||
      this.invoicingDocument?.status === 'canceled'
    );
  }

  @action createInvoiceFromQuote() {
    this.segment.track('quote_options_generate-invoice');
    this.router.transitionTo('receivable-invoices.new', {
      queryParams: { quoteId: this.invoicingDocument.id },
    });
  }

  @action createDepositInvoiceFromQuote() {
    this.segment.track('quote_options_generate-deposit-invoice');
    this.router.transitionTo('receivable-invoices.new', {
      queryParams: { quoteId: this.invoicingDocument.id, isDeposit: true },
    });
  }

  get shouldDisplayCreateDepositInvoiceCTA() {
    if (this.invoicingDocument.status !== QUOTE_STATUSES.APPROVED) {
      return false;
    }

    // @ts-expect-error
    let notCancelledRelatedDeposits = this.args.invoicingDocument.get('receivableInvoices')?.filter(
      // @ts-expect-error
      invoice => invoice.status !== STATUS.CANCELED
    );

    return notCancelledRelatedDeposits?.length === 0;
  }

  @action createBalanceInvoiceFromDeposit() {
    this.segment.track('invoice_options_generate-balance-invoice');

    let queryParams = {
      depositId: this.invoicingDocument.id,
    };

    if (this.invoicingDocument.quote?.id) {
      // @ts-expect-error
      queryParams.quoteId = this.invoicingDocument.quote.id;
    }

    this.router.transitionTo('receivable-invoices.new', {
      queryParams,
    });
  }

  @action createBalanceInvoiceFromQuote() {
    this.segment.track('quote_options_generate-balance-invoice');

    // @ts-expect-error
    let relatedDepositId = this.args.invoicingDocument.get('receivableInvoices')?.find(
      // @ts-expect-error
      invoice => invoice.isDeposit && invoice.status !== STATUS.CANCELED
    ).id;
    this.router.transitionTo('receivable-invoices.new', {
      queryParams: { quoteId: this.invoicingDocument.id, depositId: relatedDepositId },
    });
  }

  @action createBalanceInvoice() {
    if (this.isQuote) {
      this.createBalanceInvoiceFromQuote();
    }
    if (this.invoicingDocument.isDeposit) {
      this.createBalanceInvoiceFromDeposit();
    }
  }

  get shouldDisplayCreateBalanceInvoiceCTA() {
    if (this.abilities.cannot('update receivableInvoice')) {
      return false;
    }

    if (this.isQuote) {
      // We cannot use Array.some as receivableInvoices is an async DS.ManyArray
      let hasActiveDepositInvoices = Boolean(
        this.invoicingDocument.get('receivableInvoices')?.find(
          // @ts-expect-error
          ({ isDeposit, status }) => isDeposit && status !== STATUS.CANCELED
        )
      );

      let hasNoActiveBalanceInvoices = !this.invoicingDocument.get('receivableInvoices')?.find(
        // @ts-expect-error
        ({ status, isDeposit }) => !isDeposit && status !== STATUS.CANCELED
      );

      return hasActiveDepositInvoices && hasNoActiveBalanceInvoices;
    }

    return (
      this.invoicingDocument?.isDeposit &&
      ['paid', 'unpaid'].includes(this.invoicingDocument?.status) &&
      // @ts-expect-error
      this.invoicingDocument.balanceInvoices?.every(({ status }) => status === STATUS.CANCELED)
    );
  }

  @action openRemindersConfigurationModal() {
    let client = this.store.peekRecord(
      'client-hub',
      this.invoicingDocument.belongsTo('customer').id()
    );

    if (client?.hasReminders) {
      this.segment.track('invoice-details_edit-reminder_clicked');
    } else {
      this.segment.track('invoice-details_set-up-reminder_clicked');
    }

    let clientId = this.invoicingDocument.customer.id;
    this.router.transitionTo('clients.client.reminders-configuration', clientId);
  }

  @action openCancelModal() {
    this.segment.track('invoice_options_cancel');
    if (this.isFrenchOrganization) {
      this.modals.open(
        'receivable-invoices/cancel-invoice-modal-fr-org',
        {
          title: this.intl.t('receivable-invoices.invoice-modal.cancel-invoice-modal.title'),
          description: this.intl.t(
            'receivable-invoices.invoice-modal.cancel-invoice-modal.description-credit-note',
            {
              invoiceNumber: this.invoicingDocument.number,
            }
          ),
          cancelInvoiceWithCreditNoteTask: this.cancelInvoiceWithCreditNoteTask,
          cancelInvoiceWithCreditNoteLabel: this.intl.t(
            'receivable-invoices.invoice-modal.cancel-invoice-modal.cancel-with-credit-note'
          ),
          cancelInvoiceLabel: this.intl.t(
            'receivable-invoices.invoice-modal.cancel-invoice-modal.cancel'
          ),
          cancelInvoiceTask: this.cancelInvoiceTask,
        },
        {
          className: 'epm-popup-modal',
        }
      );
    } else {
      this.modals.open(
        'receivable-invoices/cancel-invoice-modal',
        {
          title: this.intl.t('receivable-invoices.invoice-modal.cancel-invoice-modal.title'),
          description: this.intl.t(
            'receivable-invoices.invoice-modal.cancel-invoice-modal.description',
            {
              invoiceNumber: this.invoicingDocument.number,
            }
          ),
          cancel: this.intl.t('btn.back'),
          confirm: this.intl.t('receivable-invoices.invoice-modal.cancel-invoice-modal.cancel'),
          confirmTask: this.cancelInvoiceTask,
        },
        {
          className: 'epm-popup-modal',
        }
      );
    }
  }

  cancelInvoiceWithCreditNoteTask = dropTask(async closeModal => {
    this.segment.track('invoice_cancel_with_credit_note_confirmed');
    this.transitionToCreditNoteForm();
    await closeModal();
  });

  @action createCreditNoteFromCanceledInvoice() {
    this.segment.track('invoice_option_create_credit_note_from_cancelled');
    this.transitionToCreditNoteForm();
  }

  @action
  cancelInvoiceWithCreditNote() {
    this.segment.track('invoice_options_create_credit_note');
    this.transitionToCreditNoteForm();
  }

  @action
  transitionToCreditNoteForm() {
    this.router.transitionTo('receivable-invoices.credit-notes.new', {
      queryParams: { invoiceId: this.invoicingDocument.id },
    });
  }

  cancelInvoiceTask = dropTask(async closeModal => {
    try {
      this.segment.track('invoice_cancel_confirmed');
      this.invoicingDocument.status = 'canceled';
      await this.invoicingDocument.save({ adapterOptions: { partial: true } });
      this.toastFlashMessages.toastInfo(
        this.intl.t('receivable-invoices.invoice-modal.cancel-invoice-modal.success', {
          invoiceNumber: this.invoicingDocument.number,
        })
      );
    } catch (error) {
      this.invoicingDocument.rollbackAttributes();
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('receivable-invoices.index');
    }
  });

  markAsPaidInvoiceTask = dropTask(async closeModal => {
    try {
      this.segment.track('invoice_mark-as-paid_confirmed');
      this.invoicingDocument.status = 'paid';
      await this.invoicingDocument.save({ adapterOptions: { partial: true } });
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoice-modal.mark-as-paid-modal.success', {
          invoiceNumber: this.invoicingDocument.number,
        })
      );
    } catch (error) {
      this.invoicingDocument.rollbackAttributes();
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('receivable-invoices.index');
    }
  });

  @action handleEdit() {
    if (this.isQuote) {
      return this.handleEditQuote();
    }
    return this.handleEditDraft();
  }

  @action handleEditQuote() {
    this.segment.track('quote_options_edit');
    this.router.transitionTo('quotes.edit', this.invoicingDocument.id);
  }

  @action handleEditDraft() {
    this.segment.track('invoice-draft_options_edit');
    this.router.transitionTo('receivable-invoices.edit', this.invoicingDocument.id);
  }

  @action handleEditPaymentDetails() {
    this.router.transitionTo('receivable-invoices.edit.payment', this.invoicingDocument.id);
  }

  @action handleEditProducts() {
    this.router.transitionTo('receivable-invoices.edit.products', this.invoicingDocument.id);
  }

  deleteDraftTask = dropTask(async closeModal => {
    try {
      await this.invoicingDocument.destroyRecord();
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('receivable-invoices.index');
    }
  });

  @action
  goToShareInvoice() {
    if (this.isQuote) {
      this.segment.track('quote_options_send');
      this.router.transitionTo('quotes.share', this.invoicingDocument.id);
      return;
    }
    this.segment.track(this.shareInvoiceEvent);
    this.router.transitionTo('receivable-invoices.share', this.invoicingDocument.id);
  }

  get shouldDisplayPaymentDate() {
    return this.invoicingDocument.status === STATUS.PAID;
  }

  get shareInvoiceEvent() {
    // @ts-expect-error
    return {
      draft: 'invoice-draft_options_send',
      unpaid: 'invoice_options_send_invoice',
    }[this.invoicingDocument.status];
  }

  get cityAddress() {
    let billingAddress = this.invoicingDocument.customerSnapshot?.billingAddress;

    if (!billingAddress) {
      return '';
    }

    if (billingAddress.countryCode) {
      let country = this.intl.formatCountry(billingAddress.countryCode);
      return `${billingAddress.zipCode} ${billingAddress.city}, ${country}`;
    }
    return `${billingAddress.zipCode} ${billingAddress.city}`;
  }

  get deliveryCityAddress() {
    let deliveryAddress = this.invoicingDocument.customerSnapshot?.deliveryAddress;

    if (!deliveryAddress) {
      return '';
    }

    if (deliveryAddress.countryCode) {
      let country = this.intl.formatCountry(deliveryAddress.countryCode);
      return `${deliveryAddress.zipCode} ${deliveryAddress.city}, ${country}`;
    }
    return `${deliveryAddress.zipCode} ${deliveryAddress.city}`;
  }

  get goToShareInvoiceCTAText() {
    if (this.shouldDisplayFrEinvoicing) {
      return this.intl.t('receivable-invoices.invoice-modal.actions.send-einvoice');
    }

    // @ts-expect-error
    return {
      pending_approval: this.intl.t('receivable-invoices.invoice-modal.quotes.actions.send-quote'),
      approved: this.intl.t('receivable-invoices.invoice-modal.quotes.actions.send-quote'),
      draft: this.intl.t('receivable-invoices.invoice-modal.actions.share-draft'),
      unpaid: this.intl.t('receivable-invoices.invoice-modal.actions.send-invoice'),
      paid: this.intl.t('receivable-invoices.invoice-modal.actions.send-invoice'),
    }[this.invoicingDocument.status];
  }

  get isEInvoicing() {
    return this.organizationManager.organization.legalCountry === 'IT';
  }

  get finalizeDraftModalDescription() {
    // French e-invoicing
    if (this.shouldDisplayFrEinvoicing) {
      return this.intl.t('receivable-invoices.issue-modal.einvocing-subtitle');
    }

    let shouldDisplayItalyCopy = this.isEInvoicing;
    // @ts-expect-error
    let isAutoNumbering = this.args.settings?.numberingMode === 'automatic';

    if (isAutoNumbering) {
      // @ts-expect-error
      let { nextInvoiceNumber, invoiceNextNumberFormatted } = this.args.settings;
      let invoiceNumber = invoiceNextNumberFormatted || nextInvoiceNumber;
      return shouldDisplayItalyCopy
        ? this.intl.t('receivable-invoices.issue-modal.draft.description.italy.automatic', {
            invoiceNumber,
          })
        : this.intl.t('receivable-invoices.issue-modal.draft.description.automatic', {
            invoiceNumber,
          });
    }

    return shouldDisplayItalyCopy
      ? this.intl.t('receivable-invoices.issue-modal.draft.description.italy.manual')
      : this.intl.t('receivable-invoices.issue-modal.draft.description.manual');
  }

  get shouldDisplayCheckbox() {
    return (
      // @ts-expect-error
      this.args.settings?.numberingMode === 'manual' &&
      safeLocalStorage.getItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY) !== 'true'
    );
  }

  get qontoCustomerFrEinvoicingEnabled() {
    let customer = this.invoicingDocument?.belongsTo('customer');

    if (customer?.id()) {
      let client = this.store.peekRecord('client-hub', customer.id());

      return client ? client?.einvoicing : false;
    }

    return false;
  }

  get externalCustomerFrEinvoicingEnabled() {
    return Boolean(this.invoicingDocument?.customerSnapshot?.eInvoicingAddress);
  }

  // French e-invoicing
  get shouldDisplayFrEinvoicing() {
    return (
      // @ts-expect-error
      (this.args?.canCreateFrEinvoice &&
        this.qontoCustomerFrEinvoicingEnabled &&
        variation('run-task--boolean-einvoicing-fr-rollback')) ||
      this.externalCustomerFrEinvoicingEnabled
    );
  }

  get showFrenchEinvoicingTimeline() {
    return Boolean(this.frenchEinvoicingEventsToDisplay.length);
  }

  get frenchEinvoicingTimelineTriggerText() {
    let latestEventDescription = EINVOICING_LIFECYCLE_EVENT_DESCRIPTION(
      this.intl,
      this.frenchEinvoicingCurrentStatusCode
    );

    return htmlSafe(`
      <div>
        <span class="${styles['timeline-status-label']}">
          ${this.intl.t('receivable-invoices.einvoicing-timeline.title')}
        </span>
        <span class="ml-8">
          ${latestEventDescription}
        </span>
      </div>
    `);
  }

  get frenchEinvoicingCurrentStatusCode() {
    return this.frenchEinvoicingEventsToDisplay.at(-1)?.status_code;
  }

  get frenchEinvoicingEventsToDisplay() {
    let events = this.invoicingDocument?.einvoicingLifecycleEvents || [];
    // @ts-expect-error
    return events.filter(item =>
      EINVOICING_LIFECYCLE_EVENT_STATUS_CODES_TO_DISPLAY.includes(item.status_code)
    );
  }

  get frenchEinvoicingTimelineSteps() {
    // @ts-expect-error
    return this.frenchEinvoicingEventsToDisplay.map((event, index) => {
      let description = EINVOICING_LIFECYCLE_EVENT_DESCRIPTION(this.intl, event.status_code);
      let timeFormat = this.localeManager.locale === 'en' ? 'hh:mm A' : 'HH:mm';
      let caption = dayjs(event.timestamp).format(`MMM DD, YYYY · ${timeFormat}`);
      let reason = event.reason;
      let comment = event.reason_message;
      let showPdpTooltip = description?.includes('PDP');
      let isLastEvent = index === this.frenchEinvoicingEventsToDisplay.length - 1;
      let stepIcon = this.frenchEInvoicingTimelineStepIcon(event.status_code, isLastEvent);

      return {
        icon: { type: stepIcon, filled: isLastEvent },
        description,
        caption,
        additionalInformation:
          (reason || comment) &&
          htmlSafe(`${
            reason
              ? this.intl.t('receivable-invoices.einvoicing-timeline.subtitle.reason', { reason })
              : ''
          }
        ${
          comment
            ? `${reason ? `<br/>` : ''}${this.intl.t('receivable-invoices.einvoicing-timeline.subtitle.comment', { comment })}`
            : ''
        }`),
        tooltipInfoMessage: showPdpTooltip
          ? this.intl.t('receivable-invoices.einvoicing-timeline.pdp-tooltip')
          : null,
        isLastEvent,
      };
    });
  }

  // Quote E-signature
  get esignatureLifecycleEvents() {
    return getQuoteEsignatureLifecycleEvents(
      this.invoicingDocument?.electronicSignatureLifecycle,
      this.invoicingDocument.status
    );
  }

  get showQuoteEsignatureLifecycleEvents() {
    return (
      variation('feature--boolean-ar-quotes-esignature') &&
      this.isQuote &&
      Boolean(this.invoicingDocument?.electronicSignatureLifecycle)
    );
  }

  get quoteEsignatureLifecycleEventsCurrentStatusText() {
    let latestEventDescription = getQuoteEsignatureLifecycleEventDescription(
      this.intl,
      this.esignatureLifecycleEvents?.at(-1)?.status ?? ''
    )?.short;

    return htmlSafe(`
      <div>
        <span class="${styles['timeline-status-label']}">
          ${this.intl.t('receivable-invoices.invoice-modal.quote.e-signature.timeline.status.title')}
        </span>
        <span class="ml-8">
          ${latestEventDescription}
        </span>
      </div>
    `);
  }

  quoteEsignatureLifecycleEventsStepIcon(status: string, isLastEvent: boolean): string {
    let { CANCELED, EXPIRED, SIGNED_BY_CLIENT, WAITING } = QUOTE_ESIGNATURE_LIFECYCLE_EVENT_STATUS;

    if (status === CANCELED || status === EXPIRED) {
      return 'reject';
    }
    if (status === WAITING) {
      return 'pending-filled';
    }
    if (status === SIGNED_BY_CLIENT || isLastEvent) {
      return 'success';
    }
    return 'default';
  }

  quoteEsignatureLifecycleEventsUserName(eventStatus: string): string | undefined {
    let { OPENED, SENT, SIGNED_BY_CLIENT, SIGNED_BY_QONTO_USER, WAITING } =
      QUOTE_ESIGNATURE_LIFECYCLE_EVENT_STATUS;
    let clientName = getClientDisplayName(this.invoicingDocument?.customerSnapshot);
    let qontoUserName = this.invoicingDocument?.organizationSnapshot?.legalName;

    switch (eventStatus) {
      case OPENED:
      case SENT:
      case SIGNED_BY_CLIENT:
      case WAITING:
        return clientName;
      case SIGNED_BY_QONTO_USER:
        return qontoUserName;
      default:
        return undefined;
    }
  }

  get quoteEsignatureLifecycleEventsSteps() {
    const { SIGNED_BY_CLIENT } = QUOTE_ESIGNATURE_LIFECYCLE_EVENT_STATUS;

    return this.esignatureLifecycleEvents?.map(
      (event: { status: string; timestamp?: string }, index: number) => {
        let name = this.quoteEsignatureLifecycleEventsUserName(event.status);
        let description = getQuoteEsignatureLifecycleEventDescription(
          this.intl,
          event.status,
          name
        )?.long;
        let timeFormat = this.localeManager.locale === 'en' ? 'hh:mm A' : 'HH:mm';
        let caption = event?.timestamp
          ? dayjs(event.timestamp).format(`MMM DD, YYYY · ${timeFormat}`)
          : undefined;
        let isLastEvent = index === (this.esignatureLifecycleEvents?.length ?? 0) - 1;
        let stepIcon = this.quoteEsignatureLifecycleEventsStepIcon(event.status, isLastEvent);
        let filledStepIcon = isLastEvent && event.status !== SIGNED_BY_CLIENT;

        return {
          icon: { type: stepIcon, filled: filledStepIcon },
          description,
          caption,
          isLastEvent,
        };
      }
    );
  }

  get useSmallerFontSize() {
    try {
      return this.shouldDisplayCreateBalanceInvoiceCTA && this.displayShareInvoiceCTA;
    } catch {
      return false;
    }
  }

  get hasNoSections() {
    if (!variation('feature--boolean-ar-advanced-customization')) {
      return false;
    }
    return isEmpty(this.invoicingDocument.sections);
  }

  // @ts-expect-error
  frenchEInvoicingTimelineStepIcon(statusCode, isLastEvent) {
    let isfrenchEInvoicingWarningStatus =
      EINVOICING_LIFECYCLE_EVENT_WARNING_STATUS_CODES.includes(statusCode);

    let isfrenchEInvoicingErrorStatus =
      EINVOICING_LIFECYCLE_EVENT_ERROR_STATUS_CODES.includes(statusCode);

    if (isfrenchEInvoicingWarningStatus && isLastEvent) {
      return `${statusCode === 214 ? 'default-colour ' : ''}warning-filled`;
    }
    if (isfrenchEInvoicingWarningStatus) {
      return 'warning';
    }
    if (isLastEvent && !isfrenchEInvoicingErrorStatus) {
      return 'success';
    }
    if (isfrenchEInvoicingErrorStatus) {
      return 'reject';
    }

    return 'default';
  }

  @action
  trackTimelineOpened() {
    this.segment.track('invoice-timeline_drawer_opened', {
      source: 'AR',
      latest_status: this.frenchEinvoicingCurrentStatusCode,
    });
  }

  @action openFinalizeDraftModal() {
    this.segment.track('invoice-draft_create-invoice');
    this.modals.open(
      'receivable-invoices/confirm-creation-modal',
      {
        title: this.intl.t('receivable-invoices.issue-modal.draft.title'),
        description: this.finalizeDraftModalDescription,
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('receivable-invoices.issue-modal.draft.cta.create-invoice'),
        confirmTask: this.checkIsCustomerUpdatedOrInvalidTask,
        shouldDisplayCheckbox: this.shouldDisplayCheckbox,
        shouldDisplayFrEinvoicing: this.shouldDisplayFrEinvoicing,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  }

  // @ts-expect-error
  redirectToEditTask = dropTask(this, async closeModal => {
    this.router.transitionTo('receivable-invoices.edit', this.invoicingDocument.id);
    await closeModal?.();
  });

  // @ts-expect-error
  checkIsCustomerUpdatedOrInvalidTask = dropTask(this, async closeModal => {
    let client;
    let customer = this.store.peekRecord(
      'customer',
      this.invoicingDocument.belongsTo('customer').id()
    );

    client = this.store.peekRecord('client-hub', this.invoicingDocument.belongsTo('customer').id());
    await client?.validate();

    let isClientMissing = !customer && !client;

    if (client?.validations.isInvalid) {
      await this.modals.open('receivable-invoices/invalid-client-modal', {
        title: this.intl.t('receivable-invoices.invoice-modal.draft-errors.invalid-client.title'),
        modalDescription: this.intl.t(
          'receivable-invoices.invoice-modal.draft-errors.invalid-client.description'
        ),
        confirm: this.intl.t(
          'receivable-invoices.invoice-modal.draft-errors.invalid-client.cta.update'
        ),
        cancel: this.intl.t('btn.cancel'),
        confirmTask: this.redirectToEditClientTask,
        client,
      });
      closeModal?.();
    } else if (customer && customer.get('updatedAt') > this.invoicingDocument.get('updatedAt')) {
      await this.modals.open('receivable-invoices/updated-customer-modal', {
        title: this.intl.t('receivable-invoices.invoice-modal.draft-errors.client-changed.title'),
        modalDescription: this.intl.t(
          'receivable-invoices.invoice-modal.draft-errors.client-changed.description',
          { amountDue: `${this.invoicingDocument.amountDue} ${customer.currency}` }
        ),
        edit: this.intl.t('receivable-invoices.invoice-modal.draft-errors.cta.edit-draft'),
        confirm: this.intl.t('btn.confirm'),
        customer,
        confirmTask: this.finalizeDraftTask,
        redirectTask: this.redirectToEditTask,
      });
      closeModal?.();
    } else {
      this.finalizeDraftTask
        // @ts-expect-error
        .perform(closeModal, isClientMissing)
        // @ts-expect-error
        .catch(error => this.handleError(error));
    }
  });

  // @ts-expect-error
  finalizeDraftTask = dropTask(this, async (closeModal, isClientMissing) => {
    let oldInvoiceNumber = this.invoicingDocument.number;
    try {
      // @ts-expect-error
      if (this.args.settings?.numberingMode === 'automatic' && !this.isQuote) {
        this.invoicingDocument.number = null;
      }
      await this.invoicingDocument.finalizeDraft();
      closeModal?.();

      let origin = 'receivable-invoices.draft-finalize';

      this.router.transitionTo('receivable-invoices.share', this.invoicingDocument.id, {
        queryParams: { origin },
      });

      this.toastFlashMessages.toastSuccess(this.intl.t('receivable-invoices.issue-modal.toaster'));
      this.segment.track('invoice-draft_create-invoice_confirm');
    } catch (error) {
      this.invoicingDocument.number = oldInvoiceNumber;
      closeModal?.();
      let modalParams;
      if (
        isClientMissing ||
        // @ts-expect-error
        (error.status === 422 && this.invoicingDocument.errors.has('customer/data/id'))
      ) {
        modalParams = {
          title: this.intl.t(
            'receivable-invoices.invoice-modal.draft-errors.client-not-exists.title'
          ),
          description: this.intl.t(
            'receivable-invoices.invoice-modal.draft-errors.client-not-exists.description'
          ),
          illustration: importAsset(['/illustrations/ill-pop-invoices-client-not-existing.svg']),
        };
        // @ts-expect-error
      } else if (error.status === 409) {
        modalParams = {
          title: this.intl.t('receivable-invoices.invoice-modal.draft-errors.number-exists.title'),
          description: this.intl.t(
            'receivable-invoices.invoice-modal.draft-errors.number-exists.description'
          ),
          illustration: importAsset(['/illustrations/ill-cp-general-rejected.svg']),
        };
      }

      if (modalParams) {
        await this.modals.open('receivable-invoices/finalize-error-modal', {
          cancel: this.intl.t('btn.cancel'),
          confirm: this.intl.t('receivable-invoices.invoice-modal.draft-errors.cta.edit-draft'),
          confirmTask: this.redirectToEditTask,
          ...modalParams,
        });
      } else {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    } finally {
      if (this.shouldDisplayCheckbox) {
        safeLocalStorage.setItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY, 'true');
      }
    }
  });

  // @ts-expect-error
  redirectToEditClientTask = dropTask(this, async (closeModal, client) => {
    this.router.transitionTo('clients.client.edit', client.id, {
      queryParams: { redirect: 'receivable-invoices.edit', redirectId: this.invoicingDocument.id },
    });
    await closeModal?.();
  });

  get isFrenchOrganization() {
    return this.organizationManager.organization.legalCountry === 'FR';
  }

  get shouldDisplayCreateCreditNoteCTA() {
    if (this.invoicingDocument.isDeposit) {
      return (
        this.abilities.can('update receivableInvoice') &&
        // @ts-expect-error
        this.invoicingDocument.balanceInvoices.every(invoice => invoice.status === STATUS.CANCELED)
      );
    }
    return (
      this.abilities.can('update receivableInvoice') &&
      !(
        this.isEInvoicing &&
        ['pending', 'declined'].includes(this.invoicingDocument.get('einvoicingStatus'))
      )
    );
  }

  get shouldDisplayCreateCreditNoteWhenCanceled() {
    return (
      this.abilities.can('update receivableInvoice') &&
      this.invoicingDocument.status === STATUS.CANCELED &&
      this.invoicingDocument.receivableCreditNotes?.length === 0
    );
  }

  get languageDescription() {
    let description = this.intl.t('receivable-invoices.invoice-modal.invoice-language', {
      language: this.abilities.cannot('read client-hubs')
        ? this.invoicingDocument.locale
        : (this.invoicingDocument.locale ?? this.invoicingDocument.get('customer.locale')),
    });
    // @ts-expect-error
    let [label, content] = description.split(':').map(string => string.trimStart());
    return { label: `${label}:`, content };
  }

  get shouldDisplayInstructionalTooltip() {
    return !safeLocalStorage.getItem(CREATE_DEPOSIT_INVOICE_FROM_QUOTE_DISCOVERABILITY_STORAGE_KEY);
  }

  get displayShareInvoiceCTA() {
    return (
      this.abilities.can('update receivableInvoice') &&
      !this.isExpired &&
      this.invoicingDocument?.status !== STATUS.PAID
    );
  }

  @action
  handleDismissInstructionalTooltip() {
    // @ts-expect-error
    safeLocalStorage.setItem(CREATE_DEPOSIT_INVOICE_FROM_QUOTE_DISCOVERABILITY_STORAGE_KEY, true);
    this.isInstructionalTooltipDismissed = true;
  }

  @action openApproveQuoteModal() {
    let hasElectronicSignature =
      variation('feature--boolean-ar-quotes-esignature') &&
      Boolean(this.invoicingDocument?.electronicSignatureLifecycle);

    let description = hasElectronicSignature
      ? this.intl.t('receivable-invoices.invoice-modal.quote.e-signature.approval-popup.body')
      : this.intl.t('receivable-invoices.invoice-modal.approve-quote-modal.description');

    this.segment.track('quote_options_mark-as-approved');
    this.modals.open('receivable-invoices/mark-as-paid-modal', {
      title: this.intl.t('receivable-invoices.invoice-modal.approve-quote-modal.title'),
      description,
      cancel: this.intl.t('receivable-invoices.invoice-modal.approve-quote-modal.return'),
      confirm: this.intl.t('receivable-invoices.invoice-modal.approve-quote-modal.confirm'),
      confirmTask: this.approveQuoteTask,
    });
  }

  approveQuoteTask = dropTask(async closeModal => {
    this.segment.track('quote_mark-as-approved_confirmed');
    try {
      await this.invoicingDocument.updateQuoteStatus('approved');
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.approve-quote-modal.success')
      );
    } catch (error) {
      this.invoicingDocument.rollbackAttributes();
      this.handleError(error);
    } finally {
      closeModal();
    }
  });

  @action openCancelQuoteModal() {
    this.segment.track('quote_options_cancel');
    this.modals.open(
      'receivable-invoices/cancel-invoice-modal',
      {
        title: this.intl.t('receivable-invoices.invoice-modal.cancel-quote-modal.title'),
        description: this.intl.t(
          'receivable-invoices.invoice-modal.cancel-quote-modal.description',
          {
            QuoteNumber: this.invoicingDocument.number,
          }
        ),
        cancel: this.intl.t('receivable-invoices.invoice-modal.cancel-quote-modal.return'),
        confirm: this.intl.t('receivable-invoices.invoice-modal.cancel-quote-modal.confirm'),
        confirmTask: this.cancelQuoteTask,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  }

  cancelQuoteTask = dropTask(async closeModal => {
    this.segment.track('quote_canceled_confirmed');
    try {
      await this.invoicingDocument.updateQuoteStatus('canceled');
      this.toastFlashMessages.toastInfo(
        this.intl.t('receivable-invoices.cancel-quote-modal.success', {
          QuoteNumber: this.invoicingDocument.number,
        })
      );
    } catch (error) {
      this.invoicingDocument.rollbackAttributes();
      this.handleError(error);
    } finally {
      closeModal?.();
      this.router.transitionTo('quotes.index');
    }
  });

  // @ts-expect-error
  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
