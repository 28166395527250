/* import __COLOCATED_TEMPLATE__ from './sorting-cell.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { SortIcon } from 'qonto/react/components/paginated-table';

interface ReceivableInvoicesTableHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableCellElement;
}

export default class ReceivableInvoicesTableHeader extends Component<ReceivableInvoicesTableHeaderSignature> {
  sortIcon = SortIcon;

  get sortField() {
    // @ts-expect-error
    let { sortBy } = this.args;
    return sortBy.startsWith('-') ? sortBy.slice(1) : sortBy;
  }

  get isAscending() {
    // @ts-expect-error
    let { sortBy } = this.args;
    return !sortBy.startsWith('-');
  }

  @action
  // @ts-expect-error
  handleSortBy(name) {
    // @ts-expect-error
    let { handleSortBy } = this.args;
    let sortBy = '';

    //Revert the order when we click on the same column
    if (name === this.sortField && this.isAscending) {
      sortBy += '-';
    }

    sortBy += name;

    return handleSortBy?.(sortBy);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Table::Header::SortingCell': typeof ReceivableInvoicesTableHeader;
  }
}
