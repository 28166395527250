/* import __COLOCATED_TEMPLATE__ from './cancel-invoice-form-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CancelInvoiceFormModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const CancelInvoiceFormModal = templateOnlyComponent<CancelInvoiceFormModalSignature>();

export default CancelInvoiceFormModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CancelInvoiceFormModal': typeof CancelInvoiceFormModal;
  }
}
