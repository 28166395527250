export default {
  "container": "cmy",
  "share-panel": "cmU",
  "email-margin": "cmj",
  "message-margin": "cmW",
  "esignature-container": "cmu",
  "esignature-teaser-title": "cpS",
  "esignature-teaser-badge": "cpc",
  "esignature-teaser-body": "cpq",
  "button-container-footer": "cpZ",
  "attachments": "cpR",
  "attachment-file": "cpQ"
};
