/* import __COLOCATED_TEMPLATE__ from './virtual-card.hbs'; */
import Component from '@glimmer/component';

import { VirtualCard } from 'qonto/react/components/request/table/cell/virtual-card';

interface VirtualCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class VirtualCardComponent extends Component<VirtualCardSignature> {
  VirtualCard = VirtualCard;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Table::Cell::VirtualCard': typeof VirtualCardComponent;
  }
}
