/* import __COLOCATED_TEMPLATE__ from './validation.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

interface ReimbursementsRequestsSidebarExpenseReportValidationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReimbursementsRequestsSidebarExpenseReportValidation extends Component<ReimbursementsRequestsSidebarExpenseReportValidationSignature> {
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare modals: Services['modals'];

  close() {
    // @ts-expect-error
    this.args.close?.();
  }

  cancelRequestModalTask = dropTask(async () => {
    this.segment.track('request_cancel_clicked', {
      request_type: 'expense_report',
      origin: 'reimbursements',
      method: 'sidepanel',
    });
    await this.modals.open('popup/destructive', {
      title: this.intl.t('requests.modals.cancel-modal.title'),
      description: this.intl.t('requests.modals.cancel-modal.description'),
      cancel: this.intl.t('btn.close'),
      confirm: this.intl.t('btn.cancel'),
      confirmTask: this.cancelRequestTask,
    });
  });

  cancelRequestTask = dropTask(async close => {
    // @ts-expect-error
    await this.args.request.cancelRequest();
    this.segment.track('reimbursement_request_cancelled');
    this.toastFlashMessages.toastInfo(this.intl.t('requests.cards.toasts.request-cancelled'));
    close();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Requests::Sidebar::ExpenseReport::Validation': typeof ReimbursementsRequestsSidebarExpenseReportValidation;
  }
}
