export default {
  "item": "cmX",
  "details": "cmC",
  "flex": "cmk",
  "header": "cmJ cmk",
  "flex-no-gap": "cms cmk",
  "file-name": "cmF",
  "label": "cmg body-2",
  "self-icon": "cmD",
  "with-separator": "cmV",
  "dimmed": "cmL"
};
