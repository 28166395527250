/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
import Component from '@glimmer/component';

interface MultiTransferDetailStatusSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class MultiTransferDetailStatus extends Component<MultiTransferDetailStatusSignature> {
  get membershipName() {
    // @ts-expect-error
    let { initiator, status, approver } = this.args.request.getProperties(
      'initiator',
      'approver',
      'status'
    );
    let member = status === 'canceled' ? initiator : approver;
    return `${member?.get('firstName')?.substr(0, 1)}. ${member?.get('lastName')}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Header::MultiTransferDetail::Status': typeof MultiTransferDetailStatus;
  }
}
