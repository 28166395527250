/* import __COLOCATED_TEMPLATE__ from './success-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { LottiePlayer } from '@repo/design-system-kit';

interface TransfersSuccessModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class TransfersSuccessModalComponent extends Component<TransfersSuccessModalSignature> {
  lottiePlayer = LottiePlayer;

  @service declare intl: Services['intl'];

  animationReady = false;

  get title() {
    return this.intl.t('requests.transfers.modals.requester.success.title');
  }

  get description() {
    return this.intl.t('requests.transfers.modals.requester.success.subtitle');
  }

  @action
  redirectToDetails() {
    // @ts-expect-error
    this.args.close(true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Requests::SuccessModal': typeof TransfersSuccessModalComponent;
  }
}
