export default {
  "mileage-validation": "coy",
  "actions": "coU",
  "action-btn": "coj",
  "action-btn-right": "coW",
  "request-mileage-validation": "cou",
  "account-selector": "cnS",
  "has-error": "cnc",
  "funds-disclaimer": "cnq",
  "account-options": "cnZ",
  "account-balance": "cnR",
  "warnings": "cnQ"
};
