/* import __COLOCATED_TEMPLATE__ from './past.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface PastSignature {
  Element: HTMLTableRowElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Past = templateOnlyComponent<PastSignature>();

export default Past;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Table::Placeholder::Past': typeof Past;
  }
}
