/* import __COLOCATED_TEMPLATE__ from './placeholder.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface PlaceholderSignature {
  Element: HTMLTableRowElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class Placeholder extends Component<PlaceholderSignature> {
  @service declare subscriptionManager: Services['subscriptionManager'];

  get hasBankAccountFeature() {
    return this.subscriptionManager.hasFeature('bank_account');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Completed::Table::Placeholder': typeof Placeholder;
  }
}
