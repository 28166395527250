export default {
  "row": "cNa body-1",
  "active": "cNH",
  "cell": "cNO",
  "empty": "cNm",
  "ellipsis": "cNp",
  "text-secondary": "cNx",
  "transfer-type": "cNw",
  "transfer-type__icon": "cNN",
  "quick-actions": "cNv",
  "amount": "cNo",
  "cell-approve": "cNn",
  "account-select": "cNi",
  "row-compact": "cNA",
  "cell-amount": "cNY",
  "cell-content-receipt": "cNh body-1",
  "cell-date": "cNf",
  "row-sidebar": "cNK",
  "hidden": "cNG",
  "animated": "cNr",
  "fadein-item": "cNb",
  "animated-cell": "cNy",
  "note": "cNU",
  "checkbox": "cNj"
};
