/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from '@glimmer/component';

import { SidebarHeader } from 'qonto/react/reimbursements/components/bulk/sidebar/header';
import { SidebarSummary } from 'qonto/react/reimbursements/components/bulk/sidebar/summary';
import { formatAmountToNumber } from 'qonto/utils/amount';
interface ReimbursementsBulkSidebarSignature {
  // The arguments accepted by the component
  Args: {
    isLastStep?: boolean;
    isLoading?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReimbursementsBulkSidebarComponent extends Component<ReimbursementsBulkSidebarSignature> {
  sidebarHeader = SidebarHeader;
  summary = SidebarSummary;
  get summaryList() {
    // @ts-expect-error
    let summaryList = this.args.selectedReimbursements.reduce((accumulator, reimbursement) => {
      let initiatorId = reimbursement.get('initiator.membershipId');
      let initiatorName = reimbursement.get('initiator.fullName');
      let initiatorAvatar = reimbursement.get('initiator.avatarThumb');

      if (!accumulator[initiatorId]) {
        accumulator[initiatorId] = {
          name: initiatorName,
          reimbursementsCount: 0,
          reimbursementsAmount: 0,
          avatar: initiatorAvatar,
        };
      }

      accumulator[initiatorId].reimbursementsCount += 1;
      // @ts-expect-error
      accumulator[initiatorId].reimbursementsAmount += formatAmountToNumber({
        amount: reimbursement.amount.value,
      });
      return accumulator;
    }, {});
    return Object.values(summaryList);
  }

  get reimbursementsTotal() {
    // @ts-expect-error
    let totalAmount = this.args.selectedReimbursements.reduce(
      // @ts-expect-error
      (sum, reimbursement) => sum + Number(reimbursement.amount.value),
      0
    );

    return totalAmount;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Bulk::Sidebar': typeof ReimbursementsBulkSidebarComponent;
  }
}
