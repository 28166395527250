/* import __COLOCATED_TEMPLATE__ from './delivery-address-selector.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface CustomerFormDeliveryAddressSelectorSignature {
  // The arguments accepted by the component
  Args: {
    isEdit?: boolean;
    isLocalValidation?: boolean;
    savedAddressName?: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class CustomerFormDeliveryAddressSelector extends Component<CustomerFormDeliveryAddressSelectorSignature> {
  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  @tracked checked = this.hasDeliveryAddress;

  get isActive() {
    return this.checked || this.hasDeliveryAddress;
  }

  get hasDeliveryAddress() {
    // @ts-expect-error
    let { deliveryAddress } = this.args.customer;
    return Boolean(deliveryAddress);
  }

  @action
  toggleDeliveryAddress() {
    // @ts-expect-error
    let { customer } = this.args;
    this.checked = !this.checked;

    if (this.args.isEdit) {
      this.segment.track('invoice-creation_client-edition_delivery-address', {
        status: this.checked ? 'enabled' : 'disabled',
      });
    } else {
      this.segment.track('invoice-creation_client-creation_delivery-address', {
        status: this.checked ? 'enabled' : 'disabled',
      });
    }

    if (!this.checked) {
      customer.setProperties({ deliveryAddress: null });
    } else {
      customer.setProperties({
        deliveryAddress: {
          addressName: customer.deliveryAddress?.addressName || this.args.savedAddressName,
          countryCode: customer.billingAddress.countryCode,
        },
      });
    }

    // @ts-expect-error
    this.args.onToggle?.();
  }

  // @ts-expect-error
  @action onUpdateDeliveryAddressCountryCode(countryCode) {
    // @ts-expect-error
    let { customer } = this.args;
    customer.set('deliveryAddress', {
      ...customer.deliveryAddress,
      countryCode,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CustomerForm::DeliveryAddressSelector': typeof CustomerFormDeliveryAddressSelector;
  }
}
