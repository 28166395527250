/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { debounce, next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dateToken } from '@qonto/ui-kit/utils/date-token';
// @ts-expect-error
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask, task, timeout } from 'ember-concurrency';
import fuzzysort from 'fuzzysort';
import { TrackedArray } from 'tracked-built-ins';

import {
  initializeQuickActions,
  modifierKey,
  QONTO_PILOT_DELAYS,
  // @ts-expect-error
} from 'qonto/constants/qonto-pilot';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { DEFAULT_SEARCH_INCLUDES } from 'qonto/constants/transactions';
import { DISCLAIMER_TYPES, TRANSFER_FLOW_ORIGIN } from 'qonto/constants/transfers';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import { isMac } from 'qonto/utils/is-mac';
// @ts-expect-error
import parseConfirmResponse from 'qonto/utils/parse-confirm-response';
const QPILOT_CREATE_TRANSFER_STEPS = {
  QUICK_ACTIONS: 'quick_actions', // the first step
  SUPPLIER_INVOICES: 'supplier_invoices',
  PROMPT: 'prompt', // the step where we ask the user to insert the prompt
  PROMPT_EVALUATION: 'prompt_evaluation', // when the user submit the prompt and the LLM tries to parse it
  TRANSFER_DETAILS: 'transfer_details', // when a transfer model is created and is ready for the user review and submission
  TRANSFER_CREATION: 'transfer_creation', // when the "confirm" of the transfer is in progress
  TRANSFER_CREATED: 'transfer_created', // when the "confirm" of the transfer is completed and the transfer is created
};

const ERROR_MESSAGES = {
  [DISCLAIMER_TYPES.BILLER_INSUFFICIENT_FUNDS]: 'transfers.warnings.insufficient_funds',
};

interface QontoPilotSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class QontoPilotComponent extends Component<QontoPilotSignature> {
  lottiePlayer = LottiePlayer;

  @service declare qontoPilotService: Services['qontoPilotService'];
  @service declare router: Services['router'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare beneficiariesManager: Services['beneficiariesManager'];
  @service declare userManager: Services['userManager'];
  @service declare intl: Services['intl'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare supplierInvoicesUploadManager: Services['supplierInvoicesUploadManager'];

  @tracked userInput = '';
  // @ts-expect-error
  @tracked transfer;
  // @ts-expect-error
  @tracked errorMessage;

  // The focus is -1 when the result entries don't have focus.
  // Otherwise, it is an index among the filteredQueryGroups.
  @tracked focusedGroupIndex = -1;
  @tracked focusedActionIndex = 0;

  selectedItems = [];

  steps = QPILOT_CREATE_TRANSFER_STEPS;
  // @ts-expect-error
  @tracked currentStep = this.args.data.action;

  mentionTrigger = '@';

  defaultQuickActions = initializeQuickActions(getOwner(this));

  modifierKey = modifierKey(isMac());

  get feedbackUrl() {
    let { currentUser } = this.userManager;

    return this.intl.t('qonto-pilot.modal.feedback', {
      // @ts-expect-error
      userid: currentUser?.id,
      // @ts-expect-error
      email: currentUser?.email,
    });
  }

  get dropZoneLabel() {
    let { organization } = this.organizationManager;
    let shouldUseXMLText = organization?.legalCountry === 'DE';

    return shouldUseXMLText
      ? this.intl.t('labels.upload-message-with-xml', {
          maxSize: formatFileSize(this.intl, this.maxFileSize),
        })
      : this.intl.t('labels.upload-message', {
          maxSize: formatFileSize(this.intl, this.maxFileSize),
        });
  }

  @action
  // @ts-expect-error
  handleActionClick(quickAction) {
    this.segment.track('qontopilot_quick-action_selected', {
      quickAction: quickAction.key,
    });
    this.qontoPilotService.addRecentQuickAction(quickAction.key);
    if (quickAction.key === 'task-sepa') {
      this.handleInstantSepaTransferActionClick();
      return;
    }
    if (quickAction.key === 'task-receipt') {
      this.handleReceiptActionClick();
      return;
    }
    if (quickAction.isEnabled && typeof quickAction.action === 'function') {
      this.close();
      quickAction.action();
      // @ts-expect-error
      document.querySelector('#mentionable-input')?.focus();
    }
  }

  @action
  handleInstantSepaTransferActionClick() {
    next(this, () => {
      this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
    });
  }

  @action
  handleReceiptActionClick() {
    next(this, () => {
      this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.SUPPLIER_INVOICES;
    });
  }

  @action
  close({ cleanCache = true } = {}) {
    if (this.submitPromptTask.isRunning) {
      return;
    }
    this.removeConfirmEvent();
    if (cleanCache) {
      this.store.unloadRecord(this.transfer);
    }
    // @ts-expect-error
    this.supplierInvoicesUploadManager.resetState();
    // @ts-expect-error
    this.args.close();
  }

  @action
  closeModalAndNavigateToTransfer() {
    this.router.transitionTo('transfers.past', {
      queryParams: { highlight: this.transfer.id },
    });
    this.close();
  }

  @action
  handleEdit() {
    this.segment.track('qontopilot_transfer_edit-details');
    this.flowLinkManager.transitionTo({
      name: 'sepa-transfer',
      stepId: 'details',
      queryParams: {
        origin: TRANSFER_FLOW_ORIGIN.QONTO_PILOT,
      },
    });
    this.close({ cleanCache: false });
  }

  constructor(owner: unknown, args: QontoPilotSignature['Args']) {
    super(owner, args);
    this.loadBeneficiariesTask.perform().catch(ignoreCancelation);
    // @ts-expect-error
    this.fetchTransactionsTask.perform().catch(ignoreCancelation);
    // @ts-expect-error
    this.supplierInvoicesUploadManager.resetState();
    // @ts-expect-error
    this.supplierInvoicesUploadManager.registerCallback({
      onUploadFinished: this.onUploadFinish.bind(this),
    });
  }

  async onUploadFinish() {
    // @ts-expect-error
    for (let file of this.supplierInvoicesUploadManager.files) {
      file.startProcessing();
    }

    // @ts-expect-error
    let attachmentIds = this.supplierInvoicesUploadManager.files.map(file => file?.attachment?.id);
    if (attachmentIds.includes(undefined)) return;

    let filterGroup = {
      conditional: 'and',
      expressions: [
        {
          property: 'attachment_ids',
          values: attachmentIds,
          operator: 'in',
        },
      ],
    };

    // TODO:: to be replaced by websocket event
    await timeout(QONTO_PILOT_DELAYS.supplierInvoices);

    let transactions = await this.fetchTransactionsTask.perform(filterGroup);

    // @ts-expect-error
    for (let file of this.supplierInvoicesUploadManager.files) {
      // @ts-expect-error
      let matchedTransaction = transactions.find(transaction =>
        transaction.attachmentIds.includes(file.attachment.id)
      );

      if (matchedTransaction) {
        let message = this.intl.t('qonto-pilot.modal.upload-receipt.match', {
          description: matchedTransaction.description,
          date: matchedTransaction.emittedAt,
          amount: `${matchedTransaction.amount} ${matchedTransaction.amountCurrency}`,
        });
        file.finishProcessing(message);
      } else {
        file.finishProcessing(this.intl.t('qonto-pilot.modal.upload-receipt.nomatch'));
      }
    }
  }

  fetchTransactionsTask = task(this, { restartable: true }, async filterGroup => {
    await timeout(DEBOUNCE_MS);

    // @ts-expect-error
    let { transactions } = await this.store.adapterFor('transaction').search({
      includes: DEFAULT_SEARCH_INCLUDES,
      filter_group: filterGroup,
      sort: { property: 'emitted_at', direction: 'desc' },
      pagination: { page: 1, per_page: 50 },
      search: '',
      organization_id: this.organizationManager.organization.id,
    });

    return transactions;
  });

  get beneficiaries() {
    return this.store.peekAll('beneficiary');
  }

  loadBeneficiariesTask = task(async () => {
    try {
      await this._loadBeneficiaries();
    } catch (error) {
      this.sentry.captureException(
        new Error(`Beneficiary load triggered an error`, { cause: error })
      );
    }
  });

  _loadBeneficiaries() {
    let organizationId = this.organizationManager.organization.id;
    // @ts-expect-error
    return this.beneficiariesManager.loadSepaBeneficiaries(organizationId);
  }

  @action
  // @ts-expect-error
  handleArrowsNavigation(event) {
    event.preventDefault();
    let delta = event.key === 'ArrowDown' ? 1 : -1;

    let groups = Object.values(this.filteredQueryGroups);
    let focusedGroup = groups[this.focusedGroupIndex];
    this.focusedActionIndex += delta;

    // If we are going past the bounds of a group, we switch to the next group.
    // We can also switch back to the query input
    // if we are at the start of the first group.

    let focusPastEndOfGroup =
      // @ts-expect-error
      focusedGroup && this.focusedActionIndex > focusedGroup.actions.length - 1;
    let focusOnLastGroup = this.focusedGroupIndex === groups.length - 1;
    let switchToGroupBelow = focusPastEndOfGroup && !focusOnLastGroup;
    let switchFromInputToEntries = this.focusedGroupIndex < 0 && delta > 0;
    if (switchToGroupBelow || switchFromInputToEntries) {
      this.focusedGroupIndex += 1;
      focusedGroup = groups[this.focusedGroupIndex];
      this.focusedActionIndex = 0;
    }

    let focusPastStartOfGroup = this.focusedActionIndex < 0;
    let switchToGroupAbove = focusPastStartOfGroup && this.focusedGroupIndex >= 0;
    if (switchToGroupAbove) {
      this.focusedGroupIndex -= 1;
      focusedGroup = groups[this.focusedGroupIndex];
      if (focusedGroup) {
        // @ts-expect-error
        this.focusedActionIndex = focusedGroup.actions.length - 1;
      }
    }

    // Ensure the focused action index is within bounds.

    if (this.focusedActionIndex < 0) {
      this.focusedActionIndex = 0;
    }
    // @ts-expect-error
    if (focusedGroup && this.focusedActionIndex > focusedGroup.actions.length - 1) {
      // @ts-expect-error
      this.focusedActionIndex = focusedGroup.actions.length - 1;
    }

    if (this.focusedGroupIndex < 0) {
      document.getElementById('qonto-pilot-quick-action-search')?.focus();
    }
  }

  // @ts-expect-error
  trackQuery(query) {
    this.segment.track('qontopilot_search-query', {
      query,
    });
  }

  @action
  // @ts-expect-error
  onTransactionKeyDown(transactionId, event) {
    if (event.key === 'Enter') {
      this.segment.track('qontopilot_transaction-open', { trigger: 'keyboard' });
      return this.onSelectItem(transactionId);
    }
    this.onKeyDown(event);
  }

  @action
  // @ts-expect-error
  onKeyDown(event) {
    this.errorMessage = null;

    if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
      this.handleArrowsNavigation(event);
    }
  }

  @action
  // @ts-expect-error
  onKeyUp(event) {
    let query = event.target.value;
    debounce(this, this.trackQuery, query, QONTO_PILOT_DELAYS.searchInputDebounce);
  }

  @action
  // @ts-expect-error
  onInputChange(event) {
    this.userInput = event.target.value;
    // Stop highlighting an entry, so the user can continue typing.
    this.focusedGroupIndex = -1;
    this.focusedActionIndex = 0;
  }

  @action
  // @ts-expect-error
  onSubmit(value) {
    this.submitPromptTask.perform(value).catch(ignoreCancelation);
  }

  submitPromptTask = dropTask(async value => {
    try {
      this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT_EVALUATION;
      let result = await this.qontoPilotService.processUserInput(value);
      if (typeof result === 'string' || result === null) {
        this.errorMessage = result || this.intl.t('qonto-pilot.modal.error.wrong-prompt');
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
      } else {
        this.segment.track('qontopilot_transfer-request_made', {
          amount: result.amount,
          beneficiary: result.beneficiary.get('name'),
        });
        this.errorMessage = null;
        this.transfer = result;
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
        // @ts-expect-error
        document.activeElement.blur();
        this.setConfirmEvent();
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  confirmTransferTask = dropTask(async () => {
    // @ts-expect-error
    await this.sensitiveActions.runTask.perform(this.confirmAndSaveTransfer, this.transfer);
  });

  confirmAndSaveTransfer = dropTask(async transfer => {
    this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATION;
    try {
      let confirmationResult = await transfer.confirm();
      if (confirmationResult.errors.length === 0) {
        this.transfer = await transfer.save();
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED;
        this.segment.track('qontopilot_transfer_confirmed', {
          amount: transfer.amount,
          beneficiary: transfer.beneficiary.get('name'),
        });
      } else {
        let confirmResponseErrors = parseConfirmResponse(confirmationResult, this.intl);
        this.#displayConfirmErrors(confirmResponseErrors.confirmErrors);
      }
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      } else {
        this.currentStep = QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
      }
    } finally {
      transfer.removeIdempotencyHeader();
    }
  });

  @action
  // @ts-expect-error
  confirmEvent(event) {
    // @ts-expect-error
    let tagName = document.activeElement.tagName.toLowerCase();
    // @ts-expect-error
    let isEditable = document.activeElement.isContentEditable;

    if (event.key === 'Enter' && tagName !== 'input' && tagName !== 'textarea' && !isEditable) {
      if (this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED) {
        this.closeModalAndNavigateToTransfer();
        return;
      }
      this.confirmTransferTask.perform().catch(ignoreCancelation);
    }
  }

  setConfirmEvent() {
    this.removeConfirmEvent();
    document.addEventListener('keydown', this.confirmEvent);
  }

  removeConfirmEvent() {
    document.removeEventListener('keydown', this.confirmEvent);
  }

  // @ts-expect-error
  #displayConfirmErrors(confirmErrors) {
    // @ts-expect-error
    let errorsToDisplay = confirmErrors.map(errorCode => ERROR_MESSAGES[errorCode]);

    // @ts-expect-error
    errorsToDisplay.forEach(errorMessage => {
      this.toastFlashMessages.toastError(this.intl.t(errorMessage));
    });

    this.close({ cleanCache: true });
  }

  get displayPromptLoading() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT_EVALUATION;
  }

  get displayTransferDetails() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
  }

  get displayButtons() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_DETAILS;
  }

  get displayMFA() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATION;
  }

  get displaySuccessScreen() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.TRANSFER_CREATED;
  }

  get displaySelectKeyControl() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.QUICK_ACTIONS;
  }

  get displayQuickActions() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.QUICK_ACTIONS;
  }

  get displayPromptInput() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT && !this.displaySuccessScreen;
  }

  get displayBeneficiary() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.PROMPT;
  }

  get displayEnter() {
    return this.currentStep !== QPILOT_CREATE_TRANSFER_STEPS.SUPPLIER_INVOICES;
  }

  get displaySupplierInvoicesDropzone() {
    return this.currentStep === QPILOT_CREATE_TRANSFER_STEPS.SUPPLIER_INVOICES;
  }

  @action
  // @ts-expect-error
  handleSupplierInvoiceUploaded(file) {
    // @ts-expect-error
    this.supplierInvoicesUploadManager.queue.add(file);
    this.supplierInvoicesErrors = new TrackedArray();
  }

  // @ts-expect-error
  @action onPreviewFile(file) {
    this.close();
    this.router.transitionTo('supplier-invoices.show', file.invoiceId);
  }

  // Group for account transactions, which conforms to the same data type
  // as the quick actions groups that you can find in
  // app/constants/qonto-pilot.js.
  get defaultTransactionGroup() {
    let transactions = this.fetchTransactionsTask.lastSuccessful?.value || [];
    return {
      name: 'transactions',
      // @ts-expect-error
      actions: transactions.map(transaction => ({
        key: `transaction-${transaction.id}`,
        isEnabled: true,
        isBeta: false,
        copy: null,
        // We separate entries with the ASCII record separator character.
        searchText: [
          transaction.note,
          transaction.status,
          transaction.description,
          transaction.signedAmount,
          transaction.activityTag,
          transaction.counterpartyName,
          // @ts-expect-error
          dateToken({ date: transaction.emittedAt, token: 'date-year-l' }),
        ].join('\u001E'),
        hotkey: null,
        illustration: null,
        action: () => {
          this.onSelectItem(transaction.id);
        },
        isFocused: false,
        storeObject: transaction,
      })),
    };
  }

  get defaultSearchAllTransactionsGroup() {
    return {
      name: 'search-all-transactions',
      hideHeader: true,
      actions: [
        {
          key: 'search-all-transactions',
          isEnabled: true,
          isBeta: false,
          copy: this.intl.t('qonto-pilot.modal.quick-action.search-transactions', {
            search: this.userInput,
          }),
          // Always show this group when the user queries something.
          searchText: this.userInput,
          hotkey: null,
          illustration: null,
          action: () => {
            // @ts-expect-error
            this.onSearchAllTransactions();
          },
          isFocused: false,
          storeObject: null,
        },
      ],
    };
  }

  // Groups that appear in the UI when there is no query entered.
  get defaultQueryGroups() {
    let groups = {
      ...this.defaultQuickActions,
      [this.intl.t('qonto-pilot.modal.quick-action.group.transactions')]:
        this.defaultTransactionGroup,
    };
    if (this.userInput !== '') {
      groups['search-all-transactions'] = this.defaultSearchAllTransactionsGroup;
    }
    return groups;
  }

  // Groups, exactly as they appear in the UI.
  get filteredQueryGroups() {
    let query = this.userInput;
    return Object.fromEntries(
      Object.entries(this.defaultQueryGroups)
        .map(([groupKey, group]) => {
          let filteredActions;
          if (query.length === 0) {
            // @ts-expect-error
            filteredActions = group.actions;
          } else {
            filteredActions = fuzzysort
              // @ts-expect-error
              .go(query, group.actions, { key: 'searchText' })
              .map(result => result.obj);
          }
          let filteredGroup = {
            // @ts-expect-error
            name: group.name,
            // @ts-expect-error
            hideHeader: group.hideHeader,
            actions: filteredActions,
          };
          return [groupKey, filteredGroup];
        })
        // @ts-expect-error
        .filter(entry => entry[1].actions.length > 0)
        .map(([groupKey, group], groupIdx) => {
          // We create fresh objects to avoid bugs from
          // references in multiple groups pointing to the same object.
          // @ts-expect-error
          group.actions = group.actions.map((action, actionIdx) => ({
            ...action,
            isFocused: groupIdx === this.focusedGroupIndex && actionIdx === this.focusedActionIndex,
          }));
          return [groupKey, group];
        })
    );
  }

  // @ts-expect-error
  @action onSelectItem(transactionId) {
    this.close();
    return this.router.replaceWith('transactions.index', {
      queryParams: {
        highlight: transactionId,
      },
    });
  }

  @action
  handleNoResultsClick() {
    this.close();
    return this.router.replaceWith('transactions.index', {
      queryParams: {
        query: this.userInput,
      },
    });
  }

  supplierInvoicesErrors = new TrackedArray();
  supplierInvoices = new TrackedArray();
  maxFileSize = 30 * 1e6;
  uploadOptions = { callEndpoint: false };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'QontoPilot::Modal': typeof QontoPilotComponent;
  }
}
