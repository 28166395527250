/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS, type StatusType } from 'qonto/constants/requests';

interface ReimbursementsRequestsSidebarExpenseReportHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class ReimbursementsRequestsSidebarExpenseReportHeaderComponent extends Component<ReimbursementsRequestsSidebarExpenseReportHeaderSignature> {
  @service declare intl: Services['intl'];

  get lastActionDate() {
    // @ts-expect-error
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get titleStatus() {
    // @ts-expect-error
    let { approver, initiator, status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    if (pastStatuses.includes(status)) {
      let user = approver || initiator;
      let { firstName, lastName } = user.getProperties('firstName', 'lastName');
      let fullName = `${firstName.charAt(0)}. ${lastName}`;

      let statuses = {
        [STATUS.DECLINED]: this.intl.t('requests.sidebar.header.status.declined', {
          approver_name: fullName,
        }),
        [STATUS.APPROVED]: this.intl.t('requests.sidebar.header.status.reimbursed', {
          approver_name: fullName,
        }),
        [STATUS.CANCELED]: this.intl.t('requests.transfers.final_status.canceled', {
          name: fullName,
        }),
      };

      return statuses[status as Exclude<StatusType, 'pending'>];
    }

    return this.intl.t('requests.sidebar.header.status.pending');
  }

  get subtitle() {
    // @ts-expect-error
    let { status } = this.args.request;
    let formattedDate = this.intl.formatDate(this.lastActionDate, { format: 'short' });

    if (status === 'pending') {
      return this.intl.t('checks.sidebar.requested_at') + ' ' + formattedDate;
    } else if (['declined', 'approved'].includes(status)) {
      return this.intl.t('requests.sidebar.header.updated-date', { updated_at: formattedDate });
    }

    return formattedDate;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Requests::Sidebar::ExpenseReport::Header': typeof ReimbursementsRequestsSidebarExpenseReportHeaderComponent;
  }
}
