/* import __COLOCATED_TEMPLATE__ from './remunerated-accounts.hbs'; */
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { sort } from 'macro-decorators';

// @ts-expect-error
const sortByDateAscending = (a, b) => new Date(a.createdAt) - new Date(b.createdAt);

interface RemuneratedAccountsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RemuneratedAccountsComponent extends Component<RemuneratedAccountsSignature> {
  @service declare organizationManager: Services['organizationManager'];

  @sort('organizationManager.organization.nonClosedRemuneratedAccounts', sortByDateAscending)
  // @ts-expect-error
  sortedNonClosedAccounts;

  constructor(owner: unknown, args: RemuneratedAccountsSignature['Args']) {
    super(owner, args);

    // @ts-expect-error
    if (this.args.maybeScrollToLast) {
      next(() => {
        // @ts-expect-error
        this.args.maybeScrollToLast(document.getElementById('remunerated-list'));
      });
    }
  }

  get matchingRemuneratedAccountInfos() {
    if (this.sortedNonClosedAccounts.length === 0) {
      return [];
    }

    // @ts-expect-error
    return this.sortedNonClosedAccounts.map(remuneratedBankAccount => {
      // @ts-expect-error
      let remuneratedAccountDetails = this.args.remuneratedAccountsDetails.find(
        // @ts-expect-error
        ({ accountId }) => accountId === remuneratedBankAccount.id
      );

      return {
        ...remuneratedAccountDetails?.getProperties(
          'currentMaturity',
          'maxMaturity',
          'startDate',
          'endDate',
          'isTaxApplicable',
          'interestRates',
          'maxMaturityEarnings'
        ),
        ...remuneratedBankAccount?.getProperties(
          'id',
          'slug',
          'status',
          'accountType',
          'name',
          'authorizedBalance',
          'balanceCurrency',
          'main',
          'isRemunerated'
        ),
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    RemuneratedAccounts: typeof RemuneratedAccountsComponent;
  }
}
