export default {
  "row": "cGq",
  "active": "cGZ",
  "dot": "cGR",
  "red": "cGQ",
  "green": "cGe",
  "yellow": "cGB",
  "status": "cGE",
  "align-right": "cGd",
  "animated": "cGI",
  "fadein-item": "cGt",
  "cell": "cGM body-2",
  "cell-content": "cGP",
  "amount": "cGl body-1",
  "subtitle": "cGX caption",
  "no-padding": "cGC"
};
