/* import __COLOCATED_TEMPLATE__ from './member.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MemberSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Member = templateOnlyComponent<MemberSignature>();

export default Member;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Pending::Table::Cell::Member': typeof Member;
  }
}
