export default {
  "iban": "crd",
  "checkbox": "crI",
  "toggle-content": "crt",
  "beneficiary-name": "crM",
  "category": "crP",
  "beneficiary-email": "crl",
  "beneficiary-form": "crX",
  "beneficiary-email-container": "crC"
};
