/* import __COLOCATED_TEMPLATE__ from './finalize-error-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface FinalizeErrorModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const FinalizeErrorModal = templateOnlyComponent<FinalizeErrorModalSignature>();

export default FinalizeErrorModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::FinalizeErrorModal': typeof FinalizeErrorModal;
  }
}
