export default {
  "container": "czm",
  "depositAmount": "czp",
  "flexRow": "czx",
  "depositInput": "czw",
  "dropdown": "czN",
  "itemRow": "czv",
  "line": "czo",
  "estimates-container": "czn",
  "estimates": "czi",
  "dt": "czA",
  "dd": "czY"
};
