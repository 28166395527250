export default {
  "selector": "czK",
  "pension-selector-content": "czG",
  "withholding-tax-selector-content": "czr",
  "withholding-tax-selector-content-row": "czb",
  "power-select-field": "czy",
  "rate-field": "czU",
  "stamp-revenue-field": "czj",
  "dropdown-option": "czW",
  "dropdown-content": "czu"
};
