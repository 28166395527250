export default {
  "mileage-validation": "cNI",
  "actions": "cNt",
  "action-btn": "cNM",
  "action-btn-success": "cNP",
  "action-btn-right": "cNl",
  "request-mileage-validation": "cNX",
  "account-selector": "cNC",
  "has-error": "cNk",
  "funds-disclaimer": "cNJ",
  "account-options": "cNs",
  "account-balance": "cNF",
  "warnings": "cNg"
};
