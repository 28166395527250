/* import __COLOCATED_TEMPLATE__ from './vat.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { AmountField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

// @ts-expect-error
import vatAmount from 'qonto/utils/vat-amount';

interface RequestSidebarExpenseReportDetailsVatSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestSidebarExpenseReportDetailsVatComponent extends Component<RequestSidebarExpenseReportDetailsVatSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  amountField = AmountField;

  get selectedVat() {
    return {
      // @ts-expect-error
      clearable: Boolean(this.args.request.vatRate),
      // @ts-expect-error
      value: this.args.request.vatRate,
    };
  }

  // @ts-expect-error
  @reads('organizationManager.organization.vatRatesCategories') vatRatesCategories;

  get optionsVatRates() {
    // @ts-expect-error
    return this.filteredVatRatesCategories.map(vatRate => {
      let label = '';
      if (vatRate > 0) {
        label = `${this.intl.formatNumber(vatRate)}%`;
      } else if (vatRate === null) {
        label = this.intl.t('transactions.sidebar.vat.accounting.none');
      } else {
        label =
          vatRate === 0
            ? this.intl.t('transactions.sidebar.vat.accounting.no-rate')
            : this.intl.t('transactions.sidebar.vat.accounting.other');
      }
      return {
        // @ts-expect-error
        clearable: this.args.hideCustomInputs ? false : vatRate !== 0,
        label,
        value: vatRate,
      };
    });
  }

  get filteredVatRatesCategories() {
    // @ts-expect-error
    if (this.args.hideCustomInputs) {
      return [
        null,
        // @ts-expect-error
        ...this.vatRatesCategories.filter(vatRate => {
          return vatRate !== -1;
        }),
      ];
    }

    return this.vatRatesCategories;
  }

  @action
  // @ts-expect-error
  handleChangeVat({ value }) {
    // @ts-expect-error
    let { amount } = this.args.request;
    let newVatAmount = vatAmount(amount.value, value);

    // @ts-expect-error
    this.args.onSelect?.(this.args.request, {
      vatRate: value,
      vatAmount: newVatAmount ? { value: newVatAmount, currency: 'EUR' } : newVatAmount,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::ExpenseReport::Details::Vat': typeof RequestSidebarExpenseReportDetailsVatComponent;
  }
}
