/* import __COLOCATED_TEMPLATE__ from './confirm-send-quote-modal.hbs'; */
// import templateOnlyComponent from '@ember/component/template-only';
import { action } from '@ember/object';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { E_SIGNATURE_CONFIRM_POPUP_STORAGE_KEY } from 'qonto/react/receivable-invoices/constants';

interface ConfirmSendQuoteModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ConfirmSendQuoteModalComponent extends Component<ConfirmSendQuoteModalSignature> {
  checkbox = Checkbox;

  @action toggleDontShowModal(value: boolean) {
    safeLocalStorage.setItem(E_SIGNATURE_CONFIRM_POPUP_STORAGE_KEY, value.toString());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::ConfirmSendQuoteModal': typeof ConfirmSendQuoteModalComponent;
  }
}
