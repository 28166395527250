/* import __COLOCATED_TEMPLATE__ from './title.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface TitleSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Title = templateOnlyComponent<TitleSignature>();

export default Title;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::MultiTransfer::Table::Title': typeof Title;
  }
}
