export default {
  "request-expense-report-validation": "chQ",
  "account-selector": "che",
  "has-error": "chB",
  "actions": "chE",
  "action-btn": "chd",
  "action-btn-success": "chI",
  "warnings": "cht",
  "top-border": "chM",
  "account-selector-subtitle": "chP",
  "action-btn-right": "chl",
  "funds-disclaimer": "chX",
  "account-options": "chC",
  "account-balance": "chk"
};
