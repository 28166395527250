/* import __COLOCATED_TEMPLATE__ from './attachment-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import window from 'ember-window-mock';

interface RequestSidebarExpenseReportAttachmentModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestSidebarExpenseReportAttachmentModal extends Component<RequestSidebarExpenseReportAttachmentModalSignature> {
  @service declare abilities: Services['abilities'];

  get request() {
    // @ts-expect-error
    return this.args.data.model;
  }

  get hasSidebar() {
    // @ts-expect-error
    return this.args.data.hasSidebar;
  }

  get selectedAttachment() {
    return this.request.attachments.content?.[0] || {};
  }

  get selectedAttachmentName() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileName : file?.fileName;
  }

  get selectedAttachmentType() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileContentType : file?.fileContentType;
  }

  get selectedAttachmentUrl() {
    let { file, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment?.fileUrl : file?.fileUrl;
  }

  get selectedAttachmentDownloadUrl() {
    let { downloadUrl, probativeAttachment, isProbated } = this.selectedAttachment;
    return isProbated ? probativeAttachment.downloadUrl : downloadUrl;
  }

  @action
  handleDownload() {
    window.open(this.selectedAttachmentDownloadUrl);
  }

  @action
  closeModal() {
    // @ts-expect-error
    this.args.close();
    // @ts-expect-error
    this.args.data.close?.();
  }

  get shouldDisplayApprovalWorkflowState() {
    let { approvalWorkflowState, canceled } = this.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');
    return (
      this.abilities.can('see state approval-workflow') &&
      approvalWorkflowState &&
      steps &&
      conditions &&
      !canceled
    );
  }

  get approvalWorkflowState() {
    let { approvalWorkflowState } = this.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return {
      id: approvalWorkflowState.id,
      conditions,
      steps,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::ExpenseReport::AttachmentModal': typeof RequestSidebarExpenseReportAttachmentModal;
  }
}
