/* import __COLOCATED_TEMPLATE__ from './beneficiary-item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';

interface RequestTransferBeneficiarySidebarBeneficiaryItemSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestTransferBeneficiarySidebarBeneficiaryItemComponent extends Component<RequestTransferBeneficiarySidebarBeneficiaryItemSignature> {
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare segment: Services['segment'];
  @service declare sensitiveActions: Services['sensitiveActions'];

  @action
  openTrustBeneficiary() {
    // @ts-expect-error
    let { beneficiary } = this.args;

    this.segment.track(
      beneficiary.trusted ? 'beneficiary_untrust_clicked' : 'beneficiary_trust_clicked'
    );

    this.modals.open('beneficiary-trust-popup', {
      title: beneficiary.trusted
        ? this.intl.t('transfers.beneficiaries.trust-popup.untrust.title')
        : this.intl.t('transfers.beneficiaries.trust-popup.trust.title'),
      beneficiary,
      confirmTask: this.trustTask,
    });
  }

  markAsTrustedTask = dropTask(async beneficiary => {
    try {
      await beneficiary.markAsTrusted();
      let message = this.intl.t('transfers.beneficiaries.trust-popup.trust.success');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      // @ts-expect-error
      if (error?.errors[0].code === 'declined') {
        this.toastFlashMessages.toastError(
          this.intl.t('transfers.beneficiaries.trust-popup.trust.declined-error')
        );
      }

      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  trustTask = dropTask(async close => {
    // @ts-expect-error
    let { beneficiary } = this.args;

    try {
      if (beneficiary.trusted) {
        await beneficiary.markAsUntrusted();
        this.toastFlashMessages.toastInfo(
          this.intl.t('transfers.beneficiaries.trust-popup.untrust.success')
        );
      } else {
        this.segment.track('beneficiary_trust_modal_confirm');
        // @ts-expect-error
        await this.sensitiveActions.runTask.perform(this.markAsTrustedTask, beneficiary);
      }
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    } finally {
      close();
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::BeneficiarySidebar::BeneficiaryItem': typeof RequestTransferBeneficiarySidebarBeneficiaryItemComponent;
  }
}
