/* import __COLOCATED_TEMPLATE__ from './confirm-creation.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { LOCAL_STORAGE_WARNING_DISMISSED_KEY } from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface ReceivableInvoicesConfirmCreationModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesConfirmCreationModalComponent extends Component<ReceivableInvoicesConfirmCreationModalSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  triggerConfirmationTask = dropTask(async () => {
    // @ts-expect-error
    if (this.args.event) {
      // @ts-expect-error
      this.segment.track(this.args.event);
    }
    await this.modals.open(
      'receivable-invoices/confirm-creation-modal',
      {
        title: this.intl.t('receivable-invoices.issue-modal.title'),
        description: this.invoiceCreationDescription,
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('receivable-invoices.issue-modal.cta-issue'),
        confirmTask: this.confirmTask,
        shouldDisplayCheckbox: this.shouldDisplayCheckbox,
        shouldDisplayFrEinvoicing: this.shouldDisplayFrEinvoicing,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  confirmTask = dropTask(async closeModal => {
    // @ts-expect-error
    await this.args.primaryTask.perform(closeModal);
    if (this.shouldDisplayCheckbox) {
      safeLocalStorage.setItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY, 'true');
    }
  });

  get invoiceCreationDescription() {
    let shouldDisplayItalyCopy = this.organizationManager.organization.legalCountry === 'IT';

    // @ts-expect-error
    if (this.args.invoice?.status === 'draft') {
      if (this.shouldDisplayFrEinvoicing) {
        return this.intl.t('receivable-invoices.issue-modal.einvocing-subtitle');
      } else {
        return shouldDisplayItalyCopy
          ? this.intl.t('receivable-invoices.issue-modal.edit-draft.italy.subtitle')
          : this.intl.t('receivable-invoices.issue-modal.edit-draft.subtitle');
      }
    }

    // FR e-invoicing condition
    if (this.shouldDisplayFrEinvoicing) {
      return this.intl.t('receivable-invoices.issue-modal.einvocing-subtitle');
    }

    return shouldDisplayItalyCopy
      ? this.intl.t('receivable-invoices.issue-modal.italy.subtitle')
      : this.intl.t('receivable-invoices.issue-modal.subtitle');
  }

  get shouldDisplayCheckbox() {
    return (
      // @ts-expect-error
      this.args.settings?.numberingMode === 'manual' &&
      safeLocalStorage.getItem(LOCAL_STORAGE_WARNING_DISMISSED_KEY) !== 'true'
    );
  }

  get shouldDisplayFrEinvoicing() {
    // @ts-expect-error
    return this.args.invoice?.isEinvoice;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Providers::ConfirmCreation': typeof ReceivableInvoicesConfirmCreationModalComponent;
  }
}
