export default {
  "attachment-viewer": "cYd",
  "hide-sidebar": "cYI",
  "sidebar": "cYt",
  "header": "cYM",
  "header-top": "cYP",
  "header-icon": "cYl",
  "header-amount": "cYX",
  "infobox": "cYC",
  "vat": "cYk",
  "validation": "cYJ"
};
