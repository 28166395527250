export default {
  "numbering-container": "caS",
  "flex-row": "cac",
  "automatic-number-label": "caq",
  "icon-tooltip": "caZ",
  "fix-width": "caR",
  "dates-container": "caQ",
  "with-performance-date": "cae",
  "date-picker": "caB",
  "label": "caE",
  "header-text-field-container": "cad",
  "mt-16": "caI",
  "header-text-field": "cat",
  "header-text-field-close-button": "caM",
  "deposit-toggle-container": "caP"
};
