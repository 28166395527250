/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
/* eslint-disable require-await */
import { later } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask } from 'ember-concurrency';

import {
  ERROR_TOAST_IGNORE_HTTP_STATUSES,
  SENTRY_IGNORE_HTTP_STATUSES,
  STATUS,
} from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface ReceivableInvoicesNewGeneralSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesNewGeneralComponent extends Component<ReceivableInvoicesNewGeneralSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare sentry: Services['sentry'];

  get isFullCreditNote() {
    // Check that the amount of the credit note (without negative sign) is greater than or equal to the amount due
    // To be sure the credit note compensate the amount of the invoice
    return (
      // @ts-expect-error
      -parseFloat(this.args.creditNote.totalAmount) >=
      // @ts-expect-error
      parseFloat(this.args.creditNote.receivableInvoice.get('amountDue'))
    );
  }

  get creditNoteCreationDescription() {
    let shouldDisplayItalianCopy = this.organizationManager.organization.legalCountry === 'IT';
    // @ts-expect-error
    let invoiceNumber = this.args.creditNote.receivableInvoice.get('number');
    // @ts-expect-error
    let status = this.args.creditNote.receivableInvoice.get('status');

    if (status === STATUS.CANCELED) {
      return this.intl.t('receivable-invoices.issue-credit-note-modal.canceled.full.description');
    }

    if (status === STATUS.UNPAID) {
      return shouldDisplayItalianCopy
        ? this.intl.t('receivable-invoices.issue-credit-note-modal.unpaid.full.italy.description', {
            invoiceNumber,
          })
        : this.intl.t('receivable-invoices.issue-credit-note-modal.unpaid.full.description', {
            invoiceNumber,
          });
    }

    if (this.isFullCreditNote) {
      return shouldDisplayItalianCopy
        ? this.intl.t('receivable-invoices.issue-credit-note-modal.paid.full.italy.description', {
            invoiceNumber,
          })
        : this.intl.t('receivable-invoices.issue-credit-note-modal.paid.full.description', {
            invoiceNumber,
          });
    }

    return shouldDisplayItalianCopy
      ? this.intl.t('receivable-invoices.issue-credit-note-modal.paid.partial.italy.description', {
          invoiceNumber,
        })
      : this.intl.t('receivable-invoices.issue-credit-note-modal.paid.partial.description', {
          invoiceNumber,
        });
  }

  get toastSuccessMessage() {
    if (this.isFullCreditNote) {
      return this.intl.t('receivable-invoices.issue-credit-note-modal.toaster.canceled', {
        // @ts-expect-error
        invoiceNumber: this.args.creditNote.receivableInvoice.get('number'),
      });
    }
    return this.intl.t('receivable-invoices.issue-credit-note-modal.toaster.success');
  }

  triggerCreditNoteCreationTask = dropTask(async () => {
    this.segment.track('credit_note_creation_submitted');
    await this.modals.open('receivable-invoices/credit-notes/confirm-creation-modal', {
      title: this.intl.t('receivable-invoices.issue-credit-note-modal.title'),
      description: this.creditNoteCreationDescription,
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('receivable-invoices.issue-credit-note-modal.create-credit-note'),
      confirmTask: this.saveTask,
    });
  });

  saveTask = dropTask(async closeModal => {
    try {
      this.segment.track('credit_note_creation_confirmed');
      // @ts-expect-error
      await this.args.creditNote.save();
      // Remove locally stored items because backend return copies of those items with new ids
      // @ts-expect-error
      this.args.creditNote.clearItemsWithNoId();
      // @ts-expect-error
      this.router.transitionTo('receivable-invoices.credit-notes.share', this.args.creditNote.id);
      this.toastFlashMessages.toastSuccess(this.toastSuccessMessage);
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal?.();
    }
  });

  // @ts-expect-error
  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    if (!ERROR_TOAST_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
    later(this, this.scrollIntoError, macroCondition(isTesting()) ? 0 : 200);
  }

  async scrollIntoError() {
    scrollIntoView('[data-has-error]');
  }

  cancelTask = dropTask(async () => {
    await this.modals.open('receivable-invoices/exit-credit-note-form-modal', {
      title: this.intl.t('receivable-invoices.credit-note-creation.exit-modal.title'),
      description: this.intl.t('receivable-invoices.credit-note-creation.exit-modal.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.confirmCancelTask,
    });
  });

  confirmCancelTask = dropTask(async close => {
    this.segment.track('credit-note_creation_canceled');
    this.router.transitionTo('receivable-invoices.index');
    await close();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CreditNotes::General': typeof ReceivableInvoicesNewGeneralComponent;
  }
}
