export default {
  "container": "cOo",
  "main": "cOn",
  "preview": "cOi",
  "form": "cOA",
  "scrollable": "cOY",
  "header": "cOh title-1",
  "form-sections": "cOf",
  "form-section": "cOK",
  "footer": "cOG",
  "preview-header": "cOr",
  "centered": "cOb",
  "preview-content-wrapper": "cOy",
  "preview-content": "cOU",
  "payment-link-preview": "cOj"
};
