export default {
  "wrapper": "crj",
  "header": "crW mb-48",
  "form-title": "cru title-2 mb-16",
  "form-subtitle": "cbS body-2",
  "form": "cbc",
  "section": "cbq mb-48",
  "section-title": "cbZ title-3 mb-16",
  "form-footer": "cbR",
  "footer-btn": "cbQ",
  "transfer-form": "cbe"
};
