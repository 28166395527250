export default {
  "section-container": "chJ",
  "disclaimer-section": "chs",
  "trip-title": "chF caption-bold mb-16",
  "google-link-container": "chg",
  "link-icon": "chD",
  "trip-details": "chV body-2",
  "trip-details-icon": "chL",
  "trip-details-title": "chT",
  "subtitle": "chz caption-bold mb-16",
  "disclaimer": "cha",
  "visualizer": "chH"
};
