/* import __COLOCATED_TEMPLATE__ from './amount-with-reference.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';

interface RequestTransferAmountWithReferenceSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestTransferAmountWithReference extends Component<RequestTransferAmountWithReferenceSignature> {
  amountField = AmountField;

  @tracked isAmountFieldDirty = false;

  get shouldDisplayAmountValidationErrorMessage() {
    // @ts-expect-error
    return this.isAmountFieldDirty || this.args.enableTransferValidations;
  }

  // @ts-expect-error
  @action onAmountChange(amountValue) {
    // @ts-expect-error
    this.args.requestTransfer.set('amount', amountValue);
  }

  @action onAmountFocus() {
    this.isAmountFieldDirty = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::AmountWithReference': typeof RequestTransferAmountWithReference;
  }
}
