export default {
  "row": "cyH",
  "cell": "cyO",
  "checkbox": "cym",
  "icon": "cyp",
  "creditor-item-container": "cyx",
  "actions-item-container": "cyw",
  "due-date": "cyN",
  "amount": "cyv",
  "align-right": "cyo"
};
