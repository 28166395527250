export default {
  "sidebar": "cHY",
  "body": "cHh",
  "form": "cHf",
  "form-section": "cHK",
  "form-title": "cHG title2",
  "row": "cHr",
  "payment-details": "cHb",
  "col": "cHy",
  "footer": "cHU",
  "footer-button": "cHj"
};
