/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import Component from '@glimmer/component';

import { FooterCTA } from 'qonto/react/reimbursements/components/bulk/sidebar/footer/footer-cta';
interface FooterSignature {
  Element: null;
  Args: {
    isLastStep?: boolean;
    isLoading?: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class Footer extends Component<FooterSignature> {
  footerCta = FooterCTA;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Bulk::Sidebar::Footer': typeof Footer;
  }
}
