/* import __COLOCATED_TEMPLATE__ from './invalid-client-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface InvalidClientModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const InvalidClientModal = templateOnlyComponent<InvalidClientModalSignature>();

export default InvalidClientModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvalidClientModal': typeof InvalidClientModal;
  }
}
