export default {
  "header": "cfs",
  "header-type": "cfF",
  "close": "cfg",
  "close-icon": "cfD",
  "header-infos": "cfV",
  "title": "cfL body-1",
  "declined": "cfT",
  "canceled": "cfz",
  "approved": "cfa",
  "date": "cfH body-2",
  "container": "cfO",
  "picto": "cfm",
  "request": "cfp",
  "picto-status": "cfx",
  "check": "cfw",
  "stop": "cfN",
  "warning": "cfv",
  "general": "cfo",
  "amount": "cfn",
  "counterparty": "cfi body-2",
  "initiator": "cfA",
  "initiator-avatar": "cfY",
  "avatar-image": "cfh",
  "initiator-id": "cff",
  "name": "cfK body-2",
  "role": "cfG caption-bold",
  "infobox": "cfr",
  "account-infobox": "cfb cfr",
  "beneficiary-warning": "cfy caption-bold",
  "dates-wrapper": "cfU",
  "dates": "cfj",
  "recurrence": "cfW caption-bold",
  "arrow": "cfu",
  "notify-checkbox": "cKS"
};
