/* import __COLOCATED_TEMPLATE__ from './activation-levers.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

interface PopupConfirmationActivationLeversSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class PopupConfirmationActivationLeversComponent extends Component<PopupConfirmationActivationLeversSignature> {
  @service declare segment: Services['segment'];
  @service declare flowLinkManager: Services['flowLinkManager'];

  constructor(owner: unknown, args: PopupConfirmationActivationLeversSignature['Args']) {
    super(owner, args);
    this.segment.track('request_modal_opened');
  }

  confirmTask = dropTask(async () => {
    this.segment.track('request_modal_accepted');
    // @ts-expect-error
    await this.args.close();
    this._setSawActivationLeversModal();
    this.flowLinkManager.transitionTo({
      name: 'requests-cards',
      stepId: 'budget',
      queryParams: { cardLevel: CARD_LEVELS.FLASH },
    });
  });

  @action
  close() {
    this.segment.track('request_modal_canceled');
    this._setSawActivationLeversModal();
    // @ts-expect-error
    this.args.close();
  }

  _setSawActivationLeversModal() {
    // @ts-expect-error
    safeLocalStorage.setItem('saw-activation-levers-modal', true);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Requests::Cards::Popup::Confirmation::ActivationLevers': typeof PopupConfirmationActivationLeversComponent;
  }
}
