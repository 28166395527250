export default {
  "header-cell": "cnE",
  "empty": "cnd",
  "header-content": "cnI caption-bold",
  "active": "cnt",
  "header-type": "cnM",
  "header-reason": "cnP",
  "header-status": "cnl",
  "header-amount": "cnX",
  "row-sidebar": "cnC",
  "hidden": "cnk",
  "animated": "cnJ",
  "fadein-item": "cns",
  "animated-cell": "cnF"
};
