export default {
  "row": "cng",
  "active": "cnD",
  "dot": "cnV",
  "red": "cnL",
  "green": "cnT",
  "yellow": "cnz",
  "status": "cna",
  "align-right": "cnH",
  "animated": "cnO",
  "fadein-item": "cnm",
  "cell": "cnp body-2",
  "cell-content": "cnx",
  "amount": "cnw body-2",
  "subtitle": "cnN caption",
  "padding-left": "cnv",
  "padding-right": "cno",
  "note": "cnn"
};
