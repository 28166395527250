export default {
  "header": "cog",
  "header-type": "coD",
  "close": "coV",
  "close-icon": "coL",
  "header-infos": "coT",
  "title": "coz body-1",
  "declined": "coa",
  "canceled": "coH",
  "approved": "coO",
  "pending": "com",
  "date": "cop body-2",
  "mileage-icon": "cox",
  "distance-subtitle": "cow body-2",
  "link-icon": "coN",
  "container": "cov",
  "picto": "coo",
  "general": "con",
  "amount": "coi",
  "calculation-container": "coA",
  "counterparty": "coY body-2",
  "infobox": "coh"
};
