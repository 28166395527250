/* import __COLOCATED_TEMPLATE__ from './share-panel.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { BadgeHighlight, Checkbox, Disclaimer, TextAreaField } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
// @ts-expect-error
import { variation } from 'ember-launch-darkly';

import { apiBaseURL, receivableInvoiceV5Namespace } from 'qonto/constants/hosts';
// @ts-expect-error
import { QUOTE_STATUSES } from 'qonto/constants/quotes';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import type CreditNoteModel from 'qonto/models/credit-note';
import type QuoteModel from 'qonto/models/quote';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice';
import type ReceivableInvoiceUploadModel from 'qonto/models/receivable-invoices-upload';
import { QuoteEsignature } from 'qonto/react/receivable-invoices/components/quote-esignature/quote-esignature';
import { E_SIGNATURE_CONFIRM_POPUP_STORAGE_KEY } from 'qonto/react/receivable-invoices/constants';
import { CopyButton } from 'qonto/react/shared/components/copy-button';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ReceivableInvoicesSharePanelShareByEmailSignature {
  // The arguments accepted by the component
  Args: {
    attachments: ReceivableInvoiceUploadModel[];
    close: () => void;
    contactEmail: string;
    copyToSelf?: boolean;
    document: CreditNoteModel | QuoteModel | ReceivableInvoiceModel;
    emailMessage: string;
    emailPreviewLanguage?: string;
    emailSubject: string;
    isAccountantTabActive?: boolean;
    isCreditNote?: boolean;
    isDefaultEmailTemplate?: boolean;
    isDraft?: boolean;
    isQuote?: boolean;
    organization: string;
    sendTo: string;
    shareableLink?: boolean;
    showUpdateEmailTrigger?: boolean;
    toggleIsESignatureClientEnabled?: () => void;
    trackEvent?: string;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesSharePanelShareByEmail extends Component<ReceivableInvoicesSharePanelShareByEmailSignature> {
  badgeHighlight = BadgeHighlight;
  checkbox = Checkbox;
  CopyButton = CopyButton;
  disclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  textAreaField = TextAreaField;
  quoteEsignature = QuoteEsignature;

  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];
  @service declare networkManager: Services['networkManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sentry: Services['sentry'];
  @service declare errors: Services['errors'];
  @service declare store: Services['store'];
  @service declare router: Services['router'];
  @service declare reactPopup: Services['reactPopup'];
  @service declare modals: Services['modals'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare userManager: Services['userManager'];

  @tracked displaySubjectErrors = false;
  @tracked areEmailsInvalid = false;
  @tracked sendTo = this.args.sendTo || '';
  @tracked displaySendToErrors = false;
  @tracked saveEmailTemplate = false;
  @tracked isESignatureClientEnabled = false;
  @tracked isESignatureIssuerEnabled = false;
  @tracked isSendToDisabled = false;
  @tracked eSignatureClientDetails = {
    // @ts-expect-error
    firstName: this.args.document?.customerSnapshot?.firstName,
    // @ts-expect-error
    lastName: this.args.document?.customerSnapshot?.lastName,
    email: this.args.sendTo,
  };
  @tracked displayESignatureClientDetailsError = {
    firstName: false,
    lastName: false,
    email: {
      empty: false,
      invalid: false,
    },
  };
  @tracked forceUpdateQuoteESignature = Date.now();
  @tracked confirmModal: void | undefined;

  sendDocumentByEmail = dropTask(async () => {
    if (
      variation('feature--boolean-ar-quotes-esignature') &&
      this.args.isQuote &&
      this.isESignatureClientEnabled
    ) {
      return await this.openQuoteESignatureConfirmModal();
    }
    if (this.args.isQuote) {
      return await this.shareQuoteByEmailTask.perform();
    }
    return await this.shareByEmailTask.perform();
  });

  get isSharingDocument() {
    return (
      this.shareQuoteByEmailTask.isRunning ||
      this.shareQuoteESignatureByEmailTask.isRunning ||
      this.shareByEmailTask.isRunning
    );
  }

  @action
  // @ts-expect-error
  trackingEvent(eventName) {
    this.toastFlashMessages.toastInfo(this.intl.t('receivable-invoices.share-link.toaster-copied'));
    this.segment.track(eventName);
  }

  get showDraftDisclaimer() {
    return !this.args.isAccountantTabActive && this.args.isDraft;
  }

  get previousRoute() {
    return this.router.currentRoute?.queryParams?.origin;
  }

  get showFrEinvoiceDisclaimer() {
    let creationRoutes = [
      'receivable-invoices.edit',
      'receivable-invoices.new',
      'receivable-invoices.duplicate',
      'receivable-invoices.draft-finalize',
    ];

    // @ts-expect-error
    if (creationRoutes.includes(this.previousRoute)) {
      // @ts-expect-error
      return !this.args.isDraft && this.args.document?.isEinvoice;
    }
    return false;
  }

  get documentType() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.document-types.quotes');
    }

    if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.document-types.drafts');
    }

    return this.intl.t('receivable-invoices.document-types.invoices');
  }

  get sendToFieldMessage() {
    if (!this.displaySendToErrors) {
      return this.intl.t('receivable-invoices.share-email.field-send-to.disclaimer');
    }

    if (this.areEmailsInvalid) {
      return this.intl.t('receivable-invoices.share-email.field-send-to.error');
    }

    if (!this.sendTo) {
      return this.intl.t('receivable-invoices.share-email.required-field');
    }
  }

  get shouldShowSubtitle() {
    return !this.args.isAccountantTabActive && !this.args.isCreditNote;
  }

  get subtitle() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.share-email.subtitle.quotes');
    }

    if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.share-email.subtitle.drafts');
    }

    return this.intl.t('receivable-invoices.share-email.subtitle.invoices');
  }

  get hasAttachments() {
    return this.args.attachments?.length > 0;
  }

  @action
  // @ts-expect-error
  updateSendTo(value) {
    this.sendTo = value;
    this.displaySendToErrors = false;

    this.eSignatureClientDetails.email = value.split(',')[0];
  }

  get subjectFieldMessage() {
    this.updateDisplaySubjectErrors(false, this.subjectHasError);

    if (this.displaySubjectErrors) {
      return this.intl.t('receivable-invoices.share-email.required-field');
    }
  }

  get emailsArray() {
    let { sendTo } = this;

    if (!sendTo) return [];

    //Delete space after ',' char,
    //Delete ',' char when first or last param
    //Return an array of emails from a string where emails are separated by a comma
    let emailsArray = sendTo.replace(/^,/, '').replace(/,$/, '').split(',');

    return emailsArray.map(email => email.trim());
  }

  get subjectHasError() {
    let { emailSubject } = this.args;
    return !emailSubject;
  }

  get saveTemplate() {
    return this.args.showUpdateEmailTrigger && this.saveEmailTemplate;
  }

  shareByEmailTask = task(async () => {
    this.updateDisplaySubjectErrors(true, this.subjectHasError);
    let isSendToEmpty = !this.sendTo;
    this.areEmailsInvalid = false;
    this.displaySendToErrors = true;

    if (!isSendToEmpty && !this.displaySubjectErrors) {
      this.sendEmailTrackingEvent();
      let { emailSubject, emailMessage, copyToSelf, close } = this.args;

      try {
        await this.networkManager.request(this.sendURL, {
          method: 'POST',
          data: JSON.stringify({
            send_to: this.emailsArray,
            email_title: emailSubject,
            email_body: emailMessage,
            copy_to_self: copyToSelf,
            save_template: this.saveTemplate,
          }),
        });

        this.toastFlashMessages.toastSuccess(this.sendEmailSuccessMessage);
        close();
      } catch (error) {
        if (
          // @ts-expect-error
          error.status === 422 &&
          // @ts-expect-error
          error.errors.some(
            // @ts-expect-error
            ({ code, source }) => code === 'invalid' && source?.pointer.includes('sendto')
          )
        ) {
          this.areEmailsInvalid = true;
        } else {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          // @ts-expect-error
          if (this.errors.shouldFlash(error)) {
            this.toastFlashMessages.toastError(
              this.intl.t('receivable-invoices.share-email.toast-error')
            );
          }
        }
      }
    }
  });

  shareQuoteByEmailTask = task(async () => {
    this.updateDisplaySubjectErrors(true, this.subjectHasError);
    let isSendToEmpty = !this.sendTo;
    this.areEmailsInvalid = false;
    this.displaySendToErrors = true;

    if (!isSendToEmpty && !this.displaySubjectErrors) {
      this.sendEmailTrackingEvent();
      let { emailSubject, emailMessage, copyToSelf, close } = this.args;

      try {
        await this.networkManager.request(this.sendURL, {
          method: 'POST',
          data: JSON.stringify({
            send_to: this.emailsArray,
            email_title: emailSubject,
            email_body: emailMessage,
            copy_to_self: copyToSelf,
            save_template: this.saveTemplate,
          }),
        });
        this.toastFlashMessages.toastSuccess(this.sendEmailSuccessMessage);
        close();
      } catch (error) {
        if (
          // @ts-expect-error
          error.status === 422 &&
          // @ts-expect-error
          error.errors.some(
            // @ts-expect-error
            ({ code, source }) => code === 'invalid' && source?.pointer.includes('sendto')
          )
        ) {
          this.areEmailsInvalid = true;
        } else {
          let errorInfo = ErrorInfo.for(error);
          if (errorInfo.shouldSendToSentry) {
            this.sentry.captureException(error);
          }
          // @ts-expect-error
          if (this.errors.shouldFlash(error)) {
            this.toastFlashMessages.toastError(
              this.intl.t('receivable-invoices.share-email.toast-error')
            );
          }
        }
      }
    }
  });

  async openQuoteESignatureConfirmModal() {
    this.updateDisplaySubjectErrors(true, this.subjectHasError);
    this.areEmailsInvalid = false;
    let errors = this.updateESignatureClientDetailErrors();
    this.forceUpdateQuoteESignature = Date.now();

    let title = this.isESignatureIssuerEnabled
      ? this.intl.t('receivable-invoices.quote-sending.e-signature.popup.title-sign')
      : this.intl.t('receivable-invoices.quote-sending.e-signature.popup.title');

    if (this.displaySubjectErrors || errors) {
      return;
    }
    if (safeLocalStorage.getItem(E_SIGNATURE_CONFIRM_POPUP_STORAGE_KEY) !== 'true') {
      this.confirmModal = this.modals.open('receivable-invoices/confirm-send-quote-modal', {
        title,
        description: this.intl.t('receivable-invoices.quote-sending.e-signature.popup.body'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.sendQuoteText,
        confirmTask: this.shareQuoteESignatureByEmailTask,
      });
    } else {
      await this.shareQuoteESignatureByEmailTask.perform();
    }
  }

  shareQuoteESignatureByEmailTask = dropTask(async () => {
    this.segment.track('electronic-signature_request-confirmed');

    let { emailSubject, emailMessage, close, document } = this.args;
    let { firstName, lastName, email } = this.eSignatureClientDetails;
    let qontoUser = this.userManager?.currentUser;
    let quoteId = document.id;

    let qonto_user = this.isESignatureIssuerEnabled
      ? {
          //@ts-expect-error
          first_name: qontoUser?.firstName.trim(),
          //@ts-expect-error
          last_name: qontoUser?.lastName.trim(),
          //@ts-expect-error
          email: qontoUser?.email.trim(),
        }
      : null;

    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${receivableInvoiceV5Namespace}/receivable_invoices/quotes/${quoteId}/signature_requests`,
        {
          method: 'POST',
          data: JSON.stringify({
            email_title: emailSubject,
            email_body: emailMessage,
            save_template: this.saveTemplate,
            electronic_signature: {
              client: {
                first_name: firstName.trim(),
                last_name: lastName.trim(),
                email: email.trim(),
              },
              qonto_user,
            },
          }),
        }
      );

      if (!this.isESignatureIssuerEnabled) {
        this.toastFlashMessages.toastSuccess(this.sendEmailSuccessMessage);
        close();
        return;
      }

      let signatureUrl = response?.electronic_signature_url;

      if (signatureUrl) {
        this.flowLinkManager.transitionTo({
          name: 'quote-esignature',
          stepId: 'signature',
          queryParams: { quoteId, signatureUrl },
        });
      } else {
        throw new Error('electronic_signature_url is empty');
      }
    } catch (error) {
      if (
        // @ts-expect-error
        error.status === 422 &&
        // @ts-expect-error
        error.errors.some(
          ({ code, source }: { code: string; source: { pointer: string } }) =>
            code === 'invalid' && source?.pointer.includes('client/email')
        )
      ) {
        this.displayESignatureClientDetailsError = {
          ...this.displayESignatureClientDetailsError,
          email: {
            ...this.displayESignatureClientDetailsError.email,
            invalid: true,
          },
        };
        this.forceUpdateQuoteESignature = Date.now();
        return;
      } else {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(
          this.intl.t('receivable-invoices.share-email.toast-error')
        );
      }
    } finally {
      // @ts-expect-error
      this.confirmModal?.close();
    }
  });

  get sendURL() {
    let adapter;
    let { isCreditNote, isQuote } = this.args;
    if (isCreditNote) {
      adapter = 'receivable-credit-note';
    } else if (isQuote) {
      adapter = 'quote';
    } else {
      adapter = 'receivable-invoice';
    }
    // @ts-expect-error
    return this.store.adapterFor(adapter).sendUrl(this.args.document.id);
  }

  get sendQuoteText() {
    if (this.isESignatureIssuerEnabled) {
      return this.intl.t('receivable-invoices.quote-sending.e-signature.cta-send');
    }
    return this.intl.t('receivable-invoices.quote-sending.send-quote.cta');
  }

  sendEmailTrackingEvent() {
    let {
      isDefaultEmailTemplate,
      isDraft,
      isAccountantTabActive,
      isCreditNote,
      isQuote,
      showUpdateEmailTrigger,
    } = this.args;

    let props = { isQontoDefault: isDefaultEmailTemplate && !showUpdateEmailTrigger };

    if (isDraft) {
      if (isAccountantTabActive) {
        return this.segment.track('invoice-draft_send-by-email-accountant_confirmed');
      } else {
        return this.segment.track('invoice-draft_send-by-email_confirmed', props);
      }
    } else if (isCreditNote) {
      return this.segment.track('credit-note_send-by-email_confirmed');
    } else if (isQuote) {
      return this.segment.track('quote_send-by-email_confirmed', props);
    } else {
      return this.segment.track('invoice_send-by-email_confirmed', props);
    }
  }

  get sendEmailSuccessMessage() {
    if (this.args.isQuote) {
      return this.intl.t('receivable-invoices.quote-sending.send-quote.toast-success');
    } else if (this.args.isDraft) {
      return this.intl.t('receivable-invoices.share-email.draft.toast-success');
    } else if (this.args.isCreditNote) {
      return this.intl.t('receivable-invoices.share-email.credit-note-toaster');
    } else {
      return this.intl.t('receivable-invoices.share-email.toaster');
    }
  }

  get showElectronicSignature() {
    // @ts-expect-error
    let isPendingApproval = this.args.document?.status === QUOTE_STATUSES.PENDING;
    return (
      variation('feature--boolean-ar-quotes-esignature') && this.args.isQuote && isPendingApproval
    );
  }

  @action handleClientToggleChange() {
    // @ts-expect-error
    this.args.toggleIsESignatureClientEnabled();
    let newClientToggleState = !this.isESignatureClientEnabled;
    this.isESignatureClientEnabled = newClientToggleState;
    this.isSendToDisabled = newClientToggleState;
    this.displaySendToErrors = false;

    if (!newClientToggleState) this.isESignatureIssuerEnabled = false;
  }

  @action handleIssuerToggleChange() {
    this.isESignatureIssuerEnabled = !this.isESignatureIssuerEnabled;
  }

  @action updateESignatureClientDetails(value: {
    firstName: string;
    lastName: string;
    email: string;
  }) {
    this.eSignatureClientDetails = value;
    this.displayESignatureClientDetailsError = {
      firstName: Boolean(!value.firstName),
      lastName: Boolean(!value.lastName),
      email: {
        empty: Boolean(!value.email),
        invalid: Boolean(!value.email),
      },
    };
  }

  updateESignatureClientDetailErrors(): boolean {
    let { firstName, lastName, email } = this.eSignatureClientDetails;

    if (!firstName || !lastName || !email) {
      this.displayESignatureClientDetailsError = {
        firstName: Boolean(!firstName),
        lastName: Boolean(!lastName),
        email: {
          empty: Boolean(!email),
          invalid: Boolean(!email),
        },
      };
      return true;
    }
    return false;
  }

  // @ts-expect-error
  updateDisplayErrors(clickOnSend, hasErrors) {
    if (clickOnSend && hasErrors) {
      return hasErrors;
    } else if (!hasErrors) {
      return false;
    }
    return null;
  }

  // @ts-expect-error
  updateDisplaySubjectErrors(clickOnSend, hasErrors) {
    let updateValue = this.updateDisplayErrors(clickOnSend, hasErrors);
    if (updateValue !== null) {
      this.displaySubjectErrors = updateValue;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::SharePanel::SharePanel': typeof ReceivableInvoicesSharePanelShareByEmail;
  }
}
