/* import __COLOCATED_TEMPLATE__ from './account-select-popup.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface AccountSelectPopupSignature {
  Element: null;
  Args: {
    data: {
      activeAccounts: any[];
      mainAccount: any;
      onSubmitRunning: boolean;
      onSubmit: (bankAccount: any) => void;
    };
    close: () => void;
  };
  Blocks: {
    default: [];
  };
}

export default class AccountSelectPopup extends Component<AccountSelectPopupSignature> {
  @tracked
  bankAccount = this.args.data.mainAccount;

  selectBankAccount(bankAccount: any) {
    this.bankAccount = bankAccount;
  }

  get activeAccounts() {
    return this.args.data.activeAccounts;
  }

  onSubmit = () => {
    this.args.data.onSubmit(this.bankAccount);
    this.args.close();
  };
}
