export default {
  "referral-invite": "cxd",
  "referral": "cxI",
  "illustration": "cxt",
  "steps": "cxM",
  "step": "cxP",
  "share-referral": "cxl",
  "choice": "cxX",
  "line": "cxC",
  "email": "cxk",
  "email-input": "cxJ",
  "input-field--error": "cxs",
  "error-message": "cxF",
  "external-link-icon": "cxg"
};
