/* import __COLOCATED_TEMPLATE__ from './updated-customer-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface UpdatedCustomerModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const UpdatedCustomerModal = templateOnlyComponent<UpdatedCustomerModalSignature>();

export default UpdatedCustomerModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::UpdatedCustomerModal': typeof UpdatedCustomerModal;
  }
}
