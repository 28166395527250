/* import __COLOCATED_TEMPLATE__ from './cancel-customer-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CancelCustomerModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const CancelCustomerModal = templateOnlyComponent<CancelCustomerModalSignature>();

export default CancelCustomerModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CancelCustomerModal': typeof CancelCustomerModal;
  }
}
