export default {
  "client-form": "cLy",
  "align-start": "cLU",
  "kind": "cLj",
  "name-fields": "cLW",
  "field": "cLu",
  "required-data-disclaimer": "cTS",
  "row": "cTc",
  "child": "cTq",
  "confirm-action": "cTZ",
  "delete-action": "cTR",
  "legal-info": "cTQ",
  "column-layout": "cTe"
};
