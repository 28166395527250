export default {
  "form": "cOW",
  "container": "cOu",
  "section": "cmS",
  "footer": "cmc",
  "attachment": "cmq",
  "attachment-title": "cmZ",
  "attachment-uploader-disabled": "cmR",
  "attachment-overlay": "cmQ",
  "attachment-overlay-hidden": "cme"
};
