/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { ProgressBar } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { DEFAULT_ORIGIN_URL, SAVINGS_DEFAULT_URL } from 'qonto/constants/bank-account';

interface RemuneratedAccountsCardSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RemuneratedAccountsCard extends Component<RemuneratedAccountsCardSignature> {
  progressBar = ProgressBar;

  @service declare modals: Services['modals'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  // @ts-expect-error
  @reads('organizationManager.organization.remuneratedAccounts') remuneratedAccounts;

  get remuneratedAccount() {
    return this.remuneratedAccounts.find(
      // @ts-expect-error
      remuneratedAccount => remuneratedAccount.id === this.args.remuneratedAccount.id
    );
  }

  get origin() {
    let origin = DEFAULT_ORIGIN_URL;
    if (!this.router.currentRouteName?.includes(DEFAULT_ORIGIN_URL)) {
      origin = SAVINGS_DEFAULT_URL;
    }
    return origin;
  }

  showRenameAccountModalTask = task(async () => {
    await this.modals.open('bank-accounts/account-rename-modal', {
      account: this.remuneratedAccount,
    });
  });

  showCloseAccountModalTask = task(async () => {
    let bankAccount = this.remuneratedAccount;
    // @ts-expect-error
    let remuneratedAccountDetails = this.args.remuneratedAccount;
    this.segment.track('bank_account_close_clicked', {
      slug: bankAccount.slug,
      account_type: bankAccount.accountType,
    });
    await this.modals.open('bank-accounts/account-close-modal', {
      bankAccount,
      remuneratedAccountDetails,
    });
  });

  get maxMaturityRate() {
    // @ts-expect-error
    return this.args.remuneratedAccount.interestRates.at(-1).rate / 100;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RemuneratedAccounts::Card': typeof RemuneratedAccountsCard;
  }
}
