export default {
  "qonto-pilot-modal": "cVu",
  "content": "cLS",
  "header": "cLc",
  "transition-state-container": "cLq",
  "lottie": "cLZ",
  "prompt-input": "cLR",
  "buttons": "cLQ",
  "footer": "cLe caption",
  "divider": "cLB",
  "illustration": "cLE",
  "icon": "cLd",
  "quick-actions-container": "cLI",
  "action": "cLt body-2",
  "action-title": "cLM",
  "disabled": "cLP",
  "action-tag": "cLl caption-bold",
  "hotkey": "cLX body-1",
  "footer-action": "cLC",
  "grow": "cLk",
  "footer-feedback-link": "cLJ",
  "buttons-container": "cLs",
  "transfer-details-container": "cLF",
  "error-container": "cLg",
  "option-iban": "cLD",
  "actions": "cLV",
  "title": "cLL",
  "group-title": "cLT caption-bold",
  "beta-badge": "cLz",
  "hotkeys": "cLa",
  "supplier-invoices-dropzone": "cLH",
  "success-icon": "cLO",
  "error-icon": "cLm",
  "file-items": "cLp",
  "file-item": "cLx",
  "uploader-file": "cLw",
  "transactions-table": "cLN",
  "transaction": "cLv",
  "prompt-example": "cLo"
};
