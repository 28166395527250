export default {
  "row": "cGk",
  "active": "cGJ",
  "animated": "cGs",
  "fadein-item": "cGF",
  "cell": "cGg body-2",
  "cell-content": "cGD",
  "subtitle": "cGV caption",
  "no-padding": "cGL",
  "note": "cGT",
  "cell-amount": "cGz",
  "cell-content-amount": "cGa body-1"
};
