/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

import { Tag } from '@repo/design-system-kit';

import { REQUEST_STATUS_COLORS, type StatusType } from 'qonto/constants/requests';
import { ExpenseReport } from 'qonto/react/components/reimbursements/requests/table/cell/expense-report';

interface RequestTableItemRequesterSignature {
  // The arguments accepted by the component
  Args: {
    isHighlighted?: boolean;
    isSidepanelOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLTableRowElement;
}

export default class RequestTableItemRequesterComponent extends Component<RequestTableItemRequesterSignature> {
  expenseReport = ExpenseReport;

  tag = Tag;

  get requestAmount() {
    // @ts-expect-error
    let { request } = this.args;

    switch (request.requestType) {
      case 'mileage':
        return { value: request.amount.value, currency: request.amount.currency };
      case 'expense_report': {
        return request.amount;
      }
    }
  }

  get status() {
    // @ts-expect-error
    let { status, displayedStatus } = this.args.request;
    return {
      displayedStatus,
      color: REQUEST_STATUS_COLORS[status as StatusType],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Requests::Table::Item': typeof RequestTableItemRequesterComponent;
  }
}
