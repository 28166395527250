export default {
  "invoice-sidebar": "cHl",
  "body": "cHX",
  "box": "cHC",
  "box-header": "cHk",
  "footer": "cHJ",
  "footer-button": "cHs",
  "footer-button-tooltip": "cHF",
  "reduced-fontsize": "cHg",
  "sidebar-box": "cHD",
  "border-top": "cHV",
  "row": "cHL",
  "status-container": "cHT",
  "due-date-container": "cHz",
  "greyed-out": "cHa",
  "struck-through": "cHH",
  "overdue": "cHO",
  "primary-actions": "cHm cHD",
  "primary-action": "cHp",
  "danger-action": "cHx",
  "button-icon": "cHw",
  "button-label": "cHN body-2",
  "related-credit-note-title": "cHv caption-bold",
  "paid-at-placeholder": "cHo",
  "collapsible-timeline": "cHn",
  "timeline-status-label": "cHi",
  "related-documents": "cHA"
};
