export default {
  "header-cell": "cKb",
  "header-content": "cKy caption-bold",
  "active": "cKU",
  "empty": "cKj",
  "request-type": "cKW",
  "status": "cKu",
  "note": "cGS",
  "amount": "cGc"
};
