/* import __COLOCATED_TEMPLATE__ from './validation.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask, waitForQueue } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

const CONFIRMED_REJECTION = 'confirmed-rejection';

interface RequestSidebarCardValidationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarCardValidationComponent extends Component<RequestSidebarCardValidationSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare abilities: Services['abilities'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare modals: Services['modals'];
  @service declare notifierCounterManager: Services['notifierCounterManager'];
  @service declare sensitiveActions: Services['sensitiveActions'];

  constructor(owner: unknown, args: RequestSidebarCardValidationSignature['Args']) {
    super(owner, args);
    this.setMainAccountTask.perform().catch(ignoreCancelation);
  }

  close() {
    // @ts-expect-error
    this.args.close?.();
  }

  // @ts-expect-error
  @reads('organizationManager.organization.activeAccounts') activeAccounts;
  // @ts-expect-error
  @reads('organizationManager.membership') membership;

  get accounts() {
    // @ts-expect-error
    return this.activeAccounts.filter(({ isRemunerated }) => !isRemunerated);
  }

  @action
  handleDeclineClick() {
    let params = {
      // @ts-expect-error
      request_type: this.args.request.requestType,
      // @ts-expect-error
      request_id: this.args.request.id,
      role: this.membership.role,
      // @ts-expect-error
      ...this.args.scopeParams,
    };

    this.declineRequestTask.perform().catch(ignoreCancelation);
    this.segment.track('request_decline_clicked', params);
  }

  @action
  handleApproveClick() {
    let params = {
      // @ts-expect-error
      request_type: this.args.request.requestType,
      // @ts-expect-error
      request_id: this.args.request.id,
      role: this.membership.role,
      // @ts-expect-error
      ...this.args.scopeParams,
    };

    // @ts-expect-error
    this.sensitiveActions.runTask.perform(this.approveRequestTask).catch(ignoreCancelation);
    this.segment.track('request_approve_clicked', params);
  }

  @action
  handleRequestPendingCancelClick() {
    // @ts-expect-error
    let { request } = this.args;
    this.cancelRequestTask.perform().catch(ignoreCancelation);
    this.segment.track('request_cancel_clicked', {
      request_type: request.requestType,
      request_id: request.id,
      // @ts-expect-error
      ...this.args.scopeParams,
    });
  }

  get canShowAccountSelector() {
    // @ts-expect-error
    let { isExpired, status } = this.args.request;
    let canShow =
      this.abilities.can('review card requests') &&
      this.activeAccounts.length > 1 &&
      status === 'pending' &&
      !isExpired;

    if (canShow) {
      this.setMainAccountTask.perform().catch(ignoreCancelation);
    }
    return canShow;
  }

  approveRequestTask = dropTask(async () => {
    try {
      // @ts-expect-error
      let { initiator } = this.args.request;

      if (initiator.get('revoked')) {
        return this.toastFlashMessages.toastError(
          this.intl.t('requests.cards.toasts.revoked-member', {
            requesterName: initiator.get('fullName'),
          })
        );
      }

      await this.approveTask.linked().perform();

      let message = this.intl.t('requests.cards.toasts.request-approved');
      this.toastFlashMessages.toastInfo(message);
      this.segment.track('request_approved', {
        // @ts-expect-error
        request_type: this.args.request.requestType,
        // @ts-expect-error
        request_id: this.args.request.id,
        role: this.membership.role,
      });

      /*
        There is no event for card approvals so
        we need to manually trigger counter request here.
        This does not cover when another admin
        approves the request, then the counter is not updated
        as it would be with a websocket.
        These are edge cases so this solution will still
        improve the UX.
      */
      await this.notifierCounterManager.updateCounter();
      this.close();
    } catch (error) {
      // @ts-expect-error
      if (hasMFAError(error?.errors)) {
        throw error;
      }
    }
  });

  approveTask = dropTask(async () => {
    // @ts-expect-error
    await this.args.approve.linked().perform(this.args.request);
  });

  cancelRequestTask = dropTask(async () => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('requests.modals.cancel-modal.title'),
      description: this.intl.t('requests.modals.cancel-modal.description'),
      cancel: this.intl.t('btn.close'),
      confirm: this.intl.t('requests.modals.cancel-modal.cta'),
      confirmTask: this.cancelTask,
    });

    if (result !== undefined) {
      return;
    }

    let message = this.intl.t('requests.cards.toasts.request-cancelled');
    this.toastFlashMessages.toastInfo(message);
    this.segment.track('request_canceled', {
      // @ts-expect-error
      request_type: this.args.request.requestType,
      // @ts-expect-error
      request_id: this.args.request.id,
    });
    this.close();
  });

  cancelTask = dropTask(async close => {
    // @ts-expect-error
    await this.args.cancel(this.args.request);
    close();
  });

  declineRequestTask = dropTask(async () => {
    let result = await this.modals.open('request/sidebar/modals/decline-request', {
      // @ts-expect-error
      request: this.args.request,
      confirmTask: this.declineTask,
    });

    // @ts-expect-error
    if (result !== CONFIRMED_REJECTION) {
      return;
    }

    let message = this.intl.t('requests.cards.toasts.request-declined');
    this.toastFlashMessages.toastInfo(message);
    this.segment.track('request_declined', {
      // @ts-expect-error
      request_type: this.args.request.requestType,
      // @ts-expect-error
      request_id: this.args.request.id,
      role: this.membership.role,
    });
    this.close();
  });

  declineTask = dropTask(async close => {
    // @ts-expect-error
    await this.args.decline(this.args.request);
    close(CONFIRMED_REJECTION);
  });

  @action
  // @ts-expect-error
  handleSetAccount(account) {
    // @ts-expect-error
    this.args.setAccount(this.args.request, account);
    this.setMainAccountTask.perform().catch(ignoreCancelation);
  }

  @action
  handleReloadRequest() {
    this.router.transitionTo('requests.past', {
      // @ts-expect-error
      queryParams: { highlight: this.args.request.id },
    });
  }

  @action
  handleSeeCard() {
    this.segment.track('request_see_item_clicked', {
      // @ts-expect-error
      request_type: this.args.request.requestType,
      // @ts-expect-error
      request_id: this.args.request.id,
      role: this.membership.role,
    });
    this.router.transitionTo('cards.index', {
      queryParams: {
        // @ts-expect-error
        highlight: this.args.request.belongsTo('card').id(),
      },
    });
  }

  setMainAccountTask = dropTask(async () => {
    await waitForQueue('afterRender');
    let { currentAccount } = this.organizationManager;
    // @ts-expect-error
    let bankAccount = await this.args.request.belongsTo('bankAccount').load();
    if (
      this.abilities.can('review card requests') &&
      !bankAccount &&
      // @ts-expect-error
      this.args.request.status === 'pending'
    ) {
      // @ts-expect-error
      this.args.request.bankAccount = currentAccount;
      return currentAccount;
    } else {
      // @ts-expect-error
      return this.args.request.bankAccount;
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Card::Validation': typeof RequestSidebarCardValidationComponent;
  }
}
