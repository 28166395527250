/* import __COLOCATED_TEMPLATE__ from './layout.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { STATUS } from 'qonto/constants/requests';

interface RequestSidebarMileageLayoutSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarMileageLayoutComponent extends Component<RequestSidebarMileageLayoutSignature> {
  @service declare abilities: Services['abilities'];

  get isPastMileage() {
    // @ts-expect-error
    let { status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    return pastStatuses.includes(status);
  }

  get showValidations() {
    // @ts-expect-error
    let { request } = this.args;
    let userId = request.get('initiator.userId');
    let isNotPastMileage = !this.isPastMileage;

    return (
      (this.abilities.can('cancel mileage request', userId) ||
        this.abilities.can('review mileage request')) &&
      isNotPastMileage
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Mileage::Layout': typeof RequestSidebarMileageLayoutComponent;
  }
}
