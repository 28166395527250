/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { reads } from 'macro-decorators';

import { STATUS, type StatusType } from 'qonto/constants/requests';

interface RequestSidebarExpenseReportHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarExpenseReportHeaderComponent extends Component<RequestSidebarExpenseReportHeaderSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @reads('args.request.approver') approver;
  // @ts-expect-error
  @reads('args.request.initiator') initiator;

  // @ts-expect-error
  @reads('args.request.isApproved') showAccountName;

  constructor(owner: unknown, args: RequestSidebarExpenseReportHeaderSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.state = this.args.state || STATUS.PENDING;
  }

  get lastActionDate() {
    // @ts-expect-error
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get isUserReviewer() {
    let currentUserId = this.organizationManager.membership.id;
    // @ts-expect-error
    let initiatorId = this.args.request.initiator.get('id');

    return currentUserId !== initiatorId;
  }

  get titleStatus() {
    // @ts-expect-error
    let { approver, initiator, status } = this.args.request;
    let pastStatuses = [STATUS.CANCELED, STATUS.DECLINED, STATUS.APPROVED];

    if (pastStatuses.includes(status)) {
      let user = approver || initiator;
      let { firstName, lastName } = user.getProperties('firstName', 'lastName');
      let fullName = `${firstName.charAt(0)}. ${lastName}`;
      let approvedTitle = this.isRequestBankless
        ? this.intl.t('expense-reports.sidebar.header.status.submitted')
        : this.intl.t('requests.sidebar.header.status.reimbursed', {
            approver_name: fullName,
          });

      let statuses = {
        [STATUS.DECLINED]: this.intl.t('requests.sidebar.header.status.declined', {
          approver_name: fullName,
        }),
        [STATUS.APPROVED]: approvedTitle,
        [STATUS.CANCELED]: this.intl.t('requests.transfers.final_status.canceled', {
          name: fullName,
        }),
      };

      return statuses[status as Exclude<StatusType, 'pending'>];
    }

    return this.isUserReviewer
      ? this.intl.t('requests.reimbursement.sidebar.title')
      : this.intl.t('requests.sidebar.header.status.pending');
  }

  get titleClass() {
    // @ts-expect-error
    return this.isUserReviewer && this.args.request.status === STATUS.PENDING
      ? ''
      : // @ts-expect-error
        this.args.request.status;
  }

  get subtitle() {
    // @ts-expect-error
    let { status } = this.args.request;
    let formattedDate = this.intl.formatDate(this.lastActionDate, { format: 'short' });

    if (status === 'pending') {
      return this.intl.t('checks.sidebar.requested_at') + ' ' + formattedDate;
    } else if (['declined', 'approved'].includes(status)) {
      return this.intl.t('requests.sidebar.header.updated-date', { updated_at: formattedDate });
    } else {
      return formattedDate;
    }
  }

  get isRequestBankless() {
    // @ts-expect-error
    return this.args.request.bankless;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::ExpenseReport::Header': typeof RequestSidebarExpenseReportHeaderComponent;
  }
}
