export default {
  "header-cell": "cKp",
  "header-content": "cKx caption-bold",
  "active": "cKw",
  "empty": "cKN",
  "align-right": "cKv",
  "request-type": "cKo",
  "requester": "cKn",
  "amount": "cKi"
};
