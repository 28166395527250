/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-expect-error
import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import RibaPaymentModel from 'qonto/models/riba-payment';
import { Wallet } from 'qonto/react/assets/icons';

interface RibaTasksTableItemSignature {
  Args: {
    item: any;
    onSelectItem: (id: string, value: boolean) => void;
    isSelected: boolean;
    openSidebar: (item: any, event: any) => void;
    reloadData: () => void;
    hasSelectedItems: number;
    mainAccount: any;
    activeAccounts: any[];
    onApprove: (bankAccountId: string, paymentIds: string[]) => void;
    onReject: (bankAccountId: string, paymentIds: string[]) => void;
    isApproveRunning: boolean;
    isRejectRunning: boolean;
  };
  Element: HTMLTableRowElement;
}

export default class RibaTasksTableItem extends Component<RibaTasksTableItemSignature> {
  Checkbox = Checkbox;
  Wallet = Wallet;
  @service declare organizationManager: Services['organizationManager'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare sensitiveActions: Services['sensitiveActions'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  @tracked bankAccount;

  private formatSafeName(name: string) {
    return name.length > 14 ? `${name.slice(0, 11)}...` : name;
  }

  private createBankAccountData(account: any, truncateName = false) {
    return {
      id: account.id,
      authorizedBalance: account.authorizedBalance,
      name: account.name,
      balance: account.balance,
      balanceCurrency: account.balanceCurrency,
      safeName: truncateName ? this.formatSafeName(account.name) : account.name,
    };
  }

  constructor(owner: unknown, args: RibaTasksTableItemSignature['Args']) {
    super(owner, args);
    if (args.mainAccount) {
      const hasSingleAccount = args.activeAccounts?.length === 1;
      this.bankAccount = this.createBankAccountData(args.mainAccount, hasSingleAccount);
    }
  }

  get hasSingleBankAccount() {
    return this.args.activeAccounts?.length === 1;
  }

  get isDisabled() {
    return this.args.isApproveRunning || this.args.isRejectRunning || this.args.hasSelectedItems;
  }

  get isReadOnly() {
    return (
      !this.args.isApproveRunning &&
      !this.args.isRejectRunning &&
      this.hasSingleBankAccount &&
      !this.args.hasSelectedItems
    );
  }

  @action
  handleApprove() {
    this.segment.track('riba_pending_payment_approved');
    if (this.bankAccount) {
      this.args.onApprove(this.bankAccount.id, [this.args.item.id]);
    }
  }

  @action
  handleReject() {
    this.segment.track('riba_pending_payment_rejected');
    if (this.bankAccount) {
      this.args.onReject(this.bankAccount.id, [this.args.item.id]);
    }
  }

  @action
  selectPayment() {
    this.args.onSelectItem(this.args.item.id, !this.args.isSelected);
  }

  @action
  openSidebar(item: any, event: any) {
    if (event.target.closest('.ember-power-select-trigger')) {
      return;
    }

    this.args.openSidebar(item, event);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Riba::TasksTable::Item': typeof RibaTasksTableItem;
  }
}
