export default {
  "wrapper": "cxD",
  "tabs": "cxV",
  "tabs-nav": "cxL",
  "tabs-panel-wrapper": "cxT",
  "list-head": "cxz",
  "list": "cxa",
  "footer": "cxH",
  "reward-amount": "cxO",
  "earned": "cxm",
  "pending": "cxp",
  "empty-state-wrapper": "cxx",
  "empty-state": "cxw",
  "illustration": "cxN",
  "error-wrapper": "cxv",
  "error-illustration": "cxo",
  "error-description": "cxn",
  "spinner": "cxi",
  "total-earned": "cxA"
};
