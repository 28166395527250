/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

interface RequestTransferFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestTransferForm extends Component<RequestTransferFormSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  @tracked enableTransferValidations = false;

  get hasUploadTaskRunning() {
    // @ts-expect-error
    return this.args.requestTransfer.attachmentsFiles.some(file => file.task?.isRunning);
  }

  submitTransferTask = dropTask(
    waitFor(async requestTransfer => {
      this.enableTransferValidations = false; // Hide errors
      // @ts-expect-error
      // eslint-disable-next-line ember/no-array-prototype-extensions
      this.args.requestTransfer.errors.clear(); // Clearing previous BE apiAction errors
      requestTransfer.beneficiaryEmail = requestTransfer.email;

      let { validations: transferValidation } = await requestTransfer.validate();

      let isEndDateInvalid = false;
      if (
        (requestTransfer.standingEndingDate &&
          dayjs(requestTransfer.standingEndingDate).isBefore(
            dayjs(requestTransfer.scheduledDate)
          )) ||
        dayjs(requestTransfer.standingEndingDate).isSame(dayjs(requestTransfer.scheduledDate))
      ) {
        isEndDateInvalid = true;
      }

      this.enableTransferValidations = true; // Show errors

      if (transferValidation.isValid && !isEndDateInvalid) {
        let doNotUpdateEmail = !requestTransfer.notifyByEmail;
        if (doNotUpdateEmail) {
          requestTransfer.beneficiaryEmail = requestTransfer.originalBeneficiaryEmail;
        }

        requestTransfer.beneficiaryId = requestTransfer.get('beneficiary.id');
        requestTransfer.beneficiaryIban = requestTransfer.iban;
        requestTransfer.beneficiaryName = requestTransfer.name;
        requestTransfer.beneficiaryBic = requestTransfer.bic;

        this.segment.track('request_note_filled', {
          request_type: requestTransfer.requestType,
        });

        if (requestTransfer.beneficiaryEmail) {
          this.segment.track('single_transfer_request_beneficiary_notification');
        }

        try {
          // @ts-expect-error
          await this.args.saveTransfer(requestTransfer);
        } catch {
          // don't display toast for validation errors
          if (requestTransfer.isValid) {
            let message = this.intl.t('toasts.errors.server_error');
            this.toastFlashMessages.toastError(message);
          }
        }
      }
    })
  );
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::Form': typeof RequestTransferForm;
  }
}
