/* import __COLOCATED_TEMPLATE__ from './tile.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { ProgressBar } from '@repo/design-system-kit';

import { DEFAULT_ORIGIN_URL, SAVINGS_DEFAULT_URL } from 'qonto/constants/bank-account';

interface RemuneratedAccountsTileSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RemuneratedAccountsTile extends Component<RemuneratedAccountsTileSignature> {
  progressBar = ProgressBar;

  @service declare router: Services['router'];

  get origin() {
    let origin = DEFAULT_ORIGIN_URL;
    if (!this.router.currentRouteName?.includes(DEFAULT_ORIGIN_URL)) {
      origin = SAVINGS_DEFAULT_URL;
    }
    return origin;
  }

  get maxMaturityRate() {
    // @ts-expect-error
    return this.args.remuneratedAccount.interestRates.at(-1).rate / 100;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'RemuneratedAccounts::Tile': typeof RemuneratedAccountsTile;
  }
}
