/* import __COLOCATED_TEMPLATE__ from './colorpicker.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ReceivableInvoicesOnboardingColorpickerSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesOnboardingColorpickerComponent extends Component<ReceivableInvoicesOnboardingColorpickerSignature> {
  @action
  // @ts-expect-error
  onChange(event) {
    let { value } = event.target;

    // @ts-expect-error
    this.args.update(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Onboarding::Colorpicker': typeof ReceivableInvoicesOnboardingColorpickerComponent;
  }
}
