export default {
  "container": "cOP",
  "form-container": "cOl",
  "content": "cOX",
  "fields": "cOC",
  "input-settings": "cOk",
  "current-year": "cOJ",
  "next-invoice-number": "cOs",
  "preview-container": "cOF",
  "actions": "cOg",
  "close-button": "cOD btn btn--icon-only btn--tertiary btn--large",
  "preview-image-container": "cOV",
  "preview-image": "cOL"
};
