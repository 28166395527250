/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { parseDate } from '@internationalized/date';
import { DatePicker, Disclaimer, TextAreaField } from '@repo/design-system-kit';

import CURRENCIES from 'qonto/constants/currencies';
import {
  ES_FREELANCER_LEGAL_FORM,
  NUMBERING_MODE,
  STATUS,
  TERM_AND_CONDITIONS_MAX_LENGTH,
  TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT,
} from 'qonto/constants/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';

interface ReceivableInvoicesCreditNotesFormSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesCreditNotesFormComponent extends Component<ReceivableInvoicesCreditNotesFormSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  textAreaField = TextAreaField;
  datePicker = DatePicker;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  get nextCreditNoteNumber() {
    // @ts-expect-error
    return this.args.settings.creditNoteNextNumberFormatted;
  }

  get issueDate() {
    // @ts-expect-error
    return this.args.creditNote?.issueDate ? parseDate(this.args.creditNote.issueDate) : null;
  }

  get minDate() {
    if (
      // @ts-expect-error
      this.args.creditNote?.receivableInvoice &&
      // @ts-expect-error
      this.args.creditNote.receivableInvoice.get('issueDate')
    ) {
      // @ts-expect-error
      return parseDate(this.args.creditNote.receivableInvoice.get('issueDate'));
    }
    return null;
  }

  // @ts-expect-error
  @action onIssueDateChange(date) {
    // @ts-expect-error
    this.args.creditNote.issueDate = date ? date.toString() : null;
  }

  // @ts-expect-error
  @action updateNumber(number) {
    // @ts-expect-error
    this.args.creditNote.number = number.replace(/[^\w/\-?:().,'+]/g, '');
    // @ts-expect-error
    this.args.creditNote.notifyPropertyChange('number');
  }

  @action
  // @ts-expect-error
  deleteItem(item) {
    item.deleteRecord();
  }

  @action
  addItem() {
    // @ts-expect-error
    this.args.creditNote.items.createRecord(
      // @ts-expect-error
      defaultValues(this, this.args.creditNote.items.at(0)?.vatRate)
    );
  }

  get areItemsDisabled() {
    return [STATUS.UNPAID, STATUS.CANCELED].includes(
      // @ts-expect-error
      this.args.creditNote.receivableInvoice.get('status')
    );
  }

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get isEInvoicing() {
    return this.legalCountry === 'IT';
  }

  get prefixTermsAndConditionsLength() {
    let { organization } = this.organizationManager;
    //Here we add 3 characters because we separate the prefix from actual terms and conditions with " - "
    return `${organization.legalName}, ${organization.shortLegalForm}`.length + 3;
  }

  get termsAndConditionsMaxLength() {
    let theoreticalMaxLength = TERM_AND_CONDITIONS_MAX_LENGTH;
    if (this.legalCountry === 'IT') {
      theoreticalMaxLength = TERM_AND_CONDITIONS_MAX_LENGTH_FOR_IT;
    }

    let maxLength = theoreticalMaxLength - this.prefixTermsAndConditionsLength;
    return String(maxLength);
  }

  get numberErrorMessage() {
    // @ts-expect-error
    let errors = this.args.creditNote.errors.errorsFor('number');
    if (errors.length === 0) return null;
    // @ts-expect-error
    if (errors.some(e => e.message === 'credit_note_number_already_exists')) {
      return this.intl.t(
        'receivable-invoices.credit-note-creation.credit-note-details.credit-note-number.try-again-error'
      );
    }
    return this.intl.t('receivable-invoices.credit-note-creation.errors.required-field');
  }

  get isAutomaticNumberingEnabled() {
    // @ts-expect-error
    return this.args.settings?.numberingMode === NUMBERING_MODE.AUTOMATIC;
  }

  get currency() {
    // @ts-expect-error
    return this.args.creditNote?.currency || CURRENCIES.default;
  }

  get isSpanishFreelancer() {
    return (
      this.legalCountry === 'ES' &&
      this.organizationManager.organization.legalForm === ES_FREELANCER_LEGAL_FORM
    );
  }

  get showFiscalDetails() {
    return (
      // @ts-expect-error
      this.isEInvoicing || (this.isSpanishFreelancer && this.args.creditNote?.withholdingTax?.rate)
    );
  }

  get showGlobalDiscount() {
    // @ts-expect-error
    let isDeposit = this.args.creditNote?.receivableInvoice.get('isDeposit');
    if (isDeposit) {
      return false;
    }

    // @ts-expect-error
    let status = this.args.creditNote?.receivableInvoice.get('status');
    // @ts-expect-error
    let hasDiscount = Boolean(this.args.creditNote?.discount);

    if (status === STATUS.PAID) {
      return true;
    } else if (status === STATUS.UNPAID || status === STATUS.CANCELED) {
      return hasDiscount;
    } else {
      return false;
    }
  }

  get isGlobalDiscountDisabled() {
    // @ts-expect-error
    let status = this.args.creditNote?.receivableInvoice.get('status');
    return status !== STATUS.PAID;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CreditNotes::Form': typeof ReceivableInvoicesCreditNotesFormComponent;
  }
}
