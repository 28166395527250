/* import __COLOCATED_TEMPLATE__ from './confirm-creation-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface ConfirmCreationModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const ConfirmCreationModal = templateOnlyComponent<ConfirmCreationModalSignature>();

export default ConfirmCreationModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CreditNotes::ConfirmCreationModal': typeof ConfirmCreationModal;
  }
}
