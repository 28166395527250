/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Checkbox } from '@repo/design-system-kit';
import { and, reads } from 'macro-decorators';

interface RequestSidebarTransferHeaderSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarTransferHeaderComponent extends Component<RequestSidebarTransferHeaderSignature> {
  checkbox = Checkbox;

  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @reads('args.request.approver') approver;
  // @ts-expect-error
  @reads('args.request.initiator') initiator;

  @and(
    'args.request.isApproved',
    'args.request.bankAccount.name',
    'args.request.bankAccount.organization.hasMultipleActiveAccounts'
  )
  // @ts-expect-error
  showAccountName;

  constructor(owner: unknown, args: RequestSidebarTransferHeaderSignature['Args']) {
    super(owner, args);
    // @ts-expect-error
    this.state = this.args.state || 'pending';
  }

  get lastActionDate() {
    // @ts-expect-error
    let { processedAt, createdAt } = this.args.request;
    return processedAt || createdAt;
  }

  get labelStanding() {
    // @ts-expect-error
    if (this.args.request.weekly) {
      return this.intl.t(`transfers.frequency.standing_weekly_short`);
      // @ts-expect-error
    } else if (this.args.request.quarterly) {
      return this.intl.t(`transfers.frequency.standing_quarterly_gender`);
      // @ts-expect-error
    } else if (this.args.request.yearly) {
      return this.intl.t(`transfers.frequency.standing_yearly_gender`);
      // @ts-expect-error
    } else if (this.args.request.halfYearly) {
      return this.intl.t(`transfers.frequency.standing_half_yearly_gender`);
    }

    return this.intl.t(`transfers.frequency.standing_monthly_gender`);
  }

  get isStandingOrder() {
    // @ts-expect-error
    let { request } = this.args;
    return (
      request.weekly || request.monthly || request.quarterly || request.halfYearly || request.yearly
    );
  }

  get requestIcon() {
    // @ts-expect-error
    if (this.args.request.once) {
      return 'feature_transfer_direct-l';
      // @ts-expect-error
    } else if (this.args.request.later) {
      return 'feature_transfer_scheduled-l';
    }
    return 'feature_transfer_recurring-l';
  }

  get _isStatusPrintable() {
    // @ts-expect-error
    let status = this.args.request.status;
    return ['approved', 'canceled', 'declined'].includes(status);
  }

  get requestHeaderTypeWording() {
    let { approver, initiator, _isStatusPrintable } = this;
    // @ts-expect-error
    let status = this.args.request.status;
    let name = this._membershipName(approver, initiator);

    if (_isStatusPrintable) {
      return this.intl.t(`requests.transfers.final_status.${status}`, {
        name,
      });
    }

    // @ts-expect-error
    if (this.args.request.later) {
      return this.intl.t('requests.sidebar.header.type.transfer_scheduled');
      // @ts-expect-error
    } else if (this.args.request.weekly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_weekly');
      // @ts-expect-error
    } else if (this.args.request.monthly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_monthly');
      // @ts-expect-error
    } else if (this.args.request.quarterly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_quarterly');
      // @ts-expect-error
    } else if (this.args.request.halfYearly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_half_yearly');
      // @ts-expect-error
    } else if (this.args.request.yearly) {
      return this.intl.t('requests.sidebar.header.type.transfer_standing_yearly');
    } else {
      return this.intl.t('requests.sidebar.header.type.transfer');
    }
  }

  // @ts-expect-error
  _formatMembershipName(value) {
    return `${value.get('firstName')?.substr(0, 1)}. ${value.get('lastName')}`;
  }

  // @ts-expect-error
  _membershipName(approver, initiator) {
    // @ts-expect-error
    let isRequestApproved = this.args.request.status === 'approved';
    let membership = isRequestApproved ? approver : initiator;

    return this._formatMembershipName(membership);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Transfer::Header': typeof RequestSidebarTransferHeaderComponent;
  }
}
