export default {
  "row": "cGH",
  "active": "cGO",
  "dot": "cGm",
  "red": "cGp",
  "green": "cGx",
  "yellow": "cGw",
  "status": "cGN",
  "align-right": "cGv",
  "animated": "cGo",
  "fadein-item": "cGn",
  "cell": "cGi body-2",
  "cell-content": "cGA",
  "amount": "cGY body-2",
  "subtitle": "cGh caption",
  "padding-left": "cGf",
  "padding-right": "cGK",
  "note": "cGG"
};
