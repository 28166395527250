/* import __COLOCATED_TEMPLATE__ from './cancel-invoice-modal-fr-org.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface CancelInvoiceModalFrOrgSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const CancelInvoiceModalFrOrg = templateOnlyComponent<CancelInvoiceModalFrOrgSignature>();

export default CancelInvoiceModalFrOrg;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CancelInvoiceModalFrOrg': typeof CancelInvoiceModalFrOrg;
  }
}
