export default {
  "row": "cAa",
  "empty": "cAH",
  "active": "cAO",
  "animated": "cAm",
  "fadein-item": "cAp",
  "cell": "cAx body-2",
  "cell-content": "cAw",
  "cell-amount": "cAN",
  "cell-content-amount": "cAv body-1"
};
