/* import __COLOCATED_TEMPLATE__ from './product-migration.hbs'; */
import Component from '@glimmer/component';

import { Badge } from '@repo/design-system-kit';

interface ReceivableInvoicesFormProductMigrationSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class ReceivableInvoicesFormProductMigrationComponent extends Component<ReceivableInvoicesFormProductMigrationSignature> {
  badge = Badge;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::ProductMigration': typeof ReceivableInvoicesFormProductMigrationComponent;
  }
}
