/* import __COLOCATED_TEMPLATE__ from './riba-attachment.hbs'; */
import Component from '@glimmer/component';

import { ErrorState } from 'qonto/react/components/transactions/sidebar/details/attachments/error-state';

interface RibaTaxAttachmentSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RibaTaxAttachment extends Component<RibaTaxAttachmentSignature> {
  errorState = ErrorState;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    RibaTaxAttachment: typeof RibaTaxAttachment;
  }
}
