/* import __COLOCATED_TEMPLATE__ from './fiscal-details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
// @ts-expect-error
import { cached, tracked } from '@glimmer/tracking';

import { AmountField } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

// @ts-expect-error
import ITALIAN_WELFARE_FUNDS from 'qonto/constants/it-welfare-fund';
// @ts-expect-error
import ITALIAN_WITHHOLDING_REASONS from 'qonto/constants/it-withholding-reason';
// @ts-expect-error
import ITALIAN_WITHHOLDING_TYPES from 'qonto/constants/it-withholding-type';
import { IRPF_ES_DEFAULT_RATE } from 'qonto/constants/receivable-invoice';
import { getSpanishWithholdingTaxRates } from 'qonto/utils/receivable-invoicing';

interface ReceivableInvoicesFormFiscalDetailsSignature {
  // The arguments accepted by the component
  Args: {
    isPensionDisabled?: boolean;
    isWithholdingTaxDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesFormFiscalDetailsComponent extends Component<ReceivableInvoicesFormFiscalDetailsSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];

  // @ts-expect-error
  @reads('organizationManager.organization.isItalian') isItalianOrganization;
  // @ts-expect-error
  @reads('organizationManager.organization.isSpanish') isSpanishOrganization;

  // @ts-expect-error
  @tracked hasPension = Boolean(this.args.document.welfareFund?.type || this.welfareFundRate);
  @tracked hasWithholdingTax = Boolean(
    // @ts-expect-error
    this.args.document.withholdingTax?.type ||
      this.withholdingTaxRate ||
      this.selectedWithholdingPaymentReason
  );
  @tracked hasStampRevenue =
    // @ts-expect-error
    Boolean(this.args.document.stampDutyAmount) && this.args.document.stampDutyAmount !== '0.00';

  amountField = AmountField;

  // @ts-expect-error
  tempWithholdingTaxRate;
  // @ts-expect-error
  tempWelfareFundRate;

  @cached
  get welfareFunds() {
    return Object.keys(ITALIAN_WELFARE_FUNDS).map(key => {
      return { key, label: `${key} - ${ITALIAN_WELFARE_FUNDS[key]}` };
    });
  }

  @cached
  get withholdingTypes() {
    return Object.keys(ITALIAN_WITHHOLDING_TYPES).map(key => {
      return { key, label: `${key} - ${ITALIAN_WITHHOLDING_TYPES[key]}` };
    });
  }

  @cached
  get withholdingReasons() {
    return Object.keys(ITALIAN_WITHHOLDING_REASONS).map(key => {
      return { key, label: `${key} - ${ITALIAN_WITHHOLDING_REASONS[key]}` };
    });
  }

  @cached
  get withholdingTaxRates() {
    return getSpanishWithholdingTaxRates(this.intl);
  }

  get selectedWelfareFund() {
    return this.welfareFunds.find(
      // @ts-expect-error
      welfareFund => welfareFund.key === this.args.document.welfareFund?.type
    );
  }

  get selectedWithholdingType() {
    return this.withholdingTypes.find(
      // @ts-expect-error
      withholdingType => withholdingType.key === this.args.document.withholdingTax?.type
    );
  }

  get selectedWithholdingPaymentReason() {
    return this.withholdingReasons.find(
      // @ts-expect-error
      withholdingReason => withholdingReason.key === this.args.document.withholdingTax?.reason
    );
  }

  get selectedWithholdingTaxRate() {
    return this.withholdingTaxRates.find(
      // @ts-expect-error
      withholdingTaxRate => withholdingTaxRate.value === this.args.document.withholdingTax?.rate
    );
  }

  get welfareFundRate() {
    // @ts-expect-error
    if (this.tempWelfareFundRate && this.args.document.welfareFund?.rate) {
      return this.tempWelfareFundRate;
      // @ts-expect-error
    } else if (this.args.document.welfareFund?.rate) {
      // @ts-expect-error
      return this._calculatePreciseRate(this.args.document.welfareFund?.rate);
    }
    return null;
  }

  get withholdingTaxRate() {
    // @ts-expect-error
    if (this.tempWithholdingTaxRate && this.args.document.withholdingTax?.rate) {
      return this.tempWithholdingTaxRate;
      // @ts-expect-error
    } else if (this.args.document.withholdingTax?.rate) {
      // @ts-expect-error
      return this._calculatePreciseRate(this.args.document.withholdingTax?.rate);
    }
    return null;
  }

  get welfareFundRateError() {
    // @ts-expect-error
    let error = this.args.document.welfareFund.get('errors.rate.0');

    switch (error?.message) {
      case 'required':
        return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
      case 'invalid':
        return this.intl.t('receivable-invoices.invoice-creation.fiscal-details.rate.error');
      default:
        return null;
    }
  }

  get withholdingTaxRateError() {
    // @ts-expect-error
    let error = this.args.document.withholdingTax.get('errors.rate.0');

    switch (error?.message) {
      case 'required':
        return this.intl.t('receivable-invoices.invoice-creation.errors.required-field');
      case 'invalid':
        return this.intl.t('receivable-invoices.invoice-creation.fiscal-details.rate.error');
      default:
        return null;
    }
  }

  // @ts-expect-error
  @action updateSelectedWithholdingTaxRate(rate) {
    // @ts-expect-error
    this.args.document.withholdingTax.rate = rate.value;
  }

  // @ts-expect-error
  @action updateWelfareFund({ key }) {
    // @ts-expect-error
    this.args.document.welfareFund.type = key;
  }

  // @ts-expect-error
  @action updateWithholdingType({ key }) {
    // @ts-expect-error
    this.args.document.withholdingTax.type = key;
  }

  // @ts-expect-error
  @action updateWithholdingPaymentReason({ key }) {
    // @ts-expect-error
    this.args.document.withholdingTax.reason = key;
  }

  // @ts-expect-error
  @action updateWelfareFundRate(rate) {
    this.tempWelfareFundRate = rate;
    let tempRate = parseFloat(rate.replace(',', '.')) / 100;
    if (tempRate >= 0) {
      // @ts-expect-error
      this.args.document.welfareFund.rate = parseFloat(tempRate.toFixed(4)).toString();
    } else {
      // @ts-expect-error
      this.args.document.welfareFund.rate = '0.00';
    }

    // @ts-expect-error
    if (this.args.document.welfareFund.rate > 1) {
      // @ts-expect-error
      this.args.document.welfareFund.errors.add('rate', 'invalid');
    }
  }

  // @ts-expect-error
  @action updateWithholdingTaxRate(rate) {
    if (this.isSpanishOrganization) {
      // @ts-expect-error
      this.args.document.withholdingTax.rate = rate.value;
    } else {
      this.tempWithholdingTaxRate = rate;
      let tempRate = parseFloat(rate.replace(',', '.')) / 100;
      if (tempRate >= 0) {
        // @ts-expect-error
        this.args.document.withholdingTax.rate = parseFloat(tempRate.toFixed(4)).toString();
      } else {
        // @ts-expect-error
        this.args.document.withholdingTax.rate = '0.00';
      }

      // @ts-expect-error
      if (this.args.document.withholdingTax.rate > 1) {
        // @ts-expect-error
        this.args.document.withholdingTax.errors.add('rate', 'invalid');
      }
    }
  }

  @action
  togglePension() {
    this.hasPension = !this.hasPension;
    if (!this.hasPension && !this.args.isPensionDisabled) {
      // @ts-expect-error
      this.args.document.welfareFund.rate = undefined;
      // @ts-expect-error
      this.args.document.welfareFund.type = undefined;
      this.tempWelfareFundRate = undefined;
    }
  }

  @action
  toggleWithholdingTax() {
    this.hasWithholdingTax = !this.hasWithholdingTax;
    if (!this.hasWithholdingTax && !this.args.isWithholdingTaxDisabled) {
      // @ts-expect-error
      this.args.document.withholdingTax.type = undefined;
      // @ts-expect-error
      this.args.document.withholdingTax.rate = undefined;
      this.tempWithholdingTaxRate = undefined;
      // @ts-expect-error
      this.args.document.withholdingTax.reason = undefined;
    }

    if (this.isSpanishOrganization && this.hasWithholdingTax) {
      // @ts-expect-error
      this.args.document.withholdingTax.type = '';
      // @ts-expect-error
      this.args.document.withholdingTax.rate =
        // @ts-expect-error
        this.args.lastWithholdingTaxRate || IRPF_ES_DEFAULT_RATE;
      // @ts-expect-error
      this.args.document.withholdingTax.reason = '';
    }
  }

  @action
  toggleStampRevenue() {
    this.hasStampRevenue = !this.hasStampRevenue;
    if (!this.hasStampRevenue) {
      // @ts-expect-error
      this.args.document.stampDutyAmount = '';
    }
  }

  @action
  // @ts-expect-error
  onAmountChange(amountValue) {
    // @ts-expect-error
    this.args.document.set('stampDutyAmount', amountValue);
  }

  /*
  This is required to avoid imprecise float multiplication
  Example: 0.14 * 100 = 14.000000000000002
  With this function we are scaling floats to integers, doing the multiplication,
  and transforming them back to floats
  */
  // @ts-expect-error
  _calculatePreciseRate(value) {
    value = value.toString();
    if (!value.includes('.')) {
      return value * 100;
    }

    let length = value.split('.')[1].length;

    value *= Math.pow(10, length);

    return (Math.trunc(value) * 100) / Math.pow(10, length);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::FiscalDetails': typeof ReceivableInvoicesFormFiscalDetailsComponent;
  }
}
