export default {
  "sections": "crT",
  "actions": "crz",
  "search-input": "cra",
  "no-upload": "crH",
  "new-link": "crO body-2",
  "between-links": "crm",
  "action-bar": "crp",
  "empty-state": "crx"
};
