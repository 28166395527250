/* import __COLOCATED_TEMPLATE__ from './payment-modal.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Disclaimer } from '@repo/design-system-kit';
import { CheckoutPanel, PaymentMethodSelectorToggle } from '@repo/domain-kit/invoices';
import { reads } from 'macro-decorators';

import { PAYMENT_LINK_METHODS, type PaymentLinkMethod } from 'qonto/constants/receivable-invoice';
import {
  PAYMENT_MODAL_TABS,
  PaymentModalTabs,
} from 'qonto/react/components/invoices/payment-modal';
import { TrackRender } from 'qonto/react/components/track-render';
import { PaymentMethods } from 'qonto/react/payment-links/components/payment-methods';

interface ReceivableInvoicesPaymentModalSignature {
  // The arguments accepted by the component
  Args: {
    paymentMethods: PaymentMethod[];
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

type PaymentMethod = {
  method: PaymentLinkMethod;
  enabled: boolean;
};

export default class ReceivableInvoicesPaymentModalComponent extends Component<ReceivableInvoicesPaymentModalSignature> {
  PaymentMethodSelectorToggle = PaymentMethodSelectorToggle;
  DisclaimerBlock: typeof Disclaimer.Block = Disclaimer.Block;
  TrackRender = TrackRender;
  PaymentModalTabs = PaymentModalTabs;
  CheckoutPanel = CheckoutPanel;
  PaymentMethods = PaymentMethods;

  @service declare intl: Services['intl'];
  @service declare mollie: Services['mollie'];
  @service declare organizationManager: Services['organizationManager'];

  @tracked activeTabId = PAYMENT_MODAL_TABS.PAYMENT_LINK;
  // @ts-expect-error
  @reads('organizationManager.organization.activeSortedAccounts') bankAccounts;

  get enabledPaymentMethods() {
    if (this.mollie.isPending || this.mollie.isDisabled) {
      return this.args.paymentMethods.filter(
        ({ method }) => method === PAYMENT_LINK_METHODS.BANK_TRANSFER
      );
    }
    return this.args.paymentMethods.filter(({ enabled }) => enabled);
  }

  get someMethodsDisabled() {
    return this.enabledPaymentMethods.length < this.args.paymentMethods.length;
  }

  get selectedPaymentMethods() {
    let shouldShowAllMethods = this.mollie.isNotConnected;
    let shouldShowBankTransferOnly = // @ts-expect-error
      !this.args.invoice.paymentLink?.methods || this.mollie.isPending || this.mollie.isDisabled;

    if (shouldShowBankTransferOnly) {
      return [PAYMENT_LINK_METHODS.BANK_TRANSFER];
    }

    if (shouldShowAllMethods) {
      return Object.values(PAYMENT_LINK_METHODS);
    }

    // @ts-expect-error
    return this.args.invoice.paymentLink?.methods || [];
  }

  get paymentLinkTabIsActive() {
    return this.activeTabId === PAYMENT_MODAL_TABS.PAYMENT_LINK;
  }

  get bankAccountDropdownIsReadOnly() {
    return this.mollie.isEnabled;
  }

  get locales() {
    // @ts-expect-error
    return this.intl.getLocales.map(({ code, name }) => ({ id: code, label: name }));
  }

  @action
  // @ts-expect-error
  changeTab(newTabId) {
    this.activeTabId = newTabId;
  }

  @action
  // @ts-expect-error
  toggleMethod(method) {
    let newMethods = this.selectedPaymentMethods;
    if (this.isMethodSelected(method)) {
      // @ts-expect-error
      newMethods = newMethods.filter(m => m !== method);
    } else {
      newMethods.push(method);
    }

    // @ts-expect-error
    this.args.invoice.paymentLink = {
      // @ts-expect-error
      ...this.args.invoice.paymentLink,
      methods: this.args.paymentMethods
        .filter(({ method }) => newMethods.includes(method))
        .map(({ method }) => method),
    };
  }

  @action
  // @ts-expect-error
  onBankAccountSelect(bankAccount) {
    // @ts-expect-error
    this.args.invoice.bankAccount = bankAccount;
    // @ts-expect-error
    this.args.invoice.iban = bankAccount?.iban;
    // @ts-expect-error
    this.args.invoice.bic = bankAccount?.bic;
    // @ts-expect-error
    this.args.invoice.beneficiaryName = this.organizationManager.organization.name;
  }

  // @ts-expect-error
  isMethodSelected = paymentMethod => this.selectedPaymentMethods.includes(paymentMethod);
  // @ts-expect-error
  isBankTransfer = paymentMethod => paymentMethod === PAYMENT_LINK_METHODS.BANK_TRANSFER;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::PaymentModal': typeof ReceivableInvoicesPaymentModalComponent;
  }
}
