export default {
  "row": "cpk",
  "cell": "cpJ",
  "greyed-out": "cps",
  "struck-through": "cpF",
  "flex-cell": "cpg",
  "details-container": "cpD",
  "overdue": "cpV",
  "align-right": "cpL",
  "customer-name-container": "cpT",
  "icon": "cpz",
  "warning": "cpa",
  "with-separator": "cpH",
  "align-center": "cpO"
};
