/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ReimbursementsRequestsTableSignature {
  // The arguments accepted by the component
  Args: {
    isSidepanelOpen?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReimbursementsRequestsTable extends Component<ReimbursementsRequestsTableSignature> {
  @action
  // @ts-expect-error
  handleKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      // @ts-expect-error
      this.args.changeRow(event);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Requests::Table': typeof ReimbursementsRequestsTable;
  }
}
