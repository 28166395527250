export default {
  "card-details-container": "cAr",
  "title": "cAb caption-bold mb-16",
  "section": "cAy",
  "divider": "cAU",
  "top-divider": "cAj",
  "content": "cAW body-2",
  "description": "cAu",
  "justify-end": "cYS",
  "icon": "cYc ml-8"
};
