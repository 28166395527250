/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface HeaderSignature {
  Element: HTMLTableRowElement;
  Args: {
    isQuote?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const Header = templateOnlyComponent<HeaderSignature>();

export default Header;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Table::Header': typeof Header;
  }
}
