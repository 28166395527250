/* import __COLOCATED_TEMPLATE__ from './beneficiary-iban.hbs'; */
import { guidFor } from '@ember/object/internals';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, rawTimeout, task } from 'ember-concurrency';

interface RequestTransferBeneficiaryIbanSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestTransferBeneficiaryIbanComponent extends Component<RequestTransferBeneficiaryIbanSignature> {
  @service declare intl: Services['intl'];

  ibanInputId = `${guidFor(this)}-iban-input`;

  get errorMessage() {
    // @ts-expect-error
    let { enableValidations, transfer } = this.args;

    if (enableValidations) {
      let message = transfer.validations.attrs.iban?.message;
      if (message === 'invalid_iban') {
        return this.intl.t('validations.errors.invalid_iban');
      }
      if (message === 'unsupported_identifier_country') {
        return this.intl.t('transfers.errors.unsupported-iban-country');
      }
      return message || transfer.errors.errorsFor('beneficiaryIban')[0]?.message;
    }
    return null;
  }

  validateIbanTask = dropTask(async () => {
    // @ts-expect-error
    if (this.args.validateTask) await this.args.validateTask.perform('iban');
  });

  clearBeneficiaryTask = task(async () => {
    // @ts-expect-error
    // eslint-disable-next-line ember/no-array-prototype-extensions
    this.args.clear();
    // needed to remove automatic focus on the IBAN input after the "clear" CTA is removed
    await rawTimeout(0);
    // @ts-expect-error
    document.querySelector(`#${this.ibanInputId} input`).blur();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Transfer::BeneficiaryIban': typeof RequestTransferBeneficiaryIbanComponent;
  }
}
