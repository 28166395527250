export default {
  "credit-note-sidebar": "cTM",
  "body": "cTP",
  "box": "cTl",
  "box-header": "cTX",
  "footer": "cTC",
  "footer-button": "cTk",
  "sidebar-box": "cTJ",
  "border-top": "cTs",
  "row": "cTF",
  "related-invoice-title": "cTg caption-bold",
  "einvoice-rejected": "cTD",
  "related-documents": "cTV"
};
