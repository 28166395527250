export default {
  "attachment-viewer": "cvQ",
  "hide-sidebar": "cve",
  "sidebar": "cvB",
  "header": "cvE",
  "header-top": "cvd",
  "header-icon": "cvI",
  "header-amount": "cvt",
  "infobox": "cvM",
  "vat": "cvP",
  "validation": "cvl"
};
