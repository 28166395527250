/* import __COLOCATED_TEMPLATE__ from './decline.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { TextAreaField } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface ReimbursementsBulkModalsDeclineSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReimbursementsBulkModalsDeclineComponent extends Component<ReimbursementsBulkModalsDeclineSignature> {
  @service declare errors: Services['errors'];
  @service declare intl: Services['intl'];

  @tracked hasEmptyDeclinedNoteError = false;
  // @ts-expect-error
  @tracked declinedNote;

  textAreaField = TextAreaField;

  get subtitle() {
    // @ts-expect-error
    let { requests } = this.args.data;

    let uniqueInitiators = [
      // @ts-expect-error
      ...new Set(requests.map(request => request.get('initiator.membershipId'))),
    ];
    return uniqueInitiators.length > 1
      ? this.intl.t('requests.modals.decline-request.subtitle-multiple-requesters')
      : this.intl.t('requests.modals.decline-request.subtitle-single-requester', {
          count: requests.length,
        });
  }

  approveTask = dropTask(async () => {
    this.hasEmptyDeclinedNoteError = false;
    let {
      // @ts-expect-error
      close,
      // @ts-expect-error
      data: { approveTask, requests, selector },
    } = this.args;

    if (!this.declinedNote) {
      return (this.hasEmptyDeclinedNoteError = true);
    }

    try {
      if (approveTask) {
        await approveTask.linked().perform(close, requests, this.declinedNote, selector);
      } else {
        close('approve');
      }
    } catch (error) {
      this.errors.handleError(error);
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Bulk::Modals::Decline': typeof ReimbursementsBulkModalsDeclineComponent;
  }
}
