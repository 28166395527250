/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
import Component from '@glimmer/component';

import { RequestSidebarCardHeader } from 'qonto/react/cards/components/request/sidebar/card/header';

interface CardSignature {
  Element: HTMLDivElement;
  Args: {};
  Blocks: {
    default: [];
  };
}

export default class Card extends Component<CardSignature> {
  requestSidebarCardHeader = RequestSidebarCardHeader;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Card': typeof Card;
  }
}
