export default {
  "header-cell": "cpe",
  "empty": "cpB",
  "header-content": "cpE caption-bold",
  "active": "cpd",
  "align-right": "cpI",
  "col-8": "cpt",
  "col-7": "cpM",
  "col-5": "cpP",
  "col-4": "cpl"
};
