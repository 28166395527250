/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { EINVOICING_STATUS } from 'qonto/constants/receivable-invoice';
import { RelatedDocument } from 'qonto/react/receivable-invoices/components/related-document';

interface ReceivableInvoicesCreditNoteModalSidebarSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLElement;
}

export default class ReceivableInvoicesCreditNoteModalSidebarComponent extends Component<ReceivableInvoicesCreditNoteModalSidebarSignature> {
  @service declare intl: Services['intl'];
  @service declare organizationManager: Services['organizationManager'];
  @service declare router: Services['router'];
  @service declare segment: Services['segment'];

  @tracked document: any;

  relatedDocument = RelatedDocument;

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.prepareDocument();
  }

  async prepareDocument() {
    // @ts-expect-error
    this.document = await this.args.creditNote.belongsTo('receivableInvoice').load();
  }

  get customer() {
    // @ts-expect-error
    return this.args.creditNote.receivableInvoice.get('customerSnapshot');
  }

  get currency() {
    // @ts-expect-error
    return this.args.creditNote.currency || 'EUR';
  }

  get billingAddress() {
    let billingAddress = this.customer?.billingAddress;
    if (billingAddress) {
      return billingAddress.streetAddress;
    }
    return this.customer.address;
  }

  get cityAddress() {
    let customer = this.customer?.billingAddress ? this.customer?.billingAddress : this.customer;
    if (customer.countryCode) {
      // @ts-expect-error
      let country = this.intl.formatCountry(customer.countryCode);
      return `${customer.zipCode} ${customer.city}, ${country}`;
    }
    return `${customer.zipCode} ${customer.city}`;
  }

  get deliveryCityAddress() {
    if (this.customer.deliveryAddress?.countryCode) {
      // @ts-expect-error
      let country = this.intl.formatCountry(this.customer.deliveryAddress.countryCode);
      return `${this.customer.deliveryAddress.zipCode} ${this.customer.deliveryAddress.city}, ${country}`;
    }
    return `${this.customer.deliveryAddress.zipCode} ${this.customer.deliveryAddress.city}`;
  }

  get isEInvoicing() {
    return this.organizationManager.organization.legalCountry === 'IT';
  }

  get isEInvoicingStatusError() {
    // @ts-expect-error
    let { einvoicingStatus } = this.args.creditNote;
    return [EINVOICING_STATUS.SUBMISSION_FAILED, EINVOICING_STATUS.REJECTED].includes(
      einvoicingStatus
    );
  }

  @action
  goToShareCreditNote() {
    this.segment.track('credit-note_options_send-credit-note');
    // @ts-expect-error
    this.router.transitionTo('receivable-invoices.credit-notes.share', this.args.creditNote.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::CreditNoteModal::Sidebar': typeof ReceivableInvoicesCreditNoteModalSidebarComponent;
  }
}
