export default {
  "section-container": "coI",
  "disclaimer-section": "cot",
  "trip-title": "coM caption-bold mb-16",
  "google-link-container": "coP",
  "link-icon": "col",
  "trip-details": "coX body-2",
  "trip-details-icon": "coC",
  "trip-details-title": "cok",
  "subtitle": "coJ caption-bold mb-16",
  "disclaimer": "cos",
  "visualizer": "coF"
};
