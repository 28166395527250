export default {
  "container": "cal",
  "title-and-trash": "caX",
  "trash": "caC",
  "quantity-amount": "cak",
  "flex-row": "caJ",
  "quantity": "cas",
  "quantityInput": "caF",
  "border-left": "cag",
  "dropdown": "caD",
  "item-row": "caV",
  "unit-price": "caL",
  "price-input": "caT",
  "hidden": "caz",
  "d-none": "caa",
  "dropdown-option": "caH",
  "list-row": "caO",
  "row-title": "cam",
  "row-price": "cap body-2",
  "row-missing-details-container": "cax",
  "row-missing-details-label": "caw body-2",
  "warning-icon": "caN",
  "row-internal-note": "cav",
  "with-internal-note": "cao",
  "dropdown-content": "can",
  "select-item": "cai"
};
