/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

import { STATUS } from 'qonto/constants/transfers';

interface RequestSidebarTransferDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarTransferDetailsComponent extends Component<RequestSidebarTransferDetailsSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;

  @service declare modals: Services['modals'];
  @service declare abilities: Services['abilities'];
  @service declare segment: Services['segment'];

  get showDisclaimer() {
    // @ts-expect-error
    let { request, spendLimitsWarning } = this.args;
    let userId = request.get('initiator.userId');

    let isPending = request.status === STATUS.PENDING;
    let isSpendLimitCase =
      spendLimitsWarning &&
      this.abilities.can('view own request info disclaimer transfer-limit', request);

    return (
      isPending &&
      (this.abilities.can('cancel transfer request', userId) || isSpendLimitCase) &&
      this.abilities.cannot('see state approval-workflow')
    );
  }

  // @ts-expect-error
  downloadAttachment(model, file) {
    // @ts-expect-error
    let attachment = model.attachments.find(a =>
      file.id ? a.file.id === file.id : file.fileUrl.includes(a.id)
    );
    window.open(attachment.downloadUrl);
  }

  @action
  // @ts-expect-error
  handleOpenFilePreviewModal(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: false,
      selectedFile: file,
      // @ts-expect-error
      model: this.args.request,
      download: this.downloadAttachment,
    });
  }

  get shouldDisplayApprovalWorkflowState() {
    // @ts-expect-error
    let { approvalWorkflowState, canceled } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return (
      this.abilities.can('see state approval-workflow') &&
      approvalWorkflowState &&
      steps &&
      conditions &&
      !canceled
    );
  }

  get approvalWorkflowState() {
    // @ts-expect-error
    let { approvalWorkflowState } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return {
      id: approvalWorkflowState.id,
      conditions,
      steps,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Transfer::Details': typeof RequestSidebarTransferDetailsComponent;
  }
}
