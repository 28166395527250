/* import __COLOCATED_TEMPLATE__ from './review-duplicates-modal.hbs'; */
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { TrackedSet } from 'tracked-built-ins';

import { apiBaseURL } from 'qonto/constants/hosts';
import { ItemSkeleton } from 'qonto/react/components/review-duplicates-modal/item-skeleton';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

interface ReviewDuplicatesModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReviewDuplicatesModal extends Component<ReviewDuplicatesModalSignature> {
  @service declare sentry: Services['sentry'];
  @service declare networkManager: Services['networkManager'];
  @service declare intl: Services['intl'];
  @service declare router: Services['router'];
  @service declare store: Services['store'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare modals: Services['modals'];
  @service declare segment: Services['segment'];

  @tracked showEmptySelectionError = false;
  selected = new TrackedSet([]);
  itemSkeleton = ItemSkeleton;

  constructor(owner: unknown, args: ReviewDuplicatesModalSignature['Args']) {
    super(owner, args);

    this.fetchDuplicatesTask
      .perform()
      .then(() => this.preselectDuplicates())
      .catch(ignoreCancelation);
  }

  get currentDocument() {
    // @ts-expect-error
    let { invoiceId } = this.args.data;
    return this.store.peekRecord('receivable-invoice', invoiceId);
  }

  get duplicates() {
    return this.fetchDuplicatesTask.lastSuccessful?.value;
  }

  get isFetchError() {
    return this.fetchDuplicatesTask.last?.isError;
  }

  @action
  // @ts-expect-error
  isSelected(id) {
    // @ts-expect-error
    return this.selected.has(id);
  }

  @action
  // @ts-expect-error
  updateSelection(id) {
    // @ts-expect-error
    let fn = this.selected.has(id) ? 'delete' : 'add';
    // @ts-expect-error
    this.selected[fn](id);
  }

  preselectDuplicates() {
    // @ts-expect-error
    this.duplicates.forEach(e => {
      // @ts-expect-error
      this.selected.add(e.id);
    });
  }

  fetchDuplicatesTask = dropTask(async () => {
    // @ts-expect-error
    let { invoiceId } = this.args.data;

    let duplicatesIds = await this.store
      // @ts-expect-error
      .adapterFor('receivable-invoice')
      // @ts-expect-error
      .fetchDuplicates(invoiceId);

    // @ts-expect-error
    let duplicates = [];
    // @ts-expect-error
    await duplicatesIds.forEach(id => {
      duplicates.push(this.store.peekRecord('receivable-invoice', id));
    });
    // @ts-expect-error
    return duplicates;
  });

  confirmDeleteSelected = dropTask(async () => {
    if (this.selected.size === 0) {
      this.showEmptySelectionError = true;
      next(() => scrollIntoView('[data-has-error]'));
      return;
    }
    this.showEmptySelectionError = false;
    await this.modals.open('popup/destructive', {
      title: this.intl.t('receivable-invoices.modals.review-duplicates.confirm-deletion.title', {
        count: this.selected.size,
      }),
      description: this.intl.t(
        'receivable-invoices.modals.review-duplicates.confirm-deletion.subtitle',
        {
          count: this.selected.size,
        }
      ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete', { count: this.selected.size }),
      confirmTask: this.deleteDuplicatesTask,
    });
  });

  #trackDeleteEvent() {
    // @ts-expect-error
    let listOfDeletableIds = [...this.duplicates, this.currentDocument].map(({ id }) => id);

    // @ts-expect-error
    let remainingDeletableInvoices = listOfDeletableIds.filter(id => !this.selected.has(id)).length;

    let keptInvoices;

    if (remainingDeletableInvoices === 1) {
      keptInvoices = 'one';
    } else if (remainingDeletableInvoices > 1) {
      keptInvoices = 'multiple';
    } else {
      keptInvoices = 'none';
    }

    this.segment.track('invoice_imported_duplicates_deleted', { keptInvoices });
  }

  deleteDuplicatesTask = dropTask(async closeConfirmation => {
    this.#trackDeleteEvent();
    try {
      let ids = Array.from(this.selected).toString();
      await this.networkManager.request(
        `${apiBaseURL}/v4/receivable_invoices/invoices?ids=${ids}`,
        {
          method: 'DELETE',
        }
      );

      // @ts-expect-error
      let deletedCurrentDocument = this.selected.has(this.currentDocument.id);
      let deletedAllDuplicates =
        // @ts-expect-error
        !deletedCurrentDocument && this.duplicates.every(({ id }) => this.selected.has(id));

      if (deletedAllDuplicates) {
        let receivableInvoice = this.store.peekRecord(
          'receivable-invoice',
          // @ts-expect-error
          this.currentDocument.id
        );
        // @ts-expect-error
        receivableInvoice.hasDuplicates = false;
      }

      if (deletedCurrentDocument) {
        this.router.transitionTo('receivable-invoices.index');
      }

      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.modals.review-duplicates.confirm-deletion.success-toast', {
          count: this.selected.size,
        })
      );
      await closeConfirmation();
      // @ts-expect-error
      this.args?.close();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      await closeConfirmation();
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      // @ts-expect-error
      if (errorInfo.shouldSendToSentry && error.status !== 422) {
        this.sentry.captureException(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::ReviewDuplicatesModal': typeof ReviewDuplicatesModal;
  }
}
