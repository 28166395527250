/* import __COLOCATED_TEMPLATE__ from './products-and-services.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

import CURRENCIES from 'qonto/constants/currencies';
import type InvoiceSubscriptionModel from 'qonto/models/invoice-subscription';
import type QuoteModel from 'qonto/models/quote';
import type ReceivableInvoiceModel from 'qonto/models/receivable-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';

interface InvoiceSubscriptionsFormProductsAndServicesSignature {
  // The arguments accepted by the component
  Args: {
    document: ReceivableInvoiceModel | QuoteModel | InvoiceSubscriptionModel;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class InvoiceSubscriptionsFormProductsAndServicesComponent extends Component<InvoiceSubscriptionsFormProductsAndServicesSignature> {
  @service declare organizationManager: Services['organizationManager'];
  @service declare productsCatalog: Services['productsCatalog'];
  @service declare modals: Services['modals'];
  @service declare intl: Services['intl'];
  @service declare segment: Services['segment'];

  // @ts-expect-error
  @reads('organizationManager.organization') organization;
  // @ts-expect-error
  @reads('args.document.currency', CURRENCIES.default) currency;

  @tracked isVatResetDisclaimerDismissed = false;

  get isVatDisclaimerShown() {
    if (this.isVatResetDisclaimerDismissed) return false;

    let { legalCountry } = this.organization;
    let customerCountry = this.args.document.customer?.get('billingAddress.countryCode');

    return customerCountry && customerCountry !== legalCountry;
  }

  @action
  // @ts-expect-error
  addItem(invoicingDocument) {
    let customerAddress = invoicingDocument.customer?.get('billingAddress.countryCode');

    invoicingDocument.items.createRecord(
      defaultValues(
        this,
        invoicingDocument.items[0]?.vatRate,
        invoicingDocument.items[0]?.vatExemptionCode
      )
    );

    // fill the current new item unit with the last item's unit
    let currentIndex = invoicingDocument.items.length - 1;
    invoicingDocument.items[currentIndex].unit = invoicingDocument.items[currentIndex - 1]?.unit;

    if (customerAddress && customerAddress !== this.organization.legalCountry) {
      invoicingDocument.items[currentIndex].resetVat();
    }
  }

  @action
  // @ts-expect-error
  deleteItem(item) {
    if (item.isNew) {
      item.deleteRecord();
    } else {
      item.unloadRecord();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::Form::ProductsAndServices': typeof InvoiceSubscriptionsFormProductsAndServicesComponent;
  }
}
