export default {
  "row": "cyM",
  "row-active": "cyP",
  "avatar": "cyl",
  "cell": "cyX",
  "cell-content": "cyC",
  "cell-counterparty": "cyk",
  "counterparty-name": "cyJ",
  "cell-amount": "cys",
  "status-avatar": "cyF",
  "counterparty": "cyg",
  "creditor-name": "cyD",
  "reference": "cyV",
  "counterparty-details": "cyL",
  "cell-quick-actions": "cyT",
  "quick-actions": "cyz",
  "account-selector": "cya"
};
