/* import __COLOCATED_TEMPLATE__ from './delete-draft-modal.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface DeleteDraftModalSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const DeleteDraftModal = templateOnlyComponent<DeleteDraftModalSignature>();

export default DeleteDraftModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::DeleteDraftModal': typeof DeleteDraftModal;
  }
}
