export default {
  "payment-details": "caA",
  "label-payment-conditions": "caY",
  "dropdown-content": "cah",
  "dropdown-option": "caf",
  "activate-sdd-container": "caK",
  "activate-sdd-header": "caG",
  "selector-toggle-sdd": "car",
  "selector-toggle-sdd-subtitle": "cab",
  "selector-toggle-content": "cay",
  "selector-toggle": "caU",
  "new-badge": "caj",
  "new-badge-activation": "caW",
  "mt-16": "cau",
  "sdd-mandate-select-loading": "cHS"
};
