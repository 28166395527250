/* import __COLOCATED_TEMPLATE__ from './mileage.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';

interface MileageSignature {
  Element: null;
  Args: {};
  Blocks: {
    default: [];
  };
}

const Mileage = templateOnlyComponent<MileageSignature>();

export default Mileage;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Requests::Table::Cell::Mileage': typeof Mileage;
  }
}
