/* import __COLOCATED_TEMPLATE__ from './sidebar-shared.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { dropTask, race, rawTimeout } from 'ember-concurrency';

// @ts-expect-error
import { RECEIVABLE_INVOICES_EVENTS } from 'qonto/constants/listeners';
// @ts-expect-error
import { QUOTE_STATUS_LEVELS } from 'qonto/constants/quotes';
import {
  INVOICING_STATUS_LEVELS,
  SENTRY_IGNORE_HTTP_STATUSES,
  WEBSOCKET_TIMEOUT_MS,
} from 'qonto/constants/receivable-invoice';
// @ts-expect-error
import { ErrorInfo } from 'qonto/utils/error-info';

interface ReceivableInvoicesSidebarSharedSignature {
  // The arguments accepted by the component
  Args: {
    isQuote?: boolean;
    isImported?: boolean;
    isGenerateInvoiceDisabled?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesSidebarSharedComponent extends Component<ReceivableInvoicesSidebarSharedSignature> {
  @service declare modals: Services['modals'];
  @service declare notifierManager: Services['notifierManager'];
  @service declare sentry: Services['sentry'];
  @service declare segment: Services['segment'];
  @service declare intl: Services['intl'];
  @service declare store: Services['store'];
  @service declare flowLinkManager: Services['flowLinkManager'];
  @service declare toastFlashMessages: Services['toastFlashMessages'];

  get currency() {
    // @ts-expect-error
    return this.args.invoicingDocument.currency || 'EUR';
  }

  get isQuote() {
    return this.args.isQuote;
  }

  get invoicingDocument() {
    // @ts-expect-error
    return this.args.invoicingDocument;
  }

  get isImported() {
    // @ts-expect-error
    return this.args.invoicingDocument.imported;
  }

  get status() {
    let { status, displayedStatus } = this.invoicingDocument;

    return {
      text: displayedStatus ? displayedStatus : status,
      // @ts-expect-error
      level: this.isQuote ? QUOTE_STATUS_LEVELS[status] : INVOICING_STATUS_LEVELS[status],
    };
  }

  get isPending() {
    return this.invoicingDocument.status === 'pending_approval';
  }

  get isExpiringSoon() {
    return this.isPending && this.invoicingDocument?.isCloseToExpire;
  }

  get showQuoteDisclaimer() {
    return this.isExpiringSoon || this.isExpired;
  }

  get showMatchedTransactions() {
    return this.invoicingDocument.status === 'paid';
  }

  get quoteDisclaimer() {
    if (this.isExpired) {
      return this.intl.t('receivable-invoices.invoice-modal.quotes.expired-disclaimer');
    }
    return this.intl.t('receivable-invoices.invoice-modal.quotes.expiration-disclaimer', {
      number: this.invoicingDocument.remainingDaysUntilExpiry,
    });
  }

  get isExpired() {
    return this.isPending && this.invoicingDocument?.remainingDaysUntilExpiry === 0;
  }

  get showRedWarning() {
    return this.isExpired || (this.invoicingDocument?.overdue && this.invoicingDocument.dueDate);
  }

  @action openMarkAsPaidFlow() {
    /**
     * @todo This logic should be moved to the flow setup once it is possible to abort the transition
     * @see https://www.notion.so/qonto/Allow-flows-to-abort-a-transition-into-the-flows-route-b657347ce54a49d686e410ede0920132
     */
    let hasAttachment = Boolean(this.invoicingDocument?.belongsTo('attachment').id());
    if (!hasAttachment) {
      this.sentry.captureMessage(`Invoice ${this.invoicingDocument.id} is missing an attachment.`);
      return this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }

    this.segment.track(
      this.isImported
        ? 'invoice_imported_mark-as-paid_clicked'
        : 'receivable_invoice_linked_mark_as_paid'
    );

    this.flowLinkManager.transitionTo({
      name: 'match-invoice',
      stepId: 'select-transaction',
      queryParams: { trigger: 'receivableInvoices', invoiceId: this.invoicingDocument.id },
    });
  }

  openMarkAsUnpaidModal = dropTask(async () => {
    await this.modals.open('popup/confirmation', {
      title: this.intl.t('receivable-invoices.invoice-modal.mark-as-unpaid-modal.title'),
      description: this.intl.t(
        'receivable-invoices.invoice-modal.mark-as-unpaid-modal.description'
      ),
      confirm: this.intl.t('receivable-invoices.invoice-modal.mark-as-unpaid-modal.cta'),
      cancel: this.intl.t('btn.cancel'),
      confirmTask: this.markAsUnpaidTask,
    });
  });

  markAsUnpaidTask = dropTask(async close => {
    try {
      await this.invoicingDocument.markAsUnpaid();
      await close();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoice-modal.success.marked-as-unpaid')
      );

      if (this.isImported) {
        this.segment.track('invoice_imported_mark-as-to-be-paid_clicked');
      } else {
        this.segment.track('receivable-invoice_status_mark-as-unpaid', {
          source: 'CTA',
        });
      }
    } catch (error) {
      this.handleError(error);
    }
  });

  onUnlinkLastTransactionTask = dropTask(async () => {
    // @ts-expect-error
    let isInvoiceUpdated = this.notifierManager.waitForEventTask.perform(
      RECEIVABLE_INVOICES_EVENTS.UNPAID
    );
    let result = await race([isInvoiceUpdated, rawTimeout(WEBSOCKET_TIMEOUT_MS)]);

    if (result?.event === RECEIVABLE_INVOICES_EVENTS.UNPAID) {
      this.invoicingDocument.rollbackAttributes();
      let { object_id, object_type, object } = result.payload;
      this.store.pushPayload('receivable-invoice', {
        data: {
          id: object_id,
          type: object_type,
          attributes: object,
        },
      });
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoice-modal.success.unlinked-last-transaction')
      );

      this.segment.track(
        this.isImported
          ? 'receivable-invoice_imported_remove-transaction_clicked'
          : 'receivable-invoice_status_mark-as-unpaid',
        {
          source: 'matched-transaction',
        }
      );
    }
  });

  // @ts-expect-error
  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::InvoiceModal::Sidebar::SidebarShared': typeof ReceivableInvoicesSidebarSharedComponent;
  }
}
