/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { action } from '@ember/object';
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Disclaimer } from '@repo/design-system-kit';

import { STATUS } from 'qonto/constants/transfers';

interface RequestSidebarExpenseReportDetailsSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarExpenseReportDetailsComponent extends Component<RequestSidebarExpenseReportDetailsSignature> {
  disclaimerInline: typeof Disclaimer.Inline = Disclaimer.Inline;
  @service declare intl: Services['intl'];
  @service declare modals: Services['modals'];
  @service declare abilities: Services['abilities'];
  @service declare attachmentsManager: Services['attachmentsManager'];

  modal = null;

  get showDisclaimer() {
    // @ts-expect-error
    let { request, spendLimitsWarning } = this.args;
    let userId = request.get('initiator.userId');
    let isSpendLimitCase = Boolean(
      spendLimitsWarning &&
        this.abilities.can('view own request info disclaimer transfer-limit', request)
    );

    return (
      request.status === STATUS.PENDING &&
      (this.abilities.can('cancel expense report request', userId) || isSpendLimitCase) &&
      this.abilities.cannot('see state approval-workflow')
    );
  }

  @action
  // @ts-expect-error
  handleOpenFilePreviewModal(file) {
    // @ts-expect-error
    this.modal = this.modals.open(
      'request/sidebar/expense-report/attachment-modal',
      {
        // @ts-expect-error
        model: this.args.request,
        selectedFile: file,
        hasSidebar:
          // @ts-expect-error
          this.args.request.status === STATUS.PENDING &&
          this.abilities.can('review expense report request'),
        onDownload: this.attachmentsManager.downloadAttachment,
        // @ts-expect-error
        decline: this.args.decline,
        // @ts-expect-error
        approve: this.args.approve,
        // @ts-expect-error
        spendLimits: this.args.spendLimits,
        // @ts-expect-error
        setAccount: this.args.setAccount,
        // @ts-expect-error
        close: this.args.close,
        // @ts-expect-error
        canShowAccountSelector: this.args.canShowAccountSelector,
        // We need to pass the args here to keep the reactivity
        // of the errors and warnings generated by the setAccount callback
        parentArgs: this.args,
        // @ts-expect-error
        origin: this.args.origin,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: true,
        },
      }
    );
  }

  willDestroy() {
    // @ts-expect-error
    super.willDestroy(...arguments);

    // @ts-expect-error
    this.modal?.close();
  }

  get shouldDisplayApprovalWorkflowState() {
    // @ts-expect-error
    let { approvalWorkflowState, canceled } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return (
      this.abilities.can('see state approval-workflow') &&
      approvalWorkflowState &&
      steps &&
      conditions &&
      !canceled
    );
  }

  get approvalWorkflowState() {
    // @ts-expect-error
    let { approvalWorkflowState } = this.args.request;
    let steps = approvalWorkflowState?.get('steps');
    let conditions = approvalWorkflowState?.get('conditions');

    return {
      id: approvalWorkflowState.id,
      conditions,
      steps,
    };
  }

  get attachmentsLabel() {
    if (this.isRequestBankless) {
      return this.intl.t('labels.receipt');
    }

    return this.intl.t('labels.attachment');
  }

  get isRequestBankless() {
    // @ts-expect-error
    return this.args.request.bankless;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::ExpenseReport::Details': typeof RequestSidebarExpenseReportDetailsComponent;
  }
}
