/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';

// @ts-expect-error
import scrollIntoView from 'qonto/utils/scroll-into-view';

const TEXT_NODE_TYPE = 3;

const INPUT_ELEMENT_ID = 'mentionable-input';
const MENTION_ELEMENT_ID = 'mention';

interface QontoPilotMentionableInputSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class QontoPilotMentionableInputComponent extends Component<QontoPilotMentionableInputSignature> {
  @tracked mentionTagLeftPosition = 0;

  @tracked searchOption = '';
  @tracked isEditingMention = false;

  @tracked selectedOptionIndex = 0;

  // @ts-expect-error
  @tracked placeholder = this.args.placeholder;
  // @ts-expect-error
  @tracked options = this.args.options;
  // @ts-expect-error
  @tracked mentionTrigger = this.args.mentionTrigger;
  // @ts-expect-error
  @tracked onSubmit = this.args.onSubmit;
  // @ts-expect-error
  @tracked onKeyDown = this.args.onKeyDown;

  INPUT_ELEMENT_ID = INPUT_ELEMENT_ID;

  constructor() {
    // @ts-expect-error
    super(...arguments);
    schedule('afterRender', () => {
      document.getElementById(this.INPUT_ELEMENT_ID)?.focus();
    });
  }

  get filteredOptions() {
    let searchTerm = this.searchOption.toLowerCase();
    // @ts-expect-error
    return this.options.filter(o => !searchTerm || o.name.toLowerCase().includes(searchTerm));
  }

  @action
  // @ts-expect-error
  onKey(event) {
    let selection = window.getSelection();

    if (event.key === 'Backspace') {
      if (this.mentionableInputValue.trim() === '') {
        event.preventDefault();
        event.target.innerHTML = '';
        this.isEditingMention = false;
        return;
      }
    }

    // @ts-expect-error
    let range = selection.getRangeAt(0);
    let node = range.startContainer;
    let offset = range.startOffset;

    // Select the closest text node
    // @ts-expect-error
    while (node && node.nodeType === TEXT_NODE_TYPE) node = node.parentElement;

    // If the user is editing the option we set it to true and modify the value of the search option
    // @ts-expect-error
    if (node && node.tagName === 'SPAN') {
      if (event.key === 'ArrowLeft' && offset === 1) {
        this.isEditingMention = false;
      } else {
        this._editOptionValue(event);
      }
    } else {
      this.isEditingMention = false;
    }

    if (this.isEditingMention) {
      if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
        this._optionArrowNavigation(event);
      }
      return;
    }
    if (event.key === this.mentionTrigger) {
      // Ignore if we already have a mention
      // @ts-expect-error
      if (this.mentionValue?.length > 0) {
        return;
      }
      this._mentionCreation(event);
      return;
    }

    this.onKeyDown?.(event);
  }

  get mentionValue() {
    return document.getElementById(MENTION_ELEMENT_ID)?.innerText;
  }

  @action
  // @ts-expect-error
  _editOptionValue(event) {
    this.isEditingMention = true;
    if (event.key === 'Backspace') {
      // If it's the last character, we disable the editing option state
      if (this.searchOption.length === 0) {
        this.isEditingMention = false;
        return;
      }

      this.selectedOptionIndex = 0;
      this.searchOption = this.searchOption.slice(0, -1);
    } else if (/^[a-zA-Z0-9 ]$/.test(event.key)) {
      this.selectedOptionIndex = 0;
      this.searchOption = `${this.searchOption}${event.key}`;
    }
  }

  @action
  // @ts-expect-error
  _mentionCreation(event) {
    this.searchOption = '';
    this.isEditingMention = true;
    event.preventDefault();

    // Create a new span element for the mention and set its text content to @
    let span = document.createElement('span');
    span.textContent = this.mentionTrigger;

    span.setAttribute('contenteditable', 'true');
    span.setAttribute('id', MENTION_ELEMENT_ID);

    // Get the current selection and create a new range for the mention
    let selection = window.getSelection();
    // @ts-expect-error
    let range = selection.getRangeAt(0);
    let fragment = document.createDocumentFragment();
    fragment.appendChild(span);
    range.insertNode(fragment);

    // Select the mention
    range.selectNodeContents(span);
    range.collapse(false);
    // @ts-expect-error
    selection.addRange(range);

    // Move the options dropdown to the beginning of the mention
    this.mentionTagLeftPosition = span.offsetLeft;
  }

  @action
  // @ts-expect-error
  _optionArrowNavigation(event) {
    event.preventDefault();
    let delta = event.key === 'ArrowDown' ? 1 : -1;
    this.selectedOptionIndex =
      (this.selectedOptionIndex + delta + this.filteredOptions.length) %
      this.filteredOptions.length;
    scrollIntoView(`[data-option-index="${this.selectedOptionIndex}"]`);
  }

  @action
  // @ts-expect-error
  selectOption(index) {
    this.isEditingMention = false;

    // Create a new text node to insert a space after the mention
    let textNode = document.createTextNode(' ');
    let parentNode = document.getElementById(INPUT_ELEMENT_ID);
    // @ts-expect-error
    parentNode.appendChild(textNode);

    // Create a new range to select the text node
    let range = document.createRange();
    // @ts-expect-error
    range.selectNodeContents(parentNode);
    range.collapse(false);

    let selection = window.getSelection();
    // @ts-expect-error
    selection.removeAllRanges();
    // @ts-expect-error
    selection.addRange(range);

    // Set the mention value to the selected option
    let selectedOption = this.filteredOptions[index].name;

    // @ts-expect-error
    document.getElementById(MENTION_ELEMENT_ID).innerText = this.mentionTrigger + selectedOption;

    this.searchOption = selectedOption;

    // Reset the selected option index
    this.selectedOptionIndex = 0;
  }

  get mentionableInputValue() {
    return (
      // @ts-expect-error
      document
        .querySelector(`#${INPUT_ELEMENT_ID}`)
        // @ts-expect-error
        .innerText.replace(new RegExp(this.mentionTrigger, 'g'), '')
    );
  }

  @action
  // @ts-expect-error
  onEnter(event) {
    if (this.isEditingMention) {
      this.selectOption(this.selectedOptionIndex);
      return;
    }
    event.preventDefault();
    this.onSubmit?.(this.mentionableInputValue);
  }

  get optionsDropdownStyle() {
    if (!this.isEditingMention) {
      return 'display: none;';
    }
    return `left: ${this.mentionTagLeftPosition}px;`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'QontoPilot::MentionableInput::Index': typeof QontoPilotMentionableInputComponent;
  }
}
