export default {
  "mileage-validation": "cfZ",
  "actions": "cfR",
  "action-btn": "cfQ",
  "approve-btn": "cfe",
  "request-mileage-validation": "cfB",
  "account-selector": "cfE",
  "has-error": "cfd",
  "funds-disclaimer": "cfI",
  "account-options": "cft",
  "account-balance": "cfM",
  "warnings": "cfP"
};
