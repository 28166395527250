/* import __COLOCATED_TEMPLATE__ from './cancel.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { task } from 'ember-concurrency';

interface ReimbursementsModalsCancelSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReimbursementsModalsCancelComponent extends Component<ReimbursementsModalsCancelSignature> {
  @service declare toastFlashMessages: Services['toastFlashMessages'];
  @service declare intl: Services['intl'];
  @service declare errors: Services['errors'];

  confirmTask = task({ drop: true }, async () => {
    // @ts-expect-error
    let { request } = this.args.data;

    try {
      await request.cancelRequest();

      this.toastFlashMessages.toastInfo(this.intl.t('reimbursements.toast.cancel'));

      // @ts-expect-error
      this.args.close('success');
    } catch (error) {
      // @ts-expect-error
      if (error.errors?.[0]?.detail?.code === 'request_not_pending') {
        // @ts-expect-error
        this.args.close();

        this.toastFlashMessages.toastError(
          this.intl.t('supplier-invoices.preview.request.info-toast.same-invoice')
        );

        // Now that we know that request status is outdated, we refresh it for the request and the invoice
        try {
          // @ts-expect-error
          await Promise.all([this.args.data.request.reload(), this.args.data.invoice.reload()]);
        } catch (error) {
          this.errors.handleError(error);
        }
      } else {
        this.errors.handleError(error);
      }
    }
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Reimbursements::Modals::Cancel': typeof ReimbursementsModalsCancelComponent;
  }
}
