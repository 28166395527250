/* import __COLOCATED_TEMPLATE__ from './confirm-creation-modal.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { Badge, Checkbox } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

interface ReceivableInvoicesConfirmCreationModalSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class ReceivableInvoicesConfirmCreationModalComponent extends Component<ReceivableInvoicesConfirmCreationModalSignature> {
  checkbox = Checkbox;

  badge = Badge;

  @tracked isChecked = false;
  @tracked checkboxHasError = false;

  @action
  handledCheckbox() {
    this.isChecked = !this.isChecked;
    this.checkboxHasError = false;
  }

  confirmTask = dropTask(async () => {
    // @ts-expect-error
    if (this.args.data.shouldDisplayCheckbox && !this.isChecked) {
      this.checkboxHasError = true;
      return;
    }

    // @ts-expect-error
    await this.args.data.confirmTask.perform(this.args.close);
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ReceivableInvoices::ConfirmCreationModal': typeof ReceivableInvoicesConfirmCreationModalComponent;
  }
}
