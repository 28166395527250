/* import __COLOCATED_TEMPLATE__ from './footer.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class RibaTasksTableFooterComponent extends Component {
  @tracked approvalBankAccount = this.args.mainAccount;
  @service segment;

  get hasSingleBankAccount() {
    return this.args.activeAccounts?.length === 1;
  }

  get isActionRunning() {
    return this.args.isApproveRunning || this.args.isRejectRunning;
  }

  @action
  handleApprove() {
    this.args.onApprove(this.approvalBankAccount.id, [...this.args.selectedPayments]);
    this.segment.track('riba_pending_payment_bulk_approved');
  }

  @action
  handleReject() {
    this.args.onReject(this.approvalBankAccount.id, [...this.args.selectedPayments]);
    this.segment.track('riba_pending_payment_bulk_rejected');
  }

  @action
  updateApprovalBankAccount(account) {
    this.approvalBankAccount = account;
  }
}
