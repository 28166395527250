export default {
  "content": "ciL",
  "balance": "ciT",
  "duration": "ciz",
  "green-text": "cia",
  "balance-subtitle": "ciH",
  "balance-duration": "ciO",
  "footer": "cim",
  "progress": "cip",
  "progress-bar": "cix",
  "progress-bar-text": "ciw",
  "progress-active": "ciN"
};
