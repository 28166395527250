/* import __COLOCATED_TEMPLATE__ from './pending-layout.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

interface RequestSidebarTransferPendingLayoutSignature {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: HTMLDivElement;
}

export default class RequestSidebarTransferPendingLayoutComponent extends Component<RequestSidebarTransferPendingLayoutSignature> {
  @service declare abilities: Services['abilities'];

  get warning() {
    let canValidate = this.abilities.can('review transfer request');
    // @ts-expect-error
    let { beneficiaryWarning, request } = this.args;

    if (canValidate && request.hasRequestMultiTransfer && request.newBeneficiary) {
      return 'new_beneficiary';
    }

    return beneficiaryWarning;
  }

  get showValidations() {
    // @ts-expect-error
    let { request } = this.args;
    let userId = request.get('initiator.userId');
    return (
      !request.hasRequestMultiTransfer &&
      (this.abilities.can('cancel transfer requests', userId) ||
        this.abilities.can('review transfer requests'))
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Sidebar::Transfer::PendingLayout': typeof RequestSidebarTransferPendingLayoutComponent;
  }
}
