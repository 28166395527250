/* import __COLOCATED_TEMPLATE__ from './transfer.hbs'; */
import Component from '@glimmer/component';

interface RequestTableCellTransferSignature {
  // The arguments accepted by the component
  Args: {
    isWithEllipsis?: boolean;
    isApprover?: boolean;
  };
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class RequestTableCellTransferComponent extends Component<RequestTableCellTransferSignature> {
  get icon() {
    // @ts-expect-error
    let { request } = this.args;

    if (request.once) {
      return 'feature_transfer_direct-m';
    } else if (
      request.weekly ||
      request.monthly ||
      request.quarterly ||
      request.halfYearly ||
      request.yearly
    ) {
      return 'feature_transfer_recurring-m';
    } else {
      return 'feature_transfer_scheduled-m';
    }
  }

  get isRequestExpired() {
    // @ts-expect-error
    let { request } = this.args;
    if (
      request.weekly ||
      request.monthly ||
      request.quarterly ||
      request.halfYearly ||
      request.yearly
    ) {
      return request.hasPastEndingDate;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Request::Table::Cell::Transfer': typeof RequestTableCellTransferComponent;
  }
}
