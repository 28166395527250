export default {
  "referral-invite-new": "cpx",
  "referral": "cpw",
  "center": "cpN",
  "illustration": "cpv",
  "share-referral": "cpo",
  "choice": "cpn",
  "line": "cpi",
  "email": "cpA",
  "email-input": "cpY",
  "input-field--error": "cph",
  "error-message": "cpf",
  "external-link-icon": "cpK"
};
