export default {
  "gap-32": "czq",
  "fieldset": "czZ",
  "date-picker": "czR",
  "with-performance-date": "czQ",
  "mt-4": "cze",
  "clients": "czB",
  "dropdown-option": "czE",
  "dropdown-content": "czd",
  "select-client": "czI",
  "client-option": "czt",
  "icon-tooltip": "czM",
  "payment-details": "czP",
  "dates-container": "czl",
  "automatic-number-label": "czX",
  "label": "czC",
  "numbering-container": "czk",
  "fix-width": "czJ",
  "customer-error": "czs",
  "amount-text": "czF",
  "header-text-field-container": "czg",
  "header-text-field": "czD",
  "header-text-field-close-button": "czV",
  "mt-16": "czL"
};
