export default {
  "row": "cxy",
  "active": "cxU",
  "animated": "cxj",
  "fadein-item": "cxW",
  "cell": "cxu",
  "cell-content": "cwS",
  "row-sidebar": "cwc",
  "empty": "cwq",
  "cell-amount": "cwZ",
  "subtitle": "cwR caption",
  "hidden": "cwQ",
  "cell-approve": "cwe",
  "quick-actions": "cwB",
  "account-select": "cwE",
  "note": "cwd",
  "cell-content-amount": "cwI body-1",
  "cell-status": "cwt"
};
